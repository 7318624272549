<template>
  <ion-page>
    <uncoded-header />
    <ion-content scroll-y="false">
      <div class="flex vertical ph" v-if="!wait">
        <property-header />
        <nav-header @back="back">Documents</nav-header>

        <div v-for="(docs, type) in groupedDocuments" :key="type" class="fullwidth ph2">
          <div class="titleFont fg cs uppercase pv">{{ type }}</div>
          <ion-item v-for="doc in docs" :key="doc.id" @click="showFile(doc)">
            <ion-icon :icon="documentOutline" slot="start" color="light" />
            <ion-label>{{ doc.description }}</ion-label>
          </ion-item>
        </div>

        <div class="grow"></div>
      </div>
      <wait v-else />
    </ion-content>
  </ion-page>
</template>

<script>
import { documentOutline, attachOutline, downloadOutline } from "ionicons/icons";
import { computed, ref } from "@vue/runtime-core";
import { useRoute, useRouter } from "vue-router";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { isPlatform, onIonViewWillEnter } from "@ionic/vue";
import UncodedHeader from "@/components/UncodedHeader";
import PropertyHeader from "../common/PropertyHeader";
import { usePropertyStore } from "@/stores/property";
import { storeToRefs } from "pinia";

export default {
  name: "PropertyDocuments",
  components: { UncodedHeader, PropertyHeader },
  setup() {
    const wait = ref(false);
    const route = useRoute();
    const router = useRouter();

    const propertyID = route.params.propertyID;
    const floorID = route.params.floorID;

    const propertyStore = usePropertyStore();
    const { property, allDocumentTypes } = storeToRefs(propertyStore);

    onIonViewWillEnter(() => {
      propertyStore.setPropertyID(propertyID);
    });

    const documents = computed(() => {
      return property.value?.documents;
    });

    const groupedDocuments = computed(() => {
      return groupBy(documents.value, "documentTypeID");
    });

    const back = () => {
      router.back();
    };

    const showFile = (file) => {
      router.push({
        name: "Document",
        params: { propertyID: propertyID, path: file.path },
      });
    };

    const groupBy = (arr, property) => {
      if (!arr) return;
      const result = {};
      const groupNames = [...new Set(arr.map((item) => item[property]))];
      for (const groupName of groupNames) {
        const type = allDocumentTypes.value.find((dt) => dt.id == groupName)?.name;
        result[type] = arr.filter((item) => item[property] == groupName);
      }
      return result;
    };

    const downloadURL = (url) => {
      return new Promise(function (resolve, reject) {
        var xhr = new XMLHttpRequest();
        xhr.open("GET", url, true);
        xhr.responseType = "arraybuffer";
        xhr.onreadystatechange = function (evt) {
          if (xhr.readyState === 4) {
            if (xhr.status === 200) {
              resolve(xhr.response);
            } else {
              reject(new Error("Error for " + url + ": " + xhr.status));
            }
          }
        };
        xhr.send();
      });
    };

    const downloadFiles = async (e) => {
      e.cancelBubble = true;
      wait.value = true;
      const zip = new JSZip();
      for (const type in groupedDocuments.value) {
        for (const file of groupedDocuments.value[type]) {
          console.log(file);
          const fileBlob = await downloadURL(file.url);
          zip
            .folder(`${property.value?.title} documents` || "Online Inside")
            .folder(type)
            .file(file.filename, fileBlob);
        }
      }

      const content = await zip.generateAsync({ type: "blob" });

      const zipName = `${property.value?.title} documents` || "Online Inside";
      saveAs(content, zipName);
      wait.value = false;
    };

    return {
      // variables
      property,
      documents,
      groupedDocuments,
      wait,
      // methods
      isPlatform,
      downloadFiles,
      back,
      showFile,
      //icons
      documentOutline,
      attachOutline,
      downloadOutline,
    };
  },
};
</script>

<style scoped>
.file {
  width: 150px;
}
</style>
