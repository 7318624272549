<template>
  <ion-page>
    <uncoded-header title="Organisations" />
    <nav-header class="cl" @back="back">{{ contactType?.plural }}</nav-header>

    <ion-content :scroll-y="false">
      <div class="flex vertical" v-if="contactType">
        <div class="grid2 p fullwidth">
          <div class="card" v-for="organisation in organisations" :key="organisation.id" @click="showOrganisation(organisation.id)">
            <div class="absolute cp keepOnTop">
              <ion-icon :icon="pencilOutline" @click="editOrganisation(organisation.id, $event)" />
            </div>

            <img :src="organisation.logo || '/img/placeholders/transparent.png'" class="light contain square fullwidth" />

            <div class="title">{{ organisation.name }}</div>
          </div>
        </div>

        <div class="p fullwidth">
          <ion-button color="primary" expand="full" class="equal" @click="addOrganisation()">New {{ contactType.name }}</ion-button>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import UncodedHeader from "@/components/UncodedHeader.vue";
import { computed } from "@vue/runtime-core";
import { useRoute, useRouter } from "vue-router";
import { pencilOutline } from "ionicons/icons";
import { chevronBackCircleOutline, chevronForward } from "ionicons/icons";
import { usePropertyStore } from "@/stores/property";
import { storeToRefs } from "pinia";

export default {
  components: { UncodedHeader },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const contactTypeID = route.params.contactTypeID;
    const propertyStore = usePropertyStore();
    const { allContactTypes } = storeToRefs(propertyStore);

    const contactType = computed(() => allContactTypes.value.find((ct) => ct.id == contactTypeID));

    const organisations = computed(() => contactType.value.organisations);

    const showOrganisation = (organisationID) => {
      router.push({
        name: "EditOrganisation",
        params: {
          contactTypeID: contactTypeID,
          organisationID: organisationID,
        },
      });
    };

    const addOrganisation = async () => {
      await propertyStore.createOrganisation(contactTypeID).then((newDoc) => {
        editOrganisation(newDoc);
      });
    };

    const editOrganisation = (organisationID, event) => {
      if (event) event.cancelBubble = true;
      router.push({
        name: "EditOrganisationDetails",
        params: {
          contactTypeID: contactTypeID,
          organisationID: organisationID,
        },
      });
    };

    const back = () => {
      router.back();
    };

    return {
      // variables
      contactType,
      organisations,
      // methods
      back,
      addOrganisation,
      showOrganisation,
      editOrganisation,
      // icons
      chevronBackCircleOutline,
      chevronForward,
      pencilOutline,
    };
  },
};
</script>
