<template>
  <ion-page>
    <uncoded-header :title="isScanning ? 'Scan Barcode' : null" />

    <ion-content :scrollY="false" class="transparent">
      <div class="flex vertical" :class="{ homeBackground: !isScanning }">
        <!-- Enter shortcode  -->
        <div class="mt2 pt2" style="width: 80%; height: 67px">
          <div class="flex p bdl autowidth" v-if="!isScanning">
            <div class="absolute cl o5 pl" v-if="shortcode == ''">Enter a shortcode</div>
            <ion-input
              v-model="shortcode"
              :maxlength="6"
              type="text"
              v-on:keyup.enter="processShortcode"
              @ionFocus="shortcodeActive = true"
              @ionChange="shortcode = shortcode.toUpperCase()"
              @ionBlur="shortcodeActive = false"
            ></ion-input
            ><ion-icon :icon="arrowForwardOutline" id="shortcodeButton" @click="processShortcode" color="primary" />
          </div>
        </div>

        <!-- QR Code Scanner  -->
        <div class="fullwidth flex vertical autoheight nogap" style="padding-top: 60px" v-show="!shortcodeActive && isPlatform('cordova')">
          <div class="square relative flex centered scanner">
            <img src="/img/frame/tl.png" class="top left" />
            <img src="/img/frame/tr.png" class="top right" />
            <img src="/img/frame/bl.png" class="bottom left" />
            <img src="/img/frame/br.png" class="bottom right" />

            <div v-if="isScanning" class="uppercase fs boldFont m2 tc">Point your camera at the QR Code</div>
            <div v-else class="uppercase fs boldFont m2 tc" @click="startScanning">Or touch here if you have a QR Code</div>
          </div>

          <div style="width: 50%; height: 50px" class="mt">
            <ion-button @click="stopScanning()" expand="full" class="bdl m0" v-if="isScanning">Cancel</ion-button>
          </div>
        </div>
        <!-- </div> -->
        <div class="fullwidth safe-bottom" v-if="user && !user?.isAnonymous">
          <div class="actionList p2">
            <div class="action" @click="navigate('Recent')">
              <div>Recently viewed</div>
              <div><ion-icon :icon="chevronForward" /></div>
            </div>
            <div class="action" @click="navigate('Shortlist')">
              <div>Favourite Buildings</div>
              <div><ion-icon :icon="chevronForward" /></div>
            </div>
          </div>
        </div>

        <!-- <div v-else class="flex vertical autoheight spaceAround bgl register pt">
          <img src="/img/logos/logo text black.png" style="height: 50px" />
          <div class="tc fs cd ph2">
            Save favourites, get live pricing for any address, re-visit recently viewed buildings, share pricing & more with a free Online
            Inside account.
          </div>
          <div class="fullwidth safe-bottom ph pb">
            <ion-button expand="full" color="dark" @click="navigate('Login')">Login or Register</ion-button>
          </div>
        </div> -->
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { isPlatform } from "@ionic/vue";
import { ref, computed, nextTick } from "vue";
import { useRouter } from "vue-router";
import { Keyboard } from "@capacitor/keyboard";
import { BarcodeScanner } from "@capacitor-community/barcode-scanner";
import { Haptics } from "@capacitor/haptics";
import { arrowForwardOutline, navigateCircleOutline, chevronForward } from "ionicons/icons";
import { toast } from "@/composables/utilities";
import { getFirebaseDocument } from "@/composables/firebase";
import { useUserStore } from "@/stores/user";
import { storeToRefs } from "pinia";

import UncodedHeader from "@/components/UncodedHeader.vue";
import mixpanel from "mixpanel-browser";
export default {
  components: { UncodedHeader },
  name: "Home",
  setup() {
    const userStore = useUserStore();
    const { user } = storeToRefs(userStore);
    const router = useRouter();
    const isScanning = ref(false);
    const shortcode = ref("");
    const shortcodeActive = ref(false);

    let scanTimer = null;

    const stopScanning = () => {
      clearTimeout(scanTimer);
      isScanning.value = false;
      setTimeout(() => {
        BarcodeScanner.stopScan();
        BarcodeScanner.showBackground();
      }, 500);
    };

    const decode = (data) => {
      const slug = data.split("onlineinside.com").pop();
      if (slug) {
        mixpanel.track("Code Received", { slug });
        stopScanning();
        Haptics.vibrate();
        router.push({ path: slug });
      }
    };

    const startScanning = async () => {
      if (!(isPlatform("iphone") || isPlatform("android"))) return;
      mixpanel.track("Scan Started");
      if (scanTimer) clearTimeout(scanTimer);
      const permission = await BarcodeScanner.checkPermission();

      if (permission.neverAsked) await BarcodeScanner.checkPermission({ force: true });
      isScanning.value = true;
      await BarcodeScanner.hideBackground();
      isScanning.value = true;
      scanTimer = setTimeout(() => {
        stopScanning();
      }, 5000);
      const result = await BarcodeScanner.startScan();
      if (result.hasContent) {
        decode(result.content);
      }
    };

    const canScan = computed(() => {
      return isPlatform("mobile");
    });
    const navigate = (to) => {
      router.push({ name: to });
    };

    const processShortcode = async () => {
      Keyboard.hide();
      mixpanel.track("Shortcode Entered", { shortcode: shortcode.value });
      const code = shortcode.value.toUpperCase();
      if (code.length < 4) {
        toast(`${code} is not a valid shortcode.`);
        shortcode.value = "";
        return;
      }
      const shortcodeDoc = await getFirebaseDocument("shortcodes", code);
      if (!shortcodeDoc) {
        toast(`Could not find shortcode ${code}.`);
        shortcode.value = "";
        return;
      }
      router.push({
        name: "Property",
        params: { propertyID: shortcodeDoc.propertyID },
      });
      nextTick(() => {
        shortcode.value = "";
      });
    };

    return {
      arrowForwardOutline,
      chevronForward,
      navigateCircleOutline,
      isScanning,
      canScan,
      shortcode,
      shortcodeActive,
      startScanning,
      stopScanning,
      isPlatform,
      processShortcode,
      navigate,
      user,
    };
  },
};
</script>

<style scoped>
ion-content {
  background: var(--ion-background-color);
}

.visible {
  opacity: 0.6;
  transition: opacity 0.3s ease-in;
}
.hidden {
  opacity: 0;
  transition: opacity 0.1s ease-in;
}
#shortcodeButton {
  font-size: 1rem;
}
.register {
  border-radius: 50px 50px 0px 0px;
}
/* 
.actionList {
  width: 100%;
  padding: 20px;
}

.action {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: uppercase;
  font-family: Bold;
  border-bottom: 1px solid rgba(var(--ion-color-light-rgb), 0.3);
  padding: 20px 10px;
  font-size: 0.8em;
}
.action:first-child {
  border-top: 1px solid rgba(var(--ion-color-light-rgb), 0.3);
} */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
