<template>
  <div id="highlights">
    <div
      v-for="(highlight, index) in highlights"
      :key="index"
      class="highlight"
      @click="highlightClicked(index)"
    >
      <div class="value">
        {{ highlight.value }}<span class="units">{{ highlight.units }}</span>
      </div>
      <div class="title">{{ highlight.title }}</div>
    </div>
  </div>
</template>

<script>
export default {
  emits: ["highlightClicked"],
  props: ["highlights"],
  setup(props, { emit }) {
    const highlightClicked = (index) => {
      emit("highlightClicked", index);
    };

    return { highlightClicked };
  },
};
</script>

<style scoped>
#highlights {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: baseline;
  z-index: 100;
}

#highlights .value {
  font-family: Title;
  font-size: 2em;
  width: 100%;
  text-align: center;
}

#highlights .title {
  font-size: 0.5em;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
}

#highlights > :not(:first-child) {
  border-left: 2px solid var(--ion-color-primary);
  padding-left: 1em;
  margin-left: 1em;
}

#highlights .units {
  padding-left: 0.2em;
  font-family: var(--ion-font-family);
  font-size: 0.2em;
  text-transform: uppercase;
}
</style>
