<template>
  <ion-page>
    <uncoded-header />
    <ion-content scroll-y="false">
      <div class="flex vertical left centered p2 bgd">
        <property-header />
        <nav-header @back="back">{{ accreditation?.name || "" }}</nav-header>
        <img :src="level?.url" class="accreditationLogo" v-if="accreditation" />
        <div class="f2 cp uppercase titleFont">
          {{ accreditation?.levelName || "" }}
        </div>
        <div class="cl mt2" v-html="accreditation?.description" />
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { useRoute, useRouter } from "vue-router";
import UncodedHeader from "@/components/UncodedHeader";
import { onIonViewWillEnter } from "@ionic/vue";
import PropertyHeader from "../common/PropertyHeader";
import { usePropertyStore } from "@/stores/property";
import { storeToRefs } from "pinia";
import { computed } from "vue-demi";

export default {
  components: { UncodedHeader, PropertyHeader },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const propertyID = route.params.propertyID;
    const accreditationID = route.params.accreditationID;
    const levelID = route.params.levelID;

    const propertyStore = usePropertyStore();
    const { property, allAccreditations } = storeToRefs(propertyStore);

    const accreditation = computed(() => allAccreditations.value.find((a) => a.id == accreditationID));
    const level = computed(() => accreditation.value.levels?.find((l) => l.id == levelID));

    onIonViewWillEnter(async () => {
      await propertyStore.setPropertyID(propertyID);
    });

    const back = () => {
      router.back();
    };

    return {
      back,
      property,
      accreditation,
      level,
    };
  },
};
</script>

<style scoped>
.accreditationLogo {
  width: 50%;
  aspect-ratio: 1;
  object-fit: contain;
  
}
</style>
