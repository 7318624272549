<template>
  <ion-page>
    <uncoded-header />
    <ion-content scroll-y="false" v-if="property">
      <div class="fullheight bgd p">
        <property-header />
        <nav-header @back="back">Contacts</nav-header>

        <div v-for="type in propertyContacts" :key="type.id" class="fullwidth ph2">
          <div class="titleFont fg cs uppercase pv">{{ type.contactPlural }}</div>
          <div v-for="organisation in type.organisations" :key="organisation.id">
            <ion-item
              v-for="contact in organisation.contacts"
              :key="contact.id"
              @click="showContact(type, organisation, contact)"
              class="pv"
            >
              <img :src="contact.url || '/img/placeholders/portrait.png'" class="agentPhoto" slot="start" />
              <div>
                <ion-label color="light"
                  ><span class="fr">{{ contact.firstname }} {{ contact.lastname }}</span></ion-label
                >
                <ion-label color="primary"
                  ><span class="fs">{{ contact.role }} - {{ organisation.name }}</span></ion-label
                >
              </div>
            </ion-item>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { useRoute, useRouter } from "vue-router";
import UncodedHeader from "@/components/UncodedHeader";
import PropertyHeader from "../common/PropertyHeader";
import { usePropertyStore } from "@/stores/property";
import { storeToRefs } from "pinia";
import { onIonViewWillEnter } from "@ionic/vue";

export default {
  components: { UncodedHeader, PropertyHeader },
  setup() {
    const propertyID = useRoute().params.propertyID;
    const propertyStore = usePropertyStore();
    const { property, propertyContacts } = storeToRefs(propertyStore);
    const router = useRouter();
    const back = () => {
      router.back();
    };

    onIonViewWillEnter(async () => {
      await propertyStore.setPropertyID(propertyID);
    });

    const showContact = (type, organisation, contact) => {
      router.push({
        name: "PropertyContact",
        params: {
          propertyID: propertyID,
          typeID: type.id,
          organisationID: organisation.id,
          contactID: contact.id,
        },
      });
    };

    return {
      property,
      propertyContacts,
      back,
      showContact,
    };
  },
};
</script>

<style scoped>
.agentPhoto {
  width: 40px;
  aspect-ratio: 1;
  object-fit: cover;
  border-radius: 5px;
}
</style>
