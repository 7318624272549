<template>
  <ion-page>
    <uncoded-header />
    <ion-content>
      <div slot="fixed" class="flex vertical fullwidth safe-bottom nogap" v-if="url">
        <nav-header @back="back"
          ><div class="fs">{{ path.split("/").pop() }}</div></nav-header
        >

        <div class="flex p2 bgd" style="gap: 20px" v-if="docType == 'pdf'">
          <div class="flex autowidth">
            <ion-icon :icon="removeCircle" @click="zoomOut" :class="{ toolbarIcon: true, disabled: scale <= 0.25 }" />
            <div class="ft cl">{{ Math.round(scale * 100) }}%</div>
            <ion-icon :icon="addCircle" @click="zoomIn" :class="{ toolbarIcon: true, disabled: scale >= 3 }" />
          </div>

          <div class="flex">
            <ion-icon :icon="arrowBackCircle" :class="{ toolbarIcon: true, disabled: page == 1 }" @click="movePage(-1)" />
            <div class="ft cl">{{ page }} of {{ pages }}</div>
            <ion-icon :icon="arrowForwardCircle" :class="{ toolbarIcon: true, disabled: page == pages }" @click="movePage(1)" />
          </div>

          <div class="flex autowidth">
            <ion-icon class="mr toolbarIcon" :icon="refreshCircle" @click="rotate" />
            <ion-icon class="toolbarIcon" :icon="expand" @click="fill" />
          </div>
        </div>

        <div class="fullheight fullwidth scroll" style="background-color: white">
          <pdf
            :src="url"
            :page="page"
            v-model:scale="scale"
            :rotate="rotation"
            :resize="fit"
            style="width: 100%"
            :annotation="true"
            ref="pdfViewer"
            v-if="docType == 'pdf'"
          >
          </pdf>
          <div v-html="docHTML" v-if="docType == 'docx'" class="bgl p2" style="background-color: white"></div>
        </div>

        <div class="ph2 bgd safe-bottom fullwidth">
          <div class="flex centered">
            <a class="cl p" style="text-decoration: none" :href="url" download="description"
              ><ion-icon :icon="download" /> Download Original</a
            >
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import UncodedHeader from "@/components/UncodedHeader.vue";
import { useRoute, useRouter } from "vue-router";
import {
  share as shareIcon,
  closeCircle,
  arrowForwardCircle,
  arrowBackCircle,
  refreshCircle,
  addCircle,
  removeCircle,
  expand,
  download,
} from "ionicons/icons";
import { getURL } from "@/composables/firebase";
import { storeToRefs } from "pinia";
import { usePropertyStore } from "@/stores/property";
import { onIonViewWillEnter } from "@ionic/vue";
import { ref } from "@vue/reactivity";
import mammoth from "mammoth";
import pdf from "pdfvuer";
import { doc } from "prettier";

export default {
  params: ["description"],
  components: { UncodedHeader, pdf },
  setup() {
    const propertyStore = usePropertyStore();
    const route = useRoute();
    const router = useRouter();
    const { property } = storeToRefs(propertyStore);
    const path = route.params.path;
    const description = route.params.description;
    const page = ref(1);
    const scale = ref(1);
    const rotation = ref(0);
    const pages = ref(1);
    const loaded = ref(false);
    const fit = ref(true);
    const url = ref();
    const pdfViewer = ref();
    const docType = ref("");
    const docHTML = ref();

    const setupDoc = async () => {
      docType.value = path.split(".").pop().toLowerCase();
      url.value = await getURL(path);

      console.log("Doc type", docType.value);
      switch (docType.value) {
        case "pdf":
          try {
            const pdfData = await pdf.createLoadingTask(url.value);
            pages.value = pdfData?.numPages;
            loaded.value = true;
          } catch (error) {
            console.log("Error loading PDF", error);
          }

          break;

        case "docx":
          const reader = new FileReader();
          const file = await fetch(url.value);
          const arrayBuffer = await file.arrayBuffer();
          const result = await mammoth.convertToHtml({ arrayBuffer });
          docHTML.value = result.value;
          break;
        default:
          console.log("Document type not supported");
          break;
      }
    };

    onIonViewWillEnter(() => {
      setupDoc();
      document.addEventListener("gesturestart", (e) => e.preventDefault());
      document.addEventListener("gesturechange", (e) => e.preventDefault());
    });

    const canShare = !!navigator.share;

    const back = () => {
      router.back();
    };

    const movePage = (move) => {
      page.value += move;
    };

    const preview = () => {
      PreviewAnyFile(
        (win) => {
          console.log(win);
        },
        (error) => {
          console.log(error);
        },
        url.value
      );
    };

    const share = async () => {
      await navigator.share({ title: "Title", url: url.value });
    };

    const rotate = () => {
      rotation.value += 90;
      if (rotation.value > 359) rotation.value = 0;
    };

    const zoomIn = () => {
      fit.value = false;
      scale.value = Math.min(3, scale.value + 0.25);
    };

    const zoomOut = () => {
      fit.value = false;
      scale.value = Math.max(0.25, scale.value - 0.25);
    };

    const fill = () => {
      fit.value = true;
      pdfViewer.value?.calculateScale();
    };

    return {
      property,
      description,
      path,
      back,
      share,
      movePage,
      rotate,
      zoomIn,
      zoomOut,
      rotation,
      scale,
      url,
      page,
      pages,
      canShare,
      shareIcon,
      closeCircle,
      arrowForwardCircle,
      arrowBackCircle,
      refreshCircle,
      addCircle,
      removeCircle,
      download,
      loaded,
      expand,
      fit,
      fill,
      pdfViewer,
      docType,
      preview,
      docHTML,
    };
  },
};
</script>
<style scoped>
.toolbarIcon {
  width: 32px;
  height: 32px;
  color: var(--ion-color-light);
}

.disabled {
  pointer-events: none;
  opacity: 0.4;
}
</style>
