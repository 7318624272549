<template>
  <swiper @swiper="setSwiper" :slidesPerView="4" :loopedSlides="4" loop>
    <swiper-slide v-for="(carrier, index) in carriers" :key="index">
      <img class="light" :src="carrier?.logo" />
    </swiper-slide>
  </swiper>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "@ionic/vue/css/ionic-swiper.css";
import { defineComponent } from "vue";

export default defineComponent({
  props: ["carriers"],
  components: { Swiper, SwiperSlide },
  setup() {
    let swiper;

    const setSwiper = (_swiper) => {
      swiper = _swiper;
      setInterval(() => {
        swiper.slideNext(500);
      }, 5000);
    };

    return { setSwiper };
  },
});
</script>

<style scoped>
.swiper-container {
  width: 100%;
}
.logo {
  height: 50px;
  width: 80px;
  margin: 20px 0px;
  object-fit: contain;
  filter: grayscale(1);
}
</style>
