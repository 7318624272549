<template>
  <ion-page>
    <uncoded-header title="Edit Property" />
    <ion-content>
      <div class="flex vertical fullheight p">
        <property-header :property="property" />
        <nav-header @back="back"> {{ floor?.name }}</nav-header>
        <div class="fullwidth">
          <ion-item class="mb2" lines="none">
            <ion-label color="primary" position="stacked">Name</ion-label>
            <ion-input v-model="floor.name" v-if="floor" @ionInput="markDirty()" />
          </ion-item>
          <div class="action" @click="showDocuments()">
            <ion-label>Documents</ion-label>
            <ion-icon :icon="chevronForward" />
          </div>
          <div v-for="space in floor?.spaces" :key="space.id" class="action cs" @click="showSpace(space)">
            <ion-label>{{ space.title }}</ion-label>
            <ion-icon :icon="chevronForward" slot="end" />
          </div>
          <div class="action cp" @click="addSpace()">
            <ion-label>Add space</ion-label>
            <ion-icon :icon="chevronForward" slot="end" />
          </div>
        </div>
        <div class="grow"></div>
        <div class="flex mb2">
          <ion-button class="equal danger" @click="confirmDelete()">Delete Floor</ion-button>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { ref } from "@vue/runtime-core";
import { useRoute, useRouter } from "vue-router";
import { chevronForward, pencilOutline } from "ionicons/icons";
import { alertController, onIonViewWillEnter } from "@ionic/vue";
import UncodedHeader from "@/components/UncodedHeader";
import PropertyHeader from "@/components/PropertyHeader.vue";
import NavHeader from "@/components/NavHeader.vue";
import { usePropertyStore } from "@/stores/property";
import { storeToRefs } from "pinia";

export default {
  name: "Floor",
  components: { NavHeader, PropertyHeader, UncodedHeader },
  setup() {
    const dirty = ref(false);
    const route = useRoute();
    const router = useRouter();
    const propertyID = route.params.propertyID;
    const floorID = route.params.floorID;
    const propertyStore = usePropertyStore();
    const { property, floor } = storeToRefs(propertyStore);

    onIonViewWillEnter(() => {
      propertyStore.setPropertyID(propertyID);
      propertyStore.setFloorID(floorID);
    });

    const markDirty = () => {
      dirty.value = true;
    };

    const back = async () => {
      if (dirty.value) {
        const alert = await alertController.create({
          cssClass: "alert",
          header: "Are you sure?",
          message: "You have unsaved changes.",
          buttons: [
            {
              text: "Save & Close",
              handler: () => {
                propertyStore.saveFloor(floor.value);
                router.back();
              },
            },
            { text: "Cancel" },
            {
              text: "Discard Changes",
              role: "destructive",
              handler: () => {
                propertyStore.restoreFloor();
                router.back();
              },
            },
          ],
        });
        await alert.present();
      } else {
        router.back();
      }
    };

    const confirmDelete = async () => {
      const alert = await alertController.create({
        cssClass: "alert",
        header: "Are you sure?",
        message: "Deleting this floor will delete all spaces in the floor.",
        buttons: [
          {
            text: "Delete",
            role: "destructive",
            handler: () => {
              propertyStore.deleteFloor();
              router.back();
            },
          },
          { text: "Cancel" },
        ],
      });
      await alert.present();
    };

    const addSpace = async () => {
      const newDoc = await propertyStore.createSpace();
      showSpace(newDoc);
    };

    const showSpace = (space) => {
      router.push({
        name: "EditPropertySpace",
        params: { propertyID: propertyID, floorID: floorID, spaceID: space.id },
      });
    };

    const showDocuments = () => {
      router.push({
        name: "EditPropertyFloorDocuments",
        params: { propertyID: propertyID, floorID: floorID },
      });
    };

    return {
      // variables
      property,
      floor,
      // methods
      showDocuments,
      showSpace,
      addSpace,
      markDirty,
      back,
      confirmDelete,
      // icons
      chevronForward,
      pencilOutline,
    };
  },
};
</script>
