<template>
  <ion-page>
    <uncoded-header title="Edit Carrier" />

    <ion-content>
      <div slot="fixed" class="fullwidth">
        <nav-header @back="back">{{ carrier?.name }}</nav-header>

        <div class="flex vertical nogap">
          <div class="flex vertical ph">
            <div class="fullwidth" v-if="carrier">
              <ion-item>
                <ion-label position="stacked" color="primary"
                  >Carrier name</ion-label
                >
                <ion-input
                  placeholder="Carrier name"
                  v-model="carrier.name"
                  @ionInput="markDirty()"
                  required="true"
                ></ion-input>
              </ion-item>
              <ion-item>
                <ion-label position="stacked" color="primary"
                  >Luminet API name</ion-label
                >
                <ion-input
                  placeholder="API name"
                  v-model="carrier.luminetAPIName"
                  @ionInput="markDirty()"
                  required="true"
                ></ion-input>
              </ion-item>
              <div class="fullwidth">
                <img
                  :src="
                    carrier.logo
                      ? carrier.logo
                      : '/img/placeholders/transparent.png'
                  "
                  :alt="carrier.name"
                  class="logo light"
                />
                <input
                  type="file"
                  @change="addLogo"
                  ref="logoSelector"
                  hidden
                />
                <div class="flex">
                  <ion-button @click="triggerAddLogo" class="primary equal"
                    >{{ carrier.logo ? "Change" : "Add" }} logo</ion-button
                  >
                  <ion-button
                    @click="deleteLogo"
                    :disabled="!carrier.logo"
                    class="danger equal"
                    >Delete logo</ion-button
                  >
                </div>
              </div>
            </div>
            <div class="grow"></div>

            <ion-button class="fullwidth danger mb2" @click="confirmDelete"
              >Delete carrier</ion-button
            >
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import UncodedHeader from "@/components/UncodedHeader.vue";
import { useRoute, useRouter } from "vue-router";
import { alertController, onIonViewWillEnter } from "@ionic/vue";
import { usePropertyStore } from "@/stores/property";
import { storeToRefs } from "pinia";
import { ref } from "vue";

export default {
  components: { UncodedHeader },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const carrierID = route.params.carrierID;
    const propertyStore = usePropertyStore();
    const dirty = ref();
    const logoSelector = ref();
    const { carrier } = storeToRefs(propertyStore);

    onIonViewWillEnter(() => {
      propertyStore.setCarrierID(carrierID);
    });

    const cancel = () => {
      router.back();
    };

    const confirmDelete = async () => {
      const alert = await alertController.create({
        cssClass: "alert",
        header: "Are you sure?",
        message:
          "Deleting this carrier will remove it as a carrier from all existing properties.",
        buttons: [
          {
            text: "Delete",
            role: "destructive",
            handler: () => {
              deleteCarrier();
            },
          },
          { text: "Cancel" },
        ],
      });
      await alert.present();
    };

    const deleteCarrier = async () => {
      propertyStore.deleteCarrier();
      router.back();
    };

    const triggerAddLogo = () => {
      logoSelector.value.click();
    };
    const addLogo = async () => {
      const files = event.target.files;
      const file = files[0];
      propertyStore.addCarrierLogo(file);
      if (logoSelector.value) logoSelector.value.value = "";
    };

    const deleteLogo = async () => {
      propertyStore.deleteCarrierLogo();
    };

    const save = async (pop = true) => {
      await propertyStore.saveCarrier();
      if (pop) router.back();
    };

    const markDirty = () => {
      dirty.value = true;
    };

    const back = async () => {
      if (dirty.value) {
        const alert = await alertController.create({
          cssClass: "alert",
          header: "Are you sure?",
          message: "You have unsaved changes.",
          buttons: [
            {
              text: "Save & Close",
              handler: () => {
                save();
              },
            },
            { text: "Cancel" },
            {
              text: "Discard Changes",
              role: "destructive",
              handler: () => {
                router.back();
              },
            },
          ],
        });
        await alert.present();
      } else {
        router.back();
      }
    };

    return {
      // variables
      carrier,
      dirty,
      logoSelector,
      // methods
      cancel,
      back,
      confirmDelete,
      triggerAddLogo,
      addLogo,
      deleteLogo,
      markDirty,
      // icons
    };
  },
};
</script>
