<template>
  <ion-page>
    <ion-content>
      <div class="background noScroll cl boldFont">
        <div class="flex vertical centered">
          <div class="cl f2 titleFont">Link Sent</div>
          <div class="fl tc fullwidth" style="max-width: min(500px, 80vw)">
            Check your email for your magic link.
          </div>
          <div class="fs tc fullwidth" style="max-width: min(500px, 80vw)">
            (You can close this window.)
          </div>
          <div>
            <ion-button class="bdl mt2 cl" @click="goHome()">Home</ion-button>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { useRouter } from "vue-router";
export default {
  setup() {
    const router = useRouter();
    const goHome = () => {
      router.push({ name: "Home" });
    };
    return { goHome };
  },
};
</script>

<style></style>
