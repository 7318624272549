<template>
  <ion-page>
    <div id="loginContainer">
      <uncoded-header title="Password Reminder" />

      <ion-content>
        <div class="flex vertical">
          <div></div>
          <div style="max-width: min(90vw, 500px)" class="fullwidth">
            <ion-item>
              <ion-label position="floating">Email</ion-label>
              <ion-input v-model="email"></ion-input>
              <ion-note>{{ emailFeedback }}</ion-note>
            </ion-item>
            <ion-button
              expand="full"
              class="dark bdl mt"
              @click="remind()"
              :disabled="!canSubmit"
              >Send reminder</ion-button
            >
          </div>

          <div class="flex pb2 safe-bottom" style="max-width: min(90vw, 500px)">
            <ion-button
              expand="full"
              class="dark bdl mv"
              router-direction="root"
              router-link="/login"
            >
              <ion-label class="fs">Back to Login</ion-label>
            </ion-button>
          </div>
        </div>
      </ion-content>
    </div>
  </ion-page>
</template>

<script>
import { computed, ref } from "vue";
import { runFunction } from "@/composables/firebase";
import { toast, isEmailValid } from "@/composables/utilities";
import UncodedHeader from "@/components/UncodedHeader";
export default {
  components: { UncodedHeader },
  setup() {
    const email = ref();
    const emailFeedback = ref();

    const canSubmit = computed(() => {
      return isEmailValid(email.value);
    });

    const remind = async () => {
      const passwordReminderSent = window.localStorage.getItem(
        "passwordReminderSent"
      );
      let remainingMinutes = 0;

      if (passwordReminderSent) {
        const elapsedMinutes = Math.floor(
          (new Date() - Date.parse(passwordReminderSent)) / (1000 * 60)
        );
        remainingMinutes = 2 - elapsedMinutes;
      }

      if (remainingMinutes > 0) {
        toast(
          `
          Please wait ${remainingMinutes} minute${
            remainingMinutes > 1 ? "s" : ""
          } before sending another request.
          If you cannot find the email in your inbox, please check your spam folder.
          `,
          5000
        );
        return;
      }

      window.localStorage.setItem("passwordReminderSent", new Date());

      runFunction("resetPassword", { reset_address: email.value });
      toast(`A password reset link has been sent to ${email.value}.`);
    };

    return {
      email,
      emailFeedback,
      canSubmit,
      remind,
    };
  },
};
</script>

<style scoped>
#loginContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.formItems {
  height: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

#loginContainer ion-item,
#loginContainer ion-button {
  width: 100%;
  max-width: 500px;
}

.footerContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: env(safe-area-inset-bottom);
}
.footerContainer ion-button {
  flex: 1 0 1px;
  font-size: 0.7em;
}
</style>
