<template>
  <ion-page>
    <uncoded-header title="Locate Me" />
    <ion-content scroll-y="false">
      <div
        class="flex vertical centered titleFont f2 cp"
        v-show="updatingLocation"
      >
        <div>Checking your location</div>
        <ion-spinner name="dots" color="primary"></ion-spinner>
      </div>

      <div v-show="!updatingLocation" class="flex vertical left">
        <div class="fullwidth relative grow">
          <div class="flex vertical centered absolute">
            <img
              src="/img/resources/crosshair.svg"
              class="square light keepOnTop"
              height="30"
            />
          </div>
          <div id="map" class="fullwidth fullheight"></div>
        </div>

        <div
          class="flex vertical autoheight left mt2 nogrow safe-bottom"
          v-if="topHit"
        >
          <div v-show="!searchActive" class="fullwidth tc">
            <div
              class="titleFont fm cl flex centered ph"
              @click="activateSearch()"
            >
              <div>{{ topHit.formatted.split(",")[0] }}</div>
              <ion-icon :icon="searchCircleOutline" color="primary" />
            </div>
            <div class="cl fr ph">
              {{ topHit.formatted.split(",").splice(1).join(",") }}
            </div>
            <div class="mt2 fullwidth p">
              <ion-button expand="full" class="bdl mh" @click="getPrices()"
                >Get Prices</ion-button
              >
            </div>
          </div>

          <div v-show="searchActive" class="fullwidth">
            <div class="p2">
              <ion-input
                placeholder="Enter address or postcode"
                v-model="searchTerm"
                ref="searchbox"
                class="bdl"
                style="--padding-start: 10px"
              ></ion-input>
              <div class="mt fs tc">
                Once the right area is shown on the map you can refine your
                search by dragging the map so that the cursor is above the
                property.
              </div>
            </div>

            <div class="fullwidth p">
              <ion-button @click="search" expand="full" class="bdl mh"
                >Search</ion-button
              >
            </div>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import UncodedHeader from "@/components/UncodedHeader";
import { Geolocation } from "@capacitor/geolocation";
import { Loader } from "@googlemaps/js-api-loader";
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { getFirebaseCollection } from "@/composables/firebase";
import { Keyboard } from "@capacitor/keyboard";
import { searchCircleOutline } from "ionicons/icons";
import { isPlatform } from "@ionic/core";

export default {
  components: { UncodedHeader },
  setup() {
    const router = useRouter();
    const opencageAPIKey = process.env.VUE_APP_OPENCAGE_API_KEY;
    const googleMapsAPIKey = process.env.VUE_APP_GOOGLE_MAPS_API_KEY;
    const googleMapID = process.env.VUE_APP_GOOGLE_MAPS_ID;
    const updatingLocation = ref(true);
    const errorLocating = ref(false);
    const searchActive = ref(false);
    const searchTerm = ref();
    const searchbox = ref();
    const topHit = ref();
    let google;
    let map;
    let mapMoveTimer;

    // Initialise Google Map
    const setupMap = (coords) => {
      const loader = new Loader({
        apiKey: googleMapsAPIKey,
        version: "weekly",
        libraries: ["places"],
      });
      const mapOptions = {
        center: {
          lat: coords.latitude,
          lng: coords.longitude,
        },
        zoom: 15,
        mapId: googleMapID,
        disableDefaultUI: true,
        keyboardShortcuts: false,
      };

      loader.load().then((_google) => {
        google = _google;
        const mapContainer = document.getElementById("map");
        map = new google.maps.Map(mapContainer, mapOptions);
        checkForNearProperties(topHit.value);

        map.addListener("click", (e) => {
          map.panTo(e.latLng);
          updateAddress();
        });

        map.addListener("dragend", () => {
          updateAddress();
        });
      });
    };
    // End of Google Map

    const checkForNearProperties = async () => {
      if (!google?.maps) return;
      const matchingProperties = await getFirebaseCollection("properties");
      const matchingLocations = await getFirebaseCollection("locations");
      if (matchingProperties?.length > 0) {
        matchingProperties.forEach((property) => {
          const marker = new google.maps.Marker({
            position: {
              lat: parseFloat(property.location?.latitude),
              lng: parseFloat(property.location?.longitude),
            },
            icon: "/img/marker.svg",
            map: map,
            optimized: true,
            html: `<p>${property.title}</p><p>${property.address1}</p>`,
          });

          marker.addListener("click", () => {
            router.push({
              name: "Property",
              params: { propertyID: property.id },
            });
          });
        });
      }
      if (matchingLocations?.length > 0) {
        matchingLocations.forEach((location) => {
          const marker = new google.maps.Marker({
            position: {
              lat: parseFloat(location.latitude),
              lng: parseFloat(location.longitude),
            },
            icon: "/img/marker2.svg",
            map: map,
            optimized: true,
            html: `<p>${location.title}</p><p>${location.full}</p>`,
          });

          marker.addListener("click", () => {
            router.push({
              name: "GetPrices",
              params: location,
            });
          });
        });
      }
    };

    const updateAddress = () => {
      if (isPlatform("cordova")) Keyboard.hide();
      if (mapMoveTimer) clearTimeout(mapMoveTimer);
      mapMoveTimer = setTimeout(() => {
        const center = map.getCenter();
        getLocation({ lat: center.lat(), lng: center.lng() });
      }, 2000);
    };

    const getLocation = async (coords) => {
      errorLocating.value = false;
      searchActive.value = false;

      const mapMoved = !!coords;

      const permissions = await Geolocation.checkPermissions();
      if (permissions.coarseLocation != "granted")
        await Geolocation.requestPermissions();

      const location = await Geolocation.getCurrentPosition();

      if (!mapMoved) {
        coords = {
          lat: location.coords.latitude,
          lng: location.coords.longitude,
        };
      }

      const opencage = await fetch(
        `https://api.opencagedata.com/geocode/v1/json?key=${opencageAPIKey}&q=${coords.lat}, ${coords.lng}`
      );
      const jsonData = await opencage.json();

      if (jsonData.results.length < 1) {
        errorLocating.value = true;
        return;
      }

      const locationData = jsonData.results[0];

      // If no postcode is found for location, use secondary API to look it up
      if (!locationData.components?.postcode) {
        const postcodeData = await fetch(
          `https://api.postcodes.io/postcodes?lon=${coords.lng}&lat=${coords.lat}`
        );
        const postcodeJson = await postcodeData.json();

        if (!postcodeJson.result?.length > 0) {
          errorLocating.value = true;
        } else {
          locationData.components.postcode = postcodeJson.result[0].postcode;
        }
      }

      updatingLocation.value = false;

      //   // Add restaurant name to lookup for accuracy
      //   if (locationData.components._type == "restaurant")
      //     locationData.components.building = locationData.components.restaurant;

      topHit.value = locationData;

      // Set up map if this is the first time location has been retrieved
      if (!mapMoved) {
        setupMap(location.coords);
      } else {
        checkForNearProperties(topHit.value);
      }
    };

    const activateSearch = () => {
      searchActive.value = true;
      setTimeout(() => {
        searchbox.value.$el.setFocus();
      }, 500);
    };

    const search = async () => {
      searchActive.value = false;
      const opencage = await fetch(
        `https://api.opencagedata.com/geocode/v1/json?key=${opencageAPIKey}&q=${searchTerm.value}`
      );
      const jsonData = await opencage.json();

      if (jsonData.status.message == "OK" && jsonData.results.length > 0) {
        const location = jsonData.results[0].geometry;
        map.setCenter(location);
        updateAddress();
      }
    };

    const getPrices = () => {
      router.push({
        name: "GetPrices",
        params: {
          street_number: topHit.value.components.house_number,
          street: topHit.value.components.road,
          town: topHit.value.components.city,
          postcode: topHit.value.components.postcode,
          latitude: "" + topHit.value.geometry.lat,
          longitude: "" + topHit.value.geometry.lng,
          full: topHit.value.formatted.split(",").splice(1).join(","),
          title: topHit.value.formatted.split(",")[0],
        },
      });
    };

    onMounted(() => {
      getLocation();
    });

    return {
      // methods
      search,
      activateSearch,
      getPrices,
      // variables
      updatingLocation,
      topHit,
      searchActive,
      searchTerm,
      searchbox,
      //   icons
      searchCircleOutline,
    };
  },
};
</script>

<style scoped>
ion-content::-webkit-scrollbar {
  display: none;
}
</style>
