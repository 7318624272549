<template>
  <ion-page>
    <uncoded-header />
    <ion-content scroll-y="false" v-if="property">
      <swiper
        pagination
        :modules="modules"
        direction="vertical"
        class="fullheight"
        style="position: absolute; top: 0px"
      >
        <swiper-slide v-for="(photo, index) in photos" :key="index">
          <div class="fullheight">
            <img :src="photo.url" class="fill cover" />
          </div>
          <div
            class="ph uppercase cd boldFont fs"
            style="
              position: absolute;
              bottom: 20px;
              background: rgba(var(--ion-color-light-rgb), 0.8);
              border-radius: 5px;
            "
          >
            {{ photo.description }}
          </div>
        </swiper-slide>
      </swiper>

      <div class="fullwidth wrap keepOnTop o8 absolute">
        <div
          class="flex left cl uppercase titleFont fm pt2 pl2 bgd"
          style="padding-bottom: 5px"
        >
          <div>{{ property.title }}</div>
          <ion-icon
            :icon="shieldCheckmarkOutline"
            class="ft superscript cp"
            v-if="property?.verified"
          />
        </div>

        <nav-header @back="back">Gallery</nav-header>

        <div class="grow"></div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  chevronBackCircleOutline,
  shieldCheckmarkOutline,
} from "ionicons/icons";
import { onMounted, ref } from "@vue/runtime-core";

import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "@ionic/vue/css/ionic-swiper.css";
import { useRoute, useRouter } from "vue-router";
import UncodedHeader from "@/components/UncodedHeader.vue";
import { getURL, getFirebaseDocument } from "@/composables/firebase";

export default {
  components: { Swiper, SwiperSlide, UncodedHeader },
  setup() {
    const route = useRoute();
    const propertyID = route.params.propertyID;
    const floorID = route.params.floorID;
    const spaceID = route.params.spaceID;

    const modules = [Pagination];
    const property = ref();
    const photos = ref([]);
    const router = useRouter();

    const refreshData = async () => {
      property.value = await getFirebaseDocument("properties", propertyID);
      const space = await getFirebaseDocument(
        `properties/${propertyID}/floors/${floorID}/spaces`,
        spaceID
      );

      photos.value = [];
      space.photos?.forEach(async (photo) => {
        const url = await getURL(photo.path);
        photos.value.push({ url: url, description: photo.description });
      });
    };

    const back = () => {
      router.back();
    };

    onMounted(() => {
      refreshData();
    });

    return {
      back,
      photos,
      property,
      chevronBackCircleOutline,
      shieldCheckmarkOutline,
      modules,
    };
  },
};
</script>
