<template>
  <ion-page>
    <uncoded-header title="Edit Property" />

    <ion-content>
      <div class="flex vertical p" v-if="space">
        <property-header :property="property" />
        <nav-header @back="back">Photos</nav-header>
        <input type="file" @change="addPhoto" ref="photoSelector" hidden />
        <div class="fullwidth">
          <ion-item lines="none" class="fullwidth"
            ><ion-label slot="end">Reorder photos</ion-label><ion-toggle v-model="allowReorder" slot="end"></ion-toggle>
          </ion-item>

          <ion-list class="fullwidth">
            <ion-reorder-group :disabled="!allowReorder" @ionItemReorder="reorderPhotos($event)">
              <ion-item v-for="photo in space.photos" :key="photo.id" lines="none">
                <ion-thumbnail slot="start" class="thumbnail">
                  <img :src="photo.url" :alt="photo.description" />
                </ion-thumbnail>
                <ion-input v-model="photo.description" placeholder="Description" @ionInput="markDirty()"></ion-input>
                <ion-reorder slot="end"></ion-reorder>
                <ion-icon v-if="!allowReorder" :icon="trashOutline" slot="end" color="danger" @click="deletePhoto(photo)" />
              </ion-item>
            </ion-reorder-group>
          </ion-list>
        </div>
        <div class="grow"></div>
        <ion-button class="fullwidth medium primary" @click="triggerAddPhoto">Add photo</ion-button>
      </div>
      <wait v-else>Loading photos</wait>
    </ion-content>
  </ion-page>
</template>

<script>
import { computed, ref } from "@vue/runtime-core";
import { useRoute, useRouter } from "vue-router";
import { chevronForward, trashOutline } from "ionicons/icons";
import { alertController, onIonViewWillEnter } from "@ionic/vue";
import UncodedHeader from "@/components/UncodedHeader";
import PropertyHeader from "@/components/PropertyHeader.vue";
import NavHeader from "@/components/NavHeader.vue";
import { usePropertyStore } from "@/stores/property";
import { storeToRefs } from "pinia";

export default {
  components: { NavHeader, PropertyHeader, UncodedHeader },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const dirty = ref(false);
    const allowReorder = ref(false);
    const propertyID = route.params.propertyID;
    const floorID = route.params.floorID;
    const spaceID = route.params.spaceID;

    const propertyStore = usePropertyStore();
    const { property, floor } = storeToRefs(propertyStore);
    const space = computed(() => floor.value?.spaces?.find((s) => s.id == spaceID));

    const photoSelector = ref();

    onIonViewWillEnter(() => {
      propertyStore.setPropertyID(propertyID);
      propertyStore.setFloorID(floorID);
    });

    const markDirty = () => {
      dirty.value = true;
    };

    const back = async () => {
      if (dirty.value) {
        const alert = await alertController.create({
          cssClass: "alert",
          header: "Are you sure?",
          message: "You have unsaved changes.",
          buttons: [
            {
              text: "Save & Close",
              handler: () => {
                propertyStore.saveSpace(space.value.id);
                router.back();
              },
            },
            { text: "Cancel" },
            {
              text: "Discard Changes",
              role: "destructive",
              handler: () => {
                router.back();
              },
            },
          ],
        });
        await alert.present();
      } else {
        router.back();
      }
    };

    const deletePhoto = async (photo) => {
      const alert = await alertController.create({
        cssClass: "alert",
        header: "Are you sure?",
        message: "This photo and associated information will be deleted immediately.",
        buttons: [
          {
            text: "Delete",
            role: "destructive",
            handler: () => {
              propertyStore.deleteSpacePhoto(space.value, photo);
            },
          },
          {
            text: "Cancel",
            role: "cancel",
          },
        ],
      });
      await alert.present();
    };

    const addPhoto = async () => {
      const files = event.target.files;
      const file = files[0];
      propertyStore.addSpacePhoto(space.value, file);
      photoSelector.value.value = "";
    };

    const reorderPhotos = (e) => {
      const from = e.detail.from;
      const to = e.detail.to;
      markDirty();
      space.value.photos = e.detail.complete(space.value.photos);
      for (const [i, item] of space.value.photos.entries()) {
        item.index = i;
      }
    };

    const triggerAddPhoto = () => {
      photoSelector.value.click();
    };

    return {
      // variables
      property,
      space,
      allowReorder,
      photoSelector,
      // methods
      addPhoto,
      deletePhoto,
      reorderPhotos,
      triggerAddPhoto,
      markDirty,
      back,
      // icons
      chevronForward,
      trashOutline,
    };
  },
};
</script>
