<template>
  <ion-page>
    <uncoded-header />

    <ion-content scroll-y="false" v-if="property">
      <property-header />
      <nav-header @back="back">Score explanation</nav-header>

      <div class="titleFont cp f2 tc p">
        Why was this property scored {{ score }}/4?
      </div>
      <div v-html="descriptions[section]" class="ph"></div> </ion-content
  ></ion-page>
</template>

<script>
import { useRoute, useRouter } from "vue-router";
import UncodedHeader from "@/components/UncodedHeader";
import { usePropertyStore } from "@/stores/property";
import { storeToRefs } from "pinia";
import PropertyHeader from "../common/PropertyHeader";

export default {
  components: { UncodedHeader, PropertyHeader },
  setup() {
    const route = useRoute();
    const router = useRouter();

    const propertyID = route.params.propertyID;
    const section = route.params.section;
    const score = route.params.score;

    const propertyStore = usePropertyStore();
    const { property } = storeToRefs(propertyStore);

    propertyStore.setPropertyID(propertyID);

    const descriptions = [
      `<p>Under 5 days is considered very quick to have connectivity installed and commissioned,
      so properties that meet this target are scored highest.</p>
      <p>Between 5-30 days will score 3; 31-60 days scores 2,
      and the lowest score is for properties that have a lead time in excess of 60 days.</p>`,
      `<p>When a building has more than 2 risers it is scored 4. Two risers will score 3 and a single riser means that the property gets a score of 1.</p>`,
      `<p>This score is directly linked to the number of services providers for which the property is on-network. Four or more providers gains the maximum score.</p>`,
      `<p>This score is directly linked to the number of entry points there are to the building. A score of 1 indicates that there are no existing entry points, while 4 means that there are more than 2 entry points.</p>`,
    ];

    const back = () => {
      router.back();
    };

    return {
      back,
      property,
      section,
      score,
      descriptions,
    };
  },
};
</script>
