<template>
  <ion-page>
    <uncoded-header />
    <ion-content scroll-y="false">
      <div class="flex vertical ph" v-if="!wait">
        <property-header />
        <nav-header @back="back">Links</nav-header>

        <ion-item class="fullwidth" v-for="(link, i) in filteredLinks" :key="i" @click="goToURL(link.url)">
          <ion-icon :icon="linkOutline" slot="start" color="light" />
          <div class="fullwidth">
            <div class="fullwidth">{{ link.description }}</div>
            <div class="fs o4">{{ link.url }}</div>
          </div>
        </ion-item>

        <div class="grow"></div>
      </div>
      <wait v-else />
    </ion-content>
  </ion-page>
</template>

<script>
import { linkOutline } from "ionicons/icons";
import { computed, ref } from "@vue/runtime-core";
import { useRoute, useRouter } from "vue-router";

import { isPlatform, onIonViewWillEnter } from "@ionic/vue";
import UncodedHeader from "@/components/UncodedHeader";
import PropertyHeader from "../common/PropertyHeader";
import { usePropertyStore } from "@/stores/property";
import { useUserStore } from "@/stores/user";
import { storeToRefs } from "pinia";

export default {
  name: "PropertyDocuments",
  components: { UncodedHeader, PropertyHeader },
  setup() {
    const wait = ref(false);
    const route = useRoute();
    const router = useRouter();

    const propertyID = route.params.propertyID;

    const propertyStore = usePropertyStore();
    const userStore = useUserStore();
    const { property } = storeToRefs(propertyStore);
    const { user } = storeToRefs(userStore);

    onIonViewWillEnter(() => {
      propertyStore.setPropertyID(propertyID);
    });

    const filteredLinks = computed(() => {
      const hasAccess = user.value?.properties?.includes(propertyID);
      if (!property.value?.links) return null;
      return property.value.links?.filter((link) => !link.private || hasAccess);
    });

    const goToURL = (url) => {
      window.open(url, "_new");
    };

    const back = () => {
      router.back();
    };

    return {
      // variables
      property,
      wait,
      // methods
      isPlatform,
      back,
      goToURL,
      filteredLinks,
      //icons
      linkOutline,
    };
  },
};
</script>
