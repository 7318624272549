<template>
  <div class="flex p">
    <ion-button class="equal light" expand="full" @click="getHelp">
      <div class="flex centered">
        <ion-icon :icon="chatboxEllipsesOutline" />
        <ion-label>Get Help</ion-label>
      </div></ion-button
    >
    <ion-button class="equal medium" expand="full" @click="share">
      <div class="flex centered">
        <ion-icon :icon="shareOutline" />
        <ion-label>Share</ion-label>
      </div>
    </ion-button>
  </div>
</template>

<script>
import { shareOutline, chatboxEllipsesOutline } from "ionicons/icons";
import { Share } from "@capacitor/share";
import { createFirebaseDocument, getCurrentUser } from "@/composables/firebase";
import { modalController } from "@ionic/vue";
import HelpModal from "./HelpModal.vue";
import { Clipboard } from "@capacitor/clipboard";
import { toast } from "@/composables/utilities";

export default {
  props: ["property", "user"],
  setup(props) {
    const getHelp = async () => {
      const mainContent = document.querySelector("#main-content");

      const modal = await modalController.create({
        component: HelpModal,
        presentingElement: mainContent,
        canDismiss: true,
        componentProps: { property: props.property },
      });
      return modal.present();
    };

    const share = async () => {
      const user = await getCurrentUser();
      //Copy shortlist to links collection
      const data = {
        type: "property",
        propertyID: props.property?.id,
        sharerID: user?.email,
        sharerName: `${props.user?.firstname || ""} ${props.user?.lastname || ""}`,
      };

      const shareDoc = await createFirebaseDocument("links", data);
      const url = `${process.env.VUE_APP_DOMAIN}/link/${shareDoc.id}`;
      try {
        await Share.share({
          title: props.property?.title,
          text: "Check out this property on Online Inside",
          url: url,
          dialogTitle: "Share this property",
        });
      } catch (error) {
        try {
          await Clipboard.write({ string: url });
          toast(`Property link copied to clipboard`, 2000);
        } catch (error) {
          console.log("Error creating link", error);
        }
      }
    };

    return {
      // functions
      getHelp,
      share,
      //variables
      // icons
      shareOutline,
      chatboxEllipsesOutline,
    };
  },
};
</script>
