<template>
  <ion-page>
    <ion-content>
      <div class="flex vertical left p2">
        <!-- Header  -->
        <div class="flex">
          <div></div>
          <ion-button
            expand="full"
            class="titleFont cl fl bgd bdl m"
            style="width: 150px"
            @click="login()"
            >Login</ion-button
          >
        </div>

        <!-- Body  -->
        <div class="flex vertical centered">
          <div class="flex centered">
            <div class="flex top autowidth fullheight">
              <img
                src="/img/logos/logo_text_white.png"
                alt="logo"
                class="oiLogo"
              />
            </div>
            <div>
              <div class="f2 description" style="width: 30vw">
                A better connectivity experience for the commercial property
                sector.
              </div>

              <form
                action="https://uncoded.us18.list-manage.com/subscribe/post?u=e765c294e72ffc6d5f5d7958a&amp;id=6b96d8a59b"
                method="post"
                id="mc-embedded-subscribe-form"
                name="mc-embedded-subscribe-form"
                class="validate"
                target="_blank"
                novalidate
              >
                <div class="flex">
                  <input
                    type="email"
                    value=""
                    name="EMAIL"
                    placeholder="Enter your email"
                    class="formElement fullwidth p"
                    id="mce-EMAIL"
                  />

                  <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
                  <div
                    style="position: absolute; left: -5000px"
                    aria-hidden="true"
                  >
                    <input
                      type="text"
                      name="b_e765c294e72ffc6d5f5d7958a_6b96d8a59b"
                      tabindex="-1"
                      value=""
                    />
                  </div>

                  <input
                    type="submit"
                    value="Subscribe"
                    name="subscribe"
                    id="mc-embedded-subscribe"
                    class="formElement titleFont fl"
                    style="width: 150px"
                  />
                </div>
                <div id="mce-responses" class="mt">
                  <div
                    class="response"
                    id="mce-error-response"
                    style="display: none"
                  ></div>
                  <div
                    class="response"
                    id="mce-success-response"
                    style="display: none"
                  ></div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { onMounted } from "@vue/runtime-core";
import { useRouter } from "vue-router";
export default {
  setup() {
    const router = useRouter();

    onMounted(() => {
      const mailChimpScript = document.createElement("script");
      mailChimpScript.setAttribute(
        "src",
        "https://s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js"
      );
      document.head.appendChild(mailChimpScript);
    });

    const login = () => {
      router.push({ name: "Login" });
    };

    return { login };
  },
};
</script>

<style scoped>
.formElement {
  height: 60px;
  background-color: var(--ion-color-dark);
  color: var(--ion-color-light);
  border: 1px solid var(--ion-color-light);
}
.oiLogo {
  width: 15vw;
  margin-right: 80px;
}

.description {
  margin-bottom: 80px;
}
</style>
