<template>
  <ion-page>
    <uncoded-header title="Edit Property" />

    <ion-content>
      <div class="flex vertical fullheight p left">
        <property-header :property="property" />
        <nav-header @back="back">Floors</nav-header>
        <div class="fullwidth">
          <ion-item lines="none"
            ><ion-label slot="end">Reorder floors</ion-label><ion-toggle v-model="allowFloorsReorder" slot="end"></ion-toggle>
          </ion-item>

          <ion-reorder-group :disabled="!allowFloorsReorder" @ionItemReorder="reorderFloors($event)" class="fullwidth">
            <div v-for="floor in propertyFloors" :key="floor.id" class="action cs" @click="showFloor(floor)">
              <ion-label class="fullwidth keepOnTop" :color="floor.name == 'New Floor' ? 'light' : 'secondary'">{{ floor.name }}</ion-label>
              <ion-icon :icon="chevronForward" v-if="!allowFloorsReorder" />
              <ion-reorder v-else />
            </div>
          </ion-reorder-group>
          <div class="action cp" v-if="!allowFloorsReorder" @click="addFloor">
            <ion-label class="fullwidth keepOnTop">Add new floor</ion-label>
            <ion-icon :icon="chevronForward" />
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { ref } from "@vue/runtime-core";
import { useRoute, useRouter } from "vue-router";
import { chevronForward, trashOutline } from "ionicons/icons";
import { alertController, onIonViewWillEnter } from "@ionic/vue";
import UncodedHeader from "@/components/UncodedHeader";
import PropertyHeader from "@/components/PropertyHeader.vue";
import NavHeader from "@/components/NavHeader.vue";
import { usePropertyStore } from "@/stores/property";
import { storeToRefs } from "pinia";

export default {
  name: "Floors",
  components: { NavHeader, PropertyHeader, UncodedHeader },
  setup() {
    const dirty = ref(false);
    const propertyID = useRoute().params.propertyID;
    const allowFloorsReorder = ref(false);
    const ready = ref(false);
    const router = useRouter();
    const propertyStore = usePropertyStore();
    const { property, propertyFloors } = storeToRefs(propertyStore);

    onIonViewWillEnter(() => {
      propertyStore.setPropertyID(propertyID);
    });

    const save = async (pop = true) => {
      propertyStore.saveFloors();
      if (pop) router.back();
    };

    const markDirty = () => {
      dirty.value = true;
    };

    const back = async () => {
      if (dirty.value) {
        const alert = await alertController.create({
          cssClass: "alert",
          header: "Are you sure?",
          message: "You have unsaved changes.",
          buttons: [
            {
              text: "Save & Close",
              handler: () => {
                propertyStore.saveFloors();
                router.back();
              },
            },
            { text: "Cancel" },
            {
              text: "Discard Changes",
              role: "destructive",
              handler: () => {
                propertyStore.restoreFloors();
                router.back();
              },
            },
          ],
        });
        await alert.present();
      } else {
        router.back();
      }
    };

    const addFloor = async () => {
      const newDoc = await propertyStore.createFloor();
      showFloor(newDoc);
    };

    const reorderFloors = (e) => {
      const from = e.detail.from;
      const to = e.detail.to;

      propertyFloors.value.forEach((item) => {
        if (from > to && item.index >= to && item.index < from) {
          item.index = item.index + 1;
          return;
        }
        if (from < to && item.index <= to && item.index > from) {
          item.index = item.index - 1;
          return;
        }
        if (item.index == from) item.index = to;
      });
      markDirty();
      e.detail.complete();
    };

    const showFloor = (floor) => {
      router.push({
        name: "EditPropertyFloor",
        params: { propertyID: propertyID, floorID: floor.id },
      });
    };

    return {
      // variables
      ready,
      propertyFloors,
      property,
      allowFloorsReorder,
      // methods
      showFloor,
      markDirty,
      back,
      addFloor,
      reorderFloors,
      // icons
      chevronForward,
      trashOutline,
    };
  },
};
</script>
