<template>
  <ion-page>
    <uncoded-header title="Edit Organisation" />

    <nav-header class="cl" @back="back">{{ organisation?.name }}</nav-header>

    <ion-content :scroll-y="false" v-if="organisation">
      <div class="flex vertical">
        <div class="scroll fullwidth p">
          <div>
            <img
              :src="
                organisation.logo
                  ? organisation.logo
                  : '/img/placeholders/transparent.png'
              "
              :alt="organisation.name"
              class="logo light"
            />
            <input type="file" @change="addLogo" ref="logoSelector" hidden />
            <div class="flex nogap">
              <ion-button
                @click="triggerAddLogo"
                expand="full"
                class="primary equal"
                >{{ organisation.logo ? "Change" : "Add" }} logo</ion-button
              >
              <ion-button
                @click="deleteLogo"
                :disabled="!organisation.logo"
                expand="full"
                class="danger equal"
                >Delete logo</ion-button
              >
            </div>
          </div>

          <ion-item class="fullwidth">
            <ion-label color="primary" position="stacked">Name</ion-label>
            <ion-input v-model="organisation.name" @ionInput="markDirty()" />
          </ion-item>

          <ion-item class="fullwidth">
            <ion-label color="primary" position="stacked">Website</ion-label>
            <ion-input v-model="organisation.website" @ionInput="markDirty()" />
          </ion-item>

          <ion-item class="fullwidth">
            <ion-label color="primary" position="stacked">Address</ion-label>
            <ion-input
              placeholder="Line 1"
              v-model="organisation.address.line1"
              @ionInput="markDirty()"
            />
            <ion-input
              placeholder="Line 2"
              v-model="organisation.address.line2"
              @ionInput="markDirty()"
            />
            <ion-input
              placeholder="Line 3"
              v-model="organisation.address.line3"
              @ionInput="markDirty()"
            />
            <ion-input
              placeholder="Town"
              v-model="organisation.address.town"
              @ionInput="markDirty()"
            />
            <ion-input
              placeholder="County"
              v-model="organisation.address.county"
              @ionInput="markDirty()"
            />
            <ion-input
              placeholder="Postcode"
              v-model="organisation.address.postcode"
              @ionInput="markDirty()"
            />
          </ion-item>
          <ion-item class="fullwidth">
            <ion-label color="primary" position="stacked">Phone</ion-label>
            <ion-input
              v-model="organisation.phone"
              placeholder="Phone number"
              @ionInput="markDirty()"
            />
          </ion-item>
        </div>

        <div class="p fullwidth">
          <ion-button color="danger" expand="full" @click="confirmDelete()"
            >Delete Organisation</ion-button
          >
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import UncodedHeader from "@/components/UncodedHeader.vue";
import { computed, onMounted, ref } from "@vue/runtime-core";
import { useRoute, useRouter } from "vue-router";
import { alertController } from "@ionic/vue";
import { usePropertyStore } from "@/stores/property";
import { storeToRefs } from "pinia";

export default {
  components: { UncodedHeader },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const contactTypeID = route.params.contactTypeID;
    const organisationID = route.params.organisationID;
    const dirty = ref(false);
    const logoSelector = ref();
    const propertyStore = usePropertyStore();
    const { allContactTypes } = storeToRefs(propertyStore);

    const contactType = computed(() =>
      allContactTypes.value.find((ct) => ct.id == contactTypeID)
    );
    const organisation = computed(() => {
      const data = contactType.value?.organisations?.find(
        (o) => o.id == organisationID
      );
      if (data && !data.address) data.address = {};
      return data;
    });

    const markDirty = () => {
      dirty.value = true;
    };

    const back = async () => {
      if (dirty.value) {
        const alert = await alertController.create({
          cssClass: "alert",
          header: "Are you sure?",
          message: "You have unsaved changes.",
          buttons: [
            {
              text: "Save & Close",
              handler: () => {
                save();
              },
            },
            { text: "Cancel" },
            {
              text: "Discard Changes",
              role: "destructive",
              handler: () => {
                router.back();
              },
            },
          ],
        });
        await alert.present();
      } else {
        router.back();
      }
    };

    const save = async (pop = true) => {
      propertyStore.saveOrganisation(contactTypeID, organisationID);
      if (pop) router.back();
    };

    const confirmDelete = async () => {
      const alert = await alertController.create({
        cssClass: "alert",
        header: "Are you sure?",
        message:
          "Deleting this organisation will also delete all members of the organisation.",
        buttons: [
          {
            text: "Delete",
            role: "destructive",
            handler: () => {
              propertyStore.deleteOrganisation(contactTypeID, organisationID);
            },
          },
          { text: "Cancel" },
        ],
      });
      await alert.present();
    };

    const triggerAddLogo = () => {
      logoSelector.value.click();
    };

    const addLogo = async () => {
      const files = event.target.files;
      const file = files[0];
      propertyStore.addOrganisationLogo(contactTypeID, organisationID, file);
    };

    const deleteLogo = async () => {
      propertyStore.deleteOrganisationLogo(contactTypeID, organisationID);
    };

    return {
      // variables
      dirty,
      organisation,
      logoSelector,
      // methods
      triggerAddLogo,
      addLogo,
      deleteLogo,
      confirmDelete,
      markDirty,
      back,
      // icons
    };
  },
};
</script>
