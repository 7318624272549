<template>
  <ion-page>
    <uncoded-header title="Edit Property" />
    <ion-content :scroll-y="false" v-if="property">
      <div class="flex vertical nogap left">
        <property-header :property="property" />
        <nav-header @back="back">WiFi</nav-header>
        <div class="fullwidth p">
          <ion-item class="fullwidth">
            <ion-label position="stacked" color="primary">SSID</ion-label>
            <ion-input v-model="property.publicWifi" placeholder="Public SSID" @ionInput="markDirty()"></ion-input>
          </ion-item>
          <ion-item class="fullwidth">
            <ion-label position="stacked" color="primary">Password</ion-label>
            <ion-input v-model="property.publicWifiPassword" placeholder="Public SSID Password" @ionInput="markDirty()"></ion-input>
          </ion-item>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { ref } from "@vue/runtime-core";
import { useRoute, useRouter } from "vue-router";
import { chevronForward } from "ionicons/icons";
import { alertController, onIonViewWillEnter } from "@ionic/vue";
import UncodedHeader from "@/components/UncodedHeader";
import PropertyHeader from "@/components/PropertyHeader.vue";
import NavHeader from "@/components/NavHeader.vue";
import { usePropertyStore } from "@/stores/property";
import { storeToRefs } from "pinia";

export default {
  name: "Wifi",
  components: { UncodedHeader, PropertyHeader, NavHeader },

  setup() {
    const dirty = ref(false);
    const propertyID = useRoute().params.propertyID;
    const propertyStore = usePropertyStore();
    const { property } = storeToRefs(propertyStore);
    const router = useRouter();

    onIonViewWillEnter(() => {
      propertyStore.setPropertyID(propertyID);
    });

    const save = async () => {
      propertyStore.saveProperty();
      router.back();
    };

    const markDirty = () => {
      dirty.value = true;
    };

    const back = async () => {
      if (dirty.value) {
        const alert = await alertController.create({
          cssClass: "alert",
          header: "Are you sure?",
          message: "You have unsaved changes.",
          buttons: [
            {
              text: "Save & Close",
              handler: () => {
                save();
              },
            },
            { text: "Cancel" },
            {
              text: "Discard Changes",
              role: "destructive",
              handler: () => {
                router.back();
              },
            },
          ],
        });
        await alert.present();
      } else {
        router.back();
      }
    };

    return {
      // variables
      property,
      // methods
      markDirty,
      back,
      // icons
      chevronForward,
    };
  },
};
</script>
