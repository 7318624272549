<template>
  <ion-page>
    <uncoded-header title="Edit Property" />

    <ion-content :scroll-y="false">
      <div class="flex vertical nogap left">
        <property-header :property="property" />
        <nav-header @back="back">Accreditations</nav-header>
        <div class="fullwidth p">
          <ion-accordion-group v-for="accreditation in allAccreditations" :key="accreditation.id" class="fullwidth">
            <ion-accordion>
              <ion-item slot="header" color="dark" class="fs uppercase fullwidth"
                ><div>{{ accreditation.name }}</div>
                <div class="fontBold cs" v-if="selectedLevel(accreditation)">&nbsp;- {{ selectedLevel(accreditation).name }}</div>
              </ion-item>
              <div slot="content">
                <ion-list>
                  <ion-radio-group v-model="propertyAccreditations[accreditation.id]" @ionChange="markDirty(accreditation)">
                    <ion-item @click="selectLevel(accreditation)">
                      <div :class="{ cs: !selectedLevel(accreditation) }">Not accredited</div>
                      <ion-radio slot="end" value="none" mode="ios" />
                    </ion-item>

                    <ion-item v-for="level in accreditation.levels" :key="level.id" @click="selectLevel(accreditation, level)">
                      <div :class="{ cs: selectedLevel(accreditation)?.id == level.id }">{{ level.name }}</div>
                      <ion-radio slot="end" :value="level.id" mode="ios" />
                    </ion-item>
                  </ion-radio-group>
                </ion-list>

                <ion-item v-if="selectedLevel(accreditation)" @click="debug(accreditation)">
                  <ion-label color="primary" v-if="property.accreditations.find((a) => a.id == accreditation.id)?.certificate">{{
                    property.accreditations.find((a) => a.id == accreditation.id)?.certificate.filename
                  }}</ion-label>
                  <ion-button
                    color="primary"
                    slot="end"
                    v-if="property.accreditations.find((a) => a.id == accreditation.id)?.certificate"
                    @click="removeCertificate(accreditation)"
                    >Remove certificate</ion-button
                  >
                  <ion-button color="primary" expand="full" class="fullwidth" v-else @click="triggerUploadCertificate(accreditation)"
                    >Upload certificate</ion-button
                  >
                </ion-item>
              </div>
            </ion-accordion>
          </ion-accordion-group>
          <input type="file" hidden ref="documentSelector" @change="uploadCertificate" />
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { computed, ref } from "@vue/runtime-core";
import { useRoute, useRouter } from "vue-router";
import { chevronForward } from "ionicons/icons";
import { alertController, onIonViewWillEnter } from "@ionic/vue";
import UncodedHeader from "@/components/UncodedHeader";
import PropertyHeader from "@/components/PropertyHeader.vue";
import NavHeader from "@/components/NavHeader.vue";
import { usePropertyStore } from "@/stores/property";
import { storeToRefs } from "pinia";
import { uploadFirebaseFile, deleteFirebaseFile } from "@/composables/firebase";

export default {
  name: "Accreditations",
  components: { NavHeader, PropertyHeader, UncodedHeader },

  setup() {
    const dirty = ref(false);
    const propertyID = useRoute().params.propertyID;
    const propertyStore = usePropertyStore();
    const { property, allAccreditations } = storeToRefs(propertyStore);
    const router = useRouter();
    let selectedAccreditation;
    const documentSelector = ref();

    const propertyAccreditations = computed(() => {
      const result = {};
      if (!property.value?.accreditations || property.value.accreditations.length == 0) return result;
      for (const accreditation of property.value.accreditations) {
        const referenceAccreditation = allAccreditations.value.find((a) => a.id == accreditation.id);
        const referenceLevel = referenceAccreditation?.levels.find((l) => l.id == accreditation.levelID);
        if (referenceLevel) {
          result[accreditation.id] = { id: referenceLevel.id, name: referenceLevel.name };
        }
      }
      return result;
    });

    onIonViewWillEnter(async () => {
      await propertyStore.setPropertyID(propertyID);
    });

    const markDirty = (accreditation) => {
      dirty.value = true;
    };

    const selectedLevel = (accreditation) => {
      if (propertyAccreditations.value?.length == 0) return;
      return propertyAccreditations.value[accreditation.id];
    };

    const selectLevel = (accreditation, level) => {
      if (level) {
        propertyStore.setPropertyAccreditationLevel(accreditation, level);
      } else {
        propertyStore.deletePropertyAccreditationLevel(accreditation);
      }
    };

    const triggerUploadCertificate = (accreditation) => {
      selectedAccreditation = accreditation;
      documentSelector.value.click();
    };

    const uploadCertificate = async () => {
      const files = event.target.files;
      if (files.length == 0) return;
      const file = files[0];
      const result = await uploadFirebaseFile(
        `Properties/${property.value.title}/documents/accreditations/${selectedAccreditation.name}`,
        file.name,
        file
      );
      await propertyStore.setPropertyAccreditationCertificate(selectedAccreditation, result);
      documentSelector.value.value = "";
    };

    const removeCertificate = async (accreditation) => {
      const alert = await alertController.create({
        cssClass: "alert",
        header: "Are you sure?",
        message: "This document will be deleted immediately.",
        buttons: [
          {
            text: "Delete",
            role: "destructive",
            handler: async () => {
              console.log(accreditation);
              await deleteFirebaseFile(accreditation.certificate.path);
              delete accreditation.certificate;
              await propertyStore.saveProperty();
            },
          },
          {
            text: "Cancel",
            role: "cancel",
          },
        ],
      });
      await alert.present();
    };

    const back = async () => {
      if (dirty.value) {
        const alert = await alertController.create({
          cssClass: "alert",
          header: "Are you sure?",
          message: "You have unsaved changes.",
          buttons: [
            {
              text: "Save & Close",
              handler: async () => {
                await propertyStore.saveProperty();
                router.back();
                dirty.value = false;
              },
            },
            { text: "Cancel" },
            {
              text: "Discard Changes",
              role: "destructive",
              handler: () => {
                propertyStore.revertProperty();
                router.back();
              },
            },
          ],
        });
        await alert.present();
      } else {
        router.back();
      }
    };

    const debug = (accreditation) => {
      console.log(propertyAccreditations.value);
    };

    return {
      // variables
      property,
      allAccreditations,
      propertyAccreditations,
      documentSelector,
      selectedLevel,
      selectLevel,
      // methods
      triggerUploadCertificate,
      uploadCertificate,
      removeCertificate,
      markDirty,
      back,
      debug,
      // icons
      chevronForward,
    };
  },
};
</script>
