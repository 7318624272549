<template>
  <ion-page>
    <uncoded-header />
    <ion-content scroll-y="false">
      <!-- <div class="fullheight bgd p2"> -->
      <div class="flex left cl uppercase titleFont fm pt2 pl2">
        <div>{{ property?.title }}</div>
        <ion-icon :icon="shieldCheckmarkOutline" class="ft superscript cp" v-if="property?.verified" />
      </div>
      <nav-header @back="back">Accreditations</nav-header>
      <ion-list>
        <ion-item
          color="dark"
          v-for="accreditationID in Object.keys(propertyAccreditations)"
          :key="accreditationID"
          @click="showCertificate(accreditationID)"
        >
          <ion-thumbnail slot="start"><img :src="propertyAccreditations[accreditationID]?.url" /></ion-thumbnail>
          <ion-label>
            <div>
              {{ getAccreditationDetails(accreditationID).name }}
              <ion-icon
                :icon="informationCircleOutline"
                @click="showAccreditation(accreditationID, propertyAccreditations[accreditationID], $event)"
              />
            </div>
            <ion-note>{{ propertyAccreditations[accreditationID].name }}</ion-note></ion-label
          >
          <ion-icon color="primary" slot="end" :icon="documentOutline" v-if="propertyAccreditations[accreditationID]?.certificate" />
        </ion-item>
      </ion-list>
      <!-- <div class="grid2 p">
        <ion-card
          class="card"
          v-for="accreditation in Object.keys(propertyAccreditations)"
          :key="accreditation"
          @click="showAccreditation(accreditation, propertyAccreditations[accreditation])"
        >
          <div class="flex centered mt">
            <img :src="propertyAccreditations[accreditation]?.url" class="accreditationLogo" />
          </div>

          <ion-card-title class="tc cp f2 titleFont mt">
            {{ propertyAccreditations[accreditation]?.name }}
          </ion-card-title>
          <ion-card-subtitle class="tc cl uppercase boldFont mb">{{ propertyAccreditations[accreditation].level }}</ion-card-subtitle>
        </ion-card>
      </div> -->
      <!-- </div> -->
    </ion-content>
  </ion-page>
</template>

<script>
import { chevronBackCircleOutline, documentOutline, informationCircleOutline, shieldCheckmarkOutline } from "ionicons/icons";

import { useRoute, useRouter } from "vue-router";
import UncodedHeader from "@/components/UncodedHeader";
import { usePropertyStore } from "@/stores/property";
import { storeToRefs } from "pinia";
import { onIonViewWillEnter } from "@ionic/vue";

export default {
  components: { UncodedHeader },
  setup() {
    const router = useRouter();
    const propertyID = useRoute().params.propertyID;
    const propertyStore = usePropertyStore();
    const { property, propertyAccreditations, allAccreditations } = storeToRefs(propertyStore);

    onIonViewWillEnter(async () => {
      await propertyStore.setPropertyID(propertyID);
    });

    const back = () => {
      router.back();
    };

    const getAccreditationDetails = (accreditationID) => allAccreditations.value.find((a) => a.id == accreditationID);

    const showCertificate = (accreditationID) => {
      if (!property.value.accreditations.find((a) => a.id == accreditationID).certificate) return;
      router.push({
        name: "Document",
        params: { propertyID: propertyID, path: property.value.accreditations.find((a) => a.id == accreditationID).certificate?.path },
      });
      console.log(property.value.accreditations.find((a) => a.id == accreditationID));
    };

    const showAccreditation = (accreditationID, level, event) => {
      event.cancelBubble = true;
      router.push({
        name: "PropertyAccreditation",
        params: {
          propertyID: propertyID,
          accreditationID: accreditationID,
          levelID: level.id,
        },
      });
    };

    return {
      property,
      propertyAccreditations,
      allAccreditations,
      getAccreditationDetails,
      back,
      showAccreditation,
      showCertificate,
      chevronBackCircleOutline,
      shieldCheckmarkOutline,
      documentOutline,
      informationCircleOutline,
    };
  },
};
</script>

<style scoped>
.accreditationLogo {
  width: 80%;
  aspect-ratio: 1;
  object-fit: contain;
}
</style>
