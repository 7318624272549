<template>
  <ion-page>
    <uncoded-header title="Edit Property" />

    <ion-content :scroll-y="false">
      <div class="flex vertical nogap">
        <property-header :property="property" />

        <div class="flex vertical ph">
          <div class="fullwidth">
            <div class="action" @click="go('EditPropertyDescription')">
              <ion-label class="fullwidth keepOnTop">Description</ion-label><ion-icon :icon="chevronForward" />
            </div>
            <div class="action" @click="go('EditPropertyLocation')">
              <ion-label class="fullwidth keepOnTop">Location</ion-label><ion-icon :icon="chevronForward" />
            </div>
            <div class="action" @click="go('EditPropertyWifi')">
              <ion-label class="fullwidth keepOnTop">Wifi</ion-label><ion-icon :icon="chevronForward" />
            </div>
            <div class="action" @click="go('EditPropertyAccreditations')">
              <ion-label class="fullwidth keepOnTop">Accreditations</ion-label><ion-icon :icon="chevronForward" />
            </div>
            <div class="action" @click="go('EditPropertyPhotos')">
              <ion-label class="fullwidth keepOnTop">Photos</ion-label><ion-icon :icon="chevronForward" />
            </div>
            <div class="action" @click="go('EditPropertyDocuments')">
              <ion-label class="fullwidth keepOnTop">Documents</ion-label><ion-icon :icon="chevronForward" />
            </div>
            <div class="action" @click="go('EditPropertyLinks')">
              <ion-label class="fullwidth keepOnTop">Links</ion-label><ion-icon :icon="chevronForward" />
            </div>
            <div class="action" @click="go('EditPropertyBullseye')">
              <ion-label class="fullwidth keepOnTop">Bullseye</ion-label><ion-icon :icon="chevronForward" />
            </div>
            <div class="action" @click="go('EditPropertyContacts')">
              <ion-label class="fullwidth keepOnTop">Contacts</ion-label><ion-icon :icon="chevronForward" />
            </div>
            <div class="action" @click="go('EditPropertyFloors')">
              <ion-label class="fullwidth keepOnTop">Floors</ion-label><ion-icon :icon="chevronForward" />
            </div>
            <div class="action" @click="go('EditPropertyQRCode')">
              <ion-label class="fullwidth keepOnTop">QR Code</ion-label><ion-icon :icon="chevronForward" />
            </div>
          </div>
          <div class="fullwidth">
            <ion-button class="fullwidth danger mb2" @click="confirmDelete">Delete property</ion-button>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import UncodedHeader from "@/components/UncodedHeader.vue";
import PropertyHeader from "@/components/PropertyHeader";
import { useRoute, useRouter } from "vue-router";
import { chevronForward } from "ionicons/icons";
import { alertController, onIonViewWillEnter } from "@ionic/vue";
import { usePropertyStore } from "@/stores/property";
import { storeToRefs } from "pinia";

export default {
  name: "EditProperty",
  components: { UncodedHeader, PropertyHeader },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const propertyID = route.params.propertyID;
    const propertyStore = usePropertyStore();
    const { property } = storeToRefs(propertyStore);

    onIonViewWillEnter(async () => {
      await propertyStore.setPropertyID(propertyID);
    });

    const cancel = () => {
      router.back();
    };

    const go = (name) => {
      router.push({ name: name, params: { propertyID: property.value.id } });
    };

    const confirmDelete = async () => {
      const alert = await alertController.create({
        cssClass: "alert",
        header: "Are you sure?",
        message: "Deleting this property will delete all associated floors, spaces and documents.",
        buttons: [
          {
            text: "Delete",
            role: "destructive",
            handler: () => {
              propertyStore.deleteProperty();
              router.back();
            },
          },
          { text: "Cancel" },
        ],
      });
      await alert.present();
    };

    // const deleteProperty = async () => {
    //   const property = await getFirebaseDocument("properties", propertyID);
    //   try {
    //     //Delete photos stored in Firebase storage
    //     property.documents?.forEach(async (document) => {
    //       await deleteFirebaseFile(document.path);
    //     });
    //     //Delete photos stored in Firebase storage
    //     property.photos?.forEach(async (photo) => {
    //       await deleteFirebaseFile(photo.path);
    //     });
    //   } catch (error) {
    //     console.log("Error deleting property", error.localizedDescription);
    //   }

    //   const floors = await getFirebaseCollection(
    //     `properties/${propertyID}/floors`
    //   );

    //   floors.forEach(async (floor) => {
    //     const spaces = await getFirebaseCollection(
    //       `properties/${propertyID}/floors/${floor.id}/spaces`
    //     );

    //     spaces.forEach(async (space) => {
    //       try {
    //         //Delete space document
    //         await deleteFirebaseDocument(
    //           `properties/${props.propertyID}/floors/${floor.id}/spaces`,
    //           space.id
    //         );
    //         //Delete photos stored in Firebase storage
    //         space.documents?.forEach(async (document) => {
    //           await deleteFirebaseFile(document.path);
    //         });
    //         //Delete photos stored in Firebase storage
    //         space.photos?.forEach(async (photo) => {
    //           await deleteFirebaseFile(photo.path);
    //         });
    //       } catch (error) {
    //         console.log("Error deleting space", error.localizedDescription);
    //       }
    //     });

    //     // Delete floor document
    //     try {
    //       //Delete space document
    //       await deleteFirebaseDocument(
    //         `properties/${props.propertyID}/floors`,
    //         floor.id
    //       );
    //       //Delete photos stored in Firebase storage
    //       floor.documents?.forEach(async (document) => {
    //         await deleteFirebaseFile(document.path);
    //       });
    //       //Delete photos stored in Firebase storage
    //       floor.photos?.forEach(async (photo) => {
    //         await deleteFirebaseFile(photo.path);
    //       });
    //     } catch (error) {
    //       console.log("Error deleting floor", error.localizedDescription);
    //     }
    //   });

    //   await deleteFirebaseDocument("properties", propertyID);

    //   router.back();
    // };

    return {
      // variables
      propertyID,
      property,
      // methods
      cancel,
      confirmDelete,
      go,
      // icons
      chevronForward,
    };
  },
};
</script>
