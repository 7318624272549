<template>
  <div id="bullseye" v-if="property">
    <div class="label top">
      <div class="container">
        Lead Time
        <ion-icon :icon="informationCircleOutline" />
      </div>
    </div>
    <div class="label right">
      <div class="container">
        Risers
        <ion-icon :icon="informationCircleOutline" />
      </div>
    </div>
    <div class="label bottom">
      <div class="container">
        Providers
        <ion-icon :icon="informationCircleOutline" />
      </div>
    </div>
    <div class="label left">
      <div class="container">
        Entry Points
        <ion-icon :icon="informationCircleOutline" />
      </div>
    </div>
    <div v-for="(score, i) in property.bullseyeScores" :key="i">
      <div v-for="j in 4" :key="j">
        <img :src="`/img/bullseye/${i + 1}${j}0.svg`" :id="`${i + 1}${j}0`" />
        <img
          :src="`/img/bullseye/${i + 1}${j}1.svg`"
          :id="`${i + 1}${j}1`"
          v-bind:class="{
            off: score < j || snapshotDelay,
            val1: j == 1,
            val2: j == 2,
            val3: j == 3,
            val4: j == 4,
          }"
        />
      </div>
    </div>
    <div>
      <div class="clickable top" @click="showDetail(0)" />
      <div class="clickable right" @click="showDetail(1)" />
      <div class="clickable bottom" @click="showDetail(2)" />
      <div class="clickable left" @click="showDetail(3)" />
    </div>
  </div>
</template>

<script>
import { informationCircleOutline } from "ionicons/icons";
import { ref } from "vue";
import { useRouter } from "vue-router";
export default {
  props: ["property"],
  setup(props) {
    const snapshotDelay = ref(true);
    const router = useRouter();

    setTimeout(() => {
      snapshotDelay.value = false;
    }, 1000);

    const showDetail = (section) => {
      router.push({
        name: "PropertyBullseyeDetails",
        params: {
          propertyID: props.property.id,
          section: section,
        },
      });
    };

    return { snapshotDelay, showDetail, informationCircleOutline };
  },
};
</script>

<style scoped>
#bullseye {
  position: relative;
  height: 70%;
  aspect-ratio: 1;
}

#bullseye img {
  position: absolute;
  height: 86%;
  top: 7%;
  left: 7%;
  opacity: 1;
  transition: opacity 1s;
  transform: rotate(45deg);
}

#bullseye .off {
  opacity: 0;
  transition: opacity 1s;
}

#bullseye .val1 {
  transition-delay: 0.1s;
}
#bullseye .val2 {
  transition-delay: 0.2s;
}
#bullseye .val3 {
  transition-delay: 0.4s;
}
#bullseye .val4 {
  transition-delay: 0.7s;
}
#bullseye .label {
  color: var(--ion-color-light);
  text-transform: uppercase;
  position: absolute;
  width: 100%;
  top: 0px;
  height: auto;
  text-align: center;
  font-size: 0.8em;
  letter-spacing: 0.3em;
}

#bullseye .label .container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2em;
}

#bullseye ion-icon {
  padding-left: 5px;
  font-size: 1.5em;
  color: var(--ion-color-primary);
}

#bullseye .label.right {
  height: 100%;
  transform: rotate(90deg);
}

#bullseye .label.bottom {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
#bullseye .label.left {
  height: 100%;
  transform: rotate(270deg);
}
#bullseye .clickable {
  position: absolute;
  height: 50%;
  width: 50%;
  top: 0px;
  left: 0px;
  transform: rotate(45deg);
}

#bullseye .clickable.top {
  transform-origin: 100% 100%;
}

#bullseye .clickable.right {
  top: 0px;
  left: 50%;
  transform-origin: 0% 100%;
}

#bullseye .clickable.bottom {
  top: 50%;
  left: 50%;
  transform-origin: 0% 0%;
}
#bullseye .clickable.left {
  top: 50%;
  left: 0px;
  transform-origin: 100% 0%;
}
</style>
