<template>
  <ion-page>
    <ion-header class="bgm">
      <ion-toolbar>
        <ion-title class="titleFont fg">Connectivity Concierge</ion-title>
        <ion-buttons slot="end" class="mr2">
          <ion-icon :icon="closeCircleOutline" class="fg" @click="close" />
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <div class="flex vertical bgl cd p2 safe-bottom">
        <ion-segment v-model="data.action">
          <ion-segment-button value="message">Send message</ion-segment-button>
          <ion-segment-button value="callback">Request callback</ion-segment-button>
        </ion-segment>

        <div class="flex vertical left autoheight grow">
          <ion-input class="darkBorder nogrow" placeholder="Your name" v-model="data.name"></ion-input>
          <ion-input class="darkBorder nogrow" placeholder="Your email" v-model="data.email" v-if="data.action == 'message'"></ion-input>
          <ion-input
            class="darkBorder nogrow"
            placeholder="Your phone number"
            v-model="data.phone"
            v-if="data.action == 'callback'"
          ></ion-input>
          <ion-input class="darkBorder nogrow" placeholder="Subject" v-model="data.subject" v-if="data.action == 'message'"></ion-input>
          <ion-textarea class="darkBorder grow" placeholder="Message" v-model="data.message" v-if="data.action == 'message'"></ion-textarea>
        </div>
        <div class="fullwidth">
          <ion-button expand="full" @click="performAction">{{ data.action == "message" ? "Send message" : "Request callback" }}</ion-button>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent, onMounted, reactive } from "vue";
import { closeCircleOutline } from "ionicons/icons";
import { getCurrentUser, runFunction } from "@/composables/firebase";
import { modalController } from "@ionic/vue";
import { toast } from "@/composables/utilities";
import { useUserStore } from "@/stores/user";
import { storeToRefs } from "pinia";

export default defineComponent({
  props: ["property"],
  setup(props) {
    const userStore = useUserStore();
    const { profile } = storeToRefs(userStore);
    const data = reactive({
      action: "message",
    });

    onMounted(async () => {
      const currentUser = await getCurrentUser();
      data.name = `${profile.value?.firstname || ""} ${profile.value?.lastname || ""}`;
      data.email = currentUser?.email;
      data.phone = profile.value?.phone;
      data.subject = `${props.property.title} enquiry`;
      data.property = {
        id: props.property?.id,
        title: props.property?.title,
      };
    });

    const close = () => {
      modalController.dismiss();
    };

    const performAction = async () => {
      await runFunction("responseRequest", { type: data.action, ...data });
      close();
      toast("Message sent.");
    };

    return {
      // methods
      close,
      performAction,
      //variables
      data,
      //icons
      closeCircleOutline,
    };
  },
});
</script>
