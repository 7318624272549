<template>
  <ion-page>
    <uncoded-header />
    <ion-content scroll-y="false" v-if="property">
      <div class="flex vertical bgd p">
        <property-header :property="property" />

        <nav-header @back="back">Contacts</nav-header>
        <div class="flex">
          <ion-accordion-group class="fullwidth">
            <ion-accordion v-for="type in allContactTypes" :key="type.id" class="fullwidth">
              <ion-item slot="header" color="dark"
                ><ion-label>{{ type.contactPlural }}</ion-label>
                <ion-icon :icon="addCircleOutline" slot="end" @click="addContacts(type.id, $event)" />
              </ion-item>
              <ion-item slot="content" lines="none">
                <div class="fullwidth">
                  <div v-if="type.organisations.filter(filterExistingOrganisations).length == 0">None added.</div>
                  <ion-item
                    v-for="organisation in type.organisations.filter(filterExistingOrganisations)"
                    :key="organisation.id"
                    class="fullwidth"
                  >
                    <div class="fullwidth">
                      <div class="titleFont fg mt">
                        {{ organisation.name }}
                      </div>
                      <div class="p fullwidth">
                        <div
                          class="flex pb fullwidth"
                          v-for="contact in organisation.contacts.filter(filterExistingContacts)"
                          :key="contact.id"
                          @click="debug(contact)"
                        >
                          <ion-thumbnail class="thumbnail">
                            <img :src="contact.url || '/img/placeholders/portrait.png'" />
                          </ion-thumbnail>
                          <div class="flex vertical">
                            <div class="fs grow fullwidth">{{ contact.firstname }} {{ contact.lastname }}</div>
                            <div class="ft fullwidth">{{ contact.role }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ion-item>
                </div>
              </ion-item>
            </ion-accordion>
          </ion-accordion-group>
        </div>
        <div class="grow"></div>
      </div>
    </ion-content>

    <ion-modal :is-open="modalVisible" presenting-element="rootElement" mode="md" @didDismiss="modalVisible = false">
      <ion-header>
        <ion-toolbar>
          <ion-title>Add Contacts</ion-title>
          <ion-label slot="end" @click="closeModal" class="cp uppercase fs mr">Done</ion-label>
        </ion-toolbar>
      </ion-header>

      <ion-content>
        <ion-accordion-group>
          <ion-accordion
            v-for="organisation in allContactTypes
              .find((t) => t.id == selectedTypeID)
              ?.organisations.filter((o) => o.contacts && o.contacts.length > 0)"
            :key="organisation.id"
          >
            <ion-item slot="header" color="dark">
              <ion-label
                ><div class="fs cs ml">{{ organisation.name }}</div></ion-label
              >
            </ion-item>

            <ion-list slot="content">
              <ion-item
                v-for="contact in organisation.contacts.filter(filterContacts)"
                :key="contact.id"
                color="dark"
                @click="addContact(organisation.id, contact.id)"
              >
                <ion-label
                  ><div class="fs cl ml2">{{ contact.firstname }} {{ contact.lastname }}</div></ion-label
                >
              </ion-item>
            </ion-list>
          </ion-accordion>
        </ion-accordion-group>
      </ion-content>
    </ion-modal>
  </ion-page>
</template>

<script>
import { useRoute, useRouter } from "vue-router";
import { addCircleOutline } from "ionicons/icons";
import UncodedHeader from "@/components/UncodedHeader";
import PropertyHeader from "@/components/PropertyHeader";
import { usePropertyStore } from "@/stores/property";
import { storeToRefs } from "pinia";
import { alertController, onIonViewWillEnter } from "@ionic/vue";
import { computed, onMounted, ref } from "vue-demi";

export default {
  components: { UncodedHeader, PropertyHeader },
  setup() {
    const dirty = ref(false);
    const propertyID = useRoute().params.propertyID;
    const propertyStore = usePropertyStore();
    const { property, allContactTypes } = storeToRefs(propertyStore);
    const router = useRouter();
    const selectedTypeID = ref();
    const modalVisible = ref(false);
    let rootElement;

    const propertyContacts = computed(() => {
      const result = [];
      for (const type in property.value?.contacts) {
        for (const organisation in property.value?.contacts[type]) {
          result.push(
            property.value?.contacts[type][organisation].map((c) => {
              return { tid: type, oid: organisation, cid: c };
            })
          );
        }
      }
      return result.flat();
    });

    onIonViewWillEnter(async () => {
      await propertyStore.setPropertyID(propertyID);
    });

    onMounted(() => {
      rootElement = document.querySelector(".ion-page");
    });

    const markDirty = () => {
      dirty.value = true;
    };

    const back = async () => {
      if (dirty.value) {
        const alert = await alertController.create({
          cssClass: "alert",
          header: "Are you sure?",
          message: "You have unsaved changes.",
          buttons: [
            {
              text: "Save & Close",
              handler: () => {
                propertyStore.saveProperty();
                router.back();
              },
            },
            { text: "Cancel" },
            {
              text: "Discard Changes",
              role: "destructive",
              handler: () => {
                propertyStore.revertProperty();
                router.back();
              },
            },
          ],
        });
        await alert.present();
      } else {
        router.back();
      }
    };

    const filterExistingOrganisations = (o) => {
      const pOrganisations = propertyContacts.value?.map((i) => i.oid);
      return pOrganisations.includes(o.id);
    };

    const filterExistingContacts = (c) => {
      const pContacts = propertyContacts.value?.map((i) => i.cid);
      return pContacts.includes(c.id);
    };

    const filterContacts = (c) => {
      const pContacts = propertyContacts.value?.map((i) => i.cid);
      return !pContacts.includes(c.id);
    };

    const flatContacts = computed(() => {
      const result = [];
      propertyContacts.value.forEach((type) => {
        type.organisations.forEach((organisation) => {
          organisation.contacts.forEach((contact) => {
            result.push({ typeID: type.id, organisationID: organisation.id, contactID: contact.id });
          });
        });
      });
      return result;
    });

    const showContact = (type, organisation, contact) => {
      router.push({
        name: "PropertyContact",
        params: {
          propertyID: propertyID,
          typeID: type.id,
          organisationID: organisation.id,
          contactID: contact.id,
        },
      });
    };

    const addContacts = (typeID, event) => {
      event.cancelBubble = true;
      selectedTypeID.value = typeID;
      modalVisible.value = true;
    };

    const addContact = (organisationID, contactID) => {
      propertyStore.addContact(selectedTypeID.value, organisationID, contactID);
      markDirty();
    };

    const removeContact = (typeID, organisationID, contactID) => {
      propertyStore.removeContact(typeID, organisationID, contactID);
      markDirty();
    };

    const closeModal = () => {
      console.log("Closing");
      modalVisible.value = false;
    };

    const debug = (c) => {
      console.log(c);
    };
    return {
      property,
      allContactTypes,
      propertyContacts,
      modalVisible,
      flatContacts,
      selectedTypeID,
      debug,
      filterExistingOrganisations,
      filterExistingContacts,
      filterContacts,
      back,
      showContact,
      addContacts,
      addContact,
      removeContact,
      closeModal,
      addCircleOutline,
    };
  },
};
</script>

<style scoped>
.agentPhoto {
  width: 100%;
  aspect-ratio: 1;
  object-fit: contain;
  border-radius: 5px;
}
.tinyIcon {
  width: 40px;
  height: 20px;
  object-fit: contain;
}
</style>
