<template>
  <ion-page>
    <uncoded-header title="Prices" />
    <ion-content>
      <div v-if="errorOccurred" class="flex vertical centered p2">
        <div class="titleFont fg tc" style="max-width: 250px">An error has occurred, please re-submit your query.</div>
        <div>
          <ion-button expand="full" class="bdl" @click="back">Return to seach</ion-button>
        </div>
      </div>

      <div v-else>
        <div class="p2 flex titleFont cl f2">
          <div>{{ params.title }}</div>
          <div class="fr cp flex autowidth nogap regularFont uppercase" @click="back">
            <ion-icon :icon="searchCircleOutline" class="fl" />
            <div class="fs">Search again</div>
          </div>
        </div>

        <!-- Filters  -->
        <div class="filters bgm" v-bind:class="{ collapsed: !showFilters }">
          <div class="flex mb uppercase fs" @click="showFilters = !showFilters">
            <ion-label color="primary" class="ml">Filters</ion-label>
            <div class="flex autowidth">
              <div
                @click="resetFilters($event)"
                class="flex nogap cl"
                :style="{
                  opacity: bearerSize == 'All' && term == 'All' && bandwidth == 'All' ? 0.3 : 1,
                }"
              >
                Reset
                <ion-icon class="cl fg mr2" :icon="refreshCircleOutline" />
              </div>
              <ion-icon class="filterIcon" :icon="showFilters ? chevronUp : chevronDown" />
            </div>
          </div>
          <transition-group name="fade">
            <filterSelection
              v-if="pricing.bandwidths"
              title="Bandwidth"
              v-model="bandwidth"
              :filterValues="pricing.bandwidths"
              v-show="showFilters"
              key="bandwidthFilter"
              type="data"
            />
            <filterSelection
              v-if="pricing.bearerSizes"
              title="Bearer"
              v-model="bearerSize"
              :filterValues="pricing.bearerSizes"
              v-show="showFilters"
              key="bearerFilter"
              type="data"
            />
            <filterSelection
              v-if="pricing.terms"
              title="Term"
              v-model="term"
              :filterValues="pricing.terms"
              v-show="showFilters"
              key="termFilter"
              type="months"
            />
          </transition-group>
        </div>
        <!-- End of Filters  -->

        <div class="tc fs cl o7 mt">Prices last checked on {{ validDate }}</div>

        <div class="grid2 p2 fullwidth">
          <div v-for="carrier in carriers.filter((c) => c.status == 'waiting')" :key="carrier.id" class="card">
            <div class="flex vertical centered">
              <img :src="carrier.logo" class="contain light" style="height: 80px" />
              <ion-spinner name="dots" />
            </div>
          </div>

          <div class="card" v-for="(item, index) in filteredPrices" :key="index">
            <div class="flex centered">
              <img :src="getLogo(item.carrier)" class="carrierLogo mt" />
            </div>

            <div class="tc titleFont fh cl">
              {{ formatBandwidth(item.bandwidth) }}<span class="ft">/{{ formatBandwidth(item.bearer) }} bearer</span>
            </div>

            <div class="tc cl o6">over {{ item.term }} months</div>

            <div class="flex centered fm titleFont">
              <ion-label color="primary">£{{ Math.ceil(item.monthly_cost) }}<span class="ft">/month</span></ion-label>
            </div>
            <div class="flex centered fs cl o6">
              <div v-if="item.install_cost > 0">£{{ Math.ceil(item.install_cost) }} installation</div>
              <div v-else>No installation cost</div>
            </div>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { reactive, ref } from "@vue/reactivity";
import { useRoute, useRouter } from "vue-router";
import UncodedHeader from "@/components/UncodedHeader";
import {
  getFirebaseCollection,
  runFunction,
  createFirebaseDocument,
  updateFirebaseDocument,
  getFirebaseDualQuery,
} from "@/composables/firebase";
import { formatBandwidth, formatDate } from "@/composables/utilities";
import FilterSelection from "@/components/FilterSelection";
import { refreshCircleOutline, chevronUp, chevronDown, searchCircleOutline } from "ionicons/icons";
import { computed, onMounted } from "@vue/runtime-core";
import { useUserStore } from "@/stores/user";
import { storeToRefs } from "pinia";

export default {
  components: { UncodedHeader, FilterSelection },
  setup() {
    const userStore = useUserStore();
    const { profile, user } = storeToRefs(userStore);

    const router = useRouter();
    const carriers = ref([]);
    const prices = ref([]);
    const term = ref("All");
    const bandwidth = ref("All");
    const bearerSize = ref("All");
    const showFilters = ref(false);
    const errorOccurred = ref(false);
    const validDate = ref(formatDate(new Date()));
    const pricing = reactive({
      bandwidths: [],
      terms: [],
      bearerSizes: [],
    });

    let params = useRoute().params;

    if (!params.postcode) errorOccurred.value = true;

    const unique = (array) => {
      return [...new Set(array)];
    };

    const filteredPrices = computed(() => {
      return prices.value.filter((p) => {
        let result = true;
        if (bandwidth.value != "All" && bandwidth.value != p.bandwidth) result = false;
        if (bearerSize.value != "All" && bearerSize.value != p.bearer) result = false;
        if (term.value != "All" && term.value != p.term) result = false;
        return result;
      });
    });

    const addFilterValues = (carrier) => {
      const bandwidths = carrier.prices?.map((p) => p.bandwidth);
      pricing.bandwidths = unique(pricing.bandwidths.concat(bandwidths));

      const terms = carrier.prices?.map((p) => p.term);
      pricing.terms = unique(pricing.terms.concat(terms));

      const bearerSizes = carrier.prices?.map((p) => parseInt(p.bearer));
      pricing.bearerSizes = unique(pricing.bearerSizes.concat(bearerSizes));
    };

    const addLocationToProfile = (id) => {
      if (!profile.value?.recentLocations || !profile.value?.recentLocations?.includes(id)) {
        if (!profile.value?.recentLocations) profile.value.recentLocations = [];
        profile.value.recentLocations.push(id);
        updateFirebaseDocument("profiles", user.value.id, profile.value);
      }
    };

    const getPrices = async () => {
      prices.value = [];

      const existingLocations = await getFirebaseDualQuery("locations", "postcode", "==", params.postcode, "title", "==", params.title);

      let existingLocation;

      if (existingLocations.length > 0) existingLocation = existingLocations[0];
      if (existingLocation) {
        const ageHours = (new Date() - existingLocation.validDate.toDate()) / (1000 * 60 * 60);
        if (ageHours < 24 * 7) {
          validDate.value = formatDate(existingLocation.validDate.toDate());
          carriers.value = await getFirebaseCollection(`locations/${existingLocation.id}/carriers`);
          if (carriers.value && carriers.value.length > 0) {
            for (const carrier of carriers.value) {
              addFilterValues(carrier);
              prices.value = prices.value.concat(carrier.prices);
              prices.value.sort((a, b) => a.monthly_cost - b.monthly_cost);
            }
            addLocationToProfile(existingLocation.id);
            return;
          }
        }
      }

      const locationDoc = await createFirebaseDocument("locations", {
        validDate: new Date(),
        ...params,
      });

      addLocationToProfile(locationDoc.id);

      const carrierCollection = await getFirebaseCollection("carriers");
      carriers.value = carrierCollection.map((c) => {
        return {
          ...c,
          prices: [],
          status: "waiting",
        };
      });

      carriers.value.forEach(async (carrier) => {
        const payload = {
          id: locationDoc.id,
          street_number: params.street_number,
          street: params.street,
          town: params.town,
          postcode: params.postcode,
          latitude: params.latitude,
          longitude: params.longitude,
          carrier: carrier,
        };

        const result = await runFunction("getPricesForLocation", payload);

        if (result?.ok) {
          addFilterValues(result);

          console.log(result);

          carrier.status = "completed";
          carrier.prices = result.prices;
          // await updateFirebaseDocument(
          //   `locations/${locationDoc.id}/carriers`,
          //   carrier.name,
          //   carrier
          // );
        } else {
          carrier.status = "failed";
        }
        prices.value.push(...carrier.prices);
      });
    };

    const getLogo = (provider) => {
      const carrier = carriers.value.find((item) => item?.name == provider);
      return carrier?.logo;
    };

    onMounted(() => {
      if (!errorOccurred.value) getPrices();
    });

    const resetFilters = (e) => {
      e.cancelBubble = true;
      bandwidth.value = "All";
      term.value = "All";
      bearerSize.value = "All";
    };

    const back = () => {
      router.back();
    };

    return {
      // variables
      params,
      carriers,
      filteredPrices,
      term,
      bandwidth,
      bearerSize,
      showFilters,
      pricing,
      errorOccurred,
      validDate,
      // methods
      resetFilters,
      formatBandwidth,
      back,
      getLogo,
      //icons
      refreshCircleOutline,
      chevronUp,
      chevronDown,
      searchCircleOutline,
    };
  },
};
</script>

<style scoped>
ion-checkbox {
  --border-color: rgba(var(--ion-color-primary-rgb), 0.5);
}

ion-list {
  margin-bottom: 100px;
}

.filters {
  width: 100%;
  padding-right: 10px;
  padding-top: 10px;
  border-top: 1px solid rgba(var(--ion-color-light-rgb), 0.6);
  border-bottom: 1px solid rgba(var(--ion-color-light-rgb), 0.6);
  transition: height 0.2s ease-in;
}

.filters.collapsed {
  height: 2.8em;
  transition: height 0.2s ease-in;
}

.filterTitle {
  padding: 0px 5px 5px 10px;
  text-transform: uppercase;
  font-size: 0.8em;
  font-family: "Bold";
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filterIcon {
  font-size: 1.5rem;
  color: var(--ion-color-light);
}

.validDate {
  font-size: 0.7em;
  margin: 5px 0px 10px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.enabled {
  filter: brightness(1);
  transition: none;
  -webkit-transition: -webkit-filter 0.5s ease-in;
}

.disabled {
  filter: brightness(0.7);
  transition: none;
  -webkit-transition: -webkit-filter 0.5s ease-in;
}
.carrierLogo {
  height: 40px;
  filter: brightness(0) invert(1);
}
</style>
