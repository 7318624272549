<template>
  <ion-page>
    <div id="loginContainer">
      <uncoded-header title="Login" />
      <ion-content>
        <div class="flex vertical">
          <div></div>
          <div style="max-width: min(90vw, 500px)" class="fullwidth">
            <ion-item lines="full">
              <ion-label position="floating">Email</ion-label>
              <ion-input v-model="email" type="email"></ion-input>
              <ion-note color="warning">{{ emailFeedback }}</ion-note>
            </ion-item>

            <div class="tc">
              <ion-button expand="full" class="dark bdl mt equal" @click="getMagicLink()" id="login-button" :disabled="!canSubmit"
                >Get Magic Link</ion-button
              >
              <div class="fs cp mt2" @click="loginWithPassword()"><span class="cl">or</span> login with password</div>
            </div>
          </div>

          <div class="flex pb2 safe-bottom" style="max-width: min(90vw, 500px)">
            <ion-button expand="full" class="dark bdl mv" router-direction="root" router-link="/register">
              <ion-label class="fs">Register</ion-label>
            </ion-button>
          </div>
        </div>
      </ion-content>
    </div>
  </ion-page>
</template>

<script>
import { computed, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { runFunction } from "@/composables/firebase";
import { toast, isEmailValid } from "@/composables/utilities";
import UncodedHeader from "@/components/UncodedHeader";
import { isPlatform } from "@ionic/core";
export default {
  components: { UncodedHeader },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const email = ref();
    const emailFeedback = ref();
    const isProduction = process.env.VUE_APP_MODE == "production";

    email.value = route.query.email;

    const canSubmit = computed(() => {
      return isEmailValid(email.value);
    });

    const getMagicLink = () => {
      window.localStorage.setItem("emailForSignIn", email.value);
      runFunction("magicLink", { login_email: email.value });
      toast(`Magic link sent to ${email.value}. Please check your email.`);
      if (!isPlatform("cordova")) router.push({ name: "CloseMe" });
    };

    const loginWithPassword = () => {
      router.push({
        name: "LoginWithPassword",
        params: { email: email.value },
      });
    };

    return {
      email,
      emailFeedback,
      isProduction,
      canSubmit,
      getMagicLink,
      loginWithPassword,
    };
  },
};
</script>

<style scoped>
#loginContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.formItems {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: space-around;
}

#loginContainer ion-item,
#loginContainer ion-button {
  width: 100%;
}

.footerContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: env(safe-area-inset-bottom);
}
.footerContainer ion-button {
  flex: 1 0 1px;
  font-size: 0.7em;
}
</style>
