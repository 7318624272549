<template>
  <ion-page>
    <uncoded-header title="Edit Contact" />

    <nav-header class="cl" @back="back">{{ contact?.firstname }} {{ contact?.lastname }}</nav-header>

    <ion-content :scroll-y="false" v-if="contact">
      <div class="flex vertical">
        <div class="scroll fullwidth">
          <div class="tc">
            <img
              :src="contact.photo ? contact.photo : '/img/placeholders/portrait.png'"
              :alt="`${contact.firstname}  ${contact.lastname}`"
              class="portrait"
            />
            <input type="file" @change="addPhoto" ref="photoSelector" hidden />
            <div class="flex nogap">
              <ion-button @click="triggerAddPhoto" expand="full" class="primary equal"
                >{{ contact.photo ? "Change" : "Add" }} photo</ion-button
              >
              <ion-button @click="deletePhoto" :disabled="!contact.photo" expand="full" class="danger equal">Delete photo</ion-button>
            </div>
          </div>

          <ion-item class="fullwidth">
            <ion-label color="primary" position="stacked">First Name</ion-label>
            <ion-input v-model="contact.firstname" @ionInput="markDirty()" />
          </ion-item>

          <ion-item class="fullwidth">
            <ion-label color="primary" position="stacked">Last Name</ion-label>
            <ion-input v-model="contact.lastname" @ionInput="markDirty()" />
          </ion-item>

          <ion-item class="fullwidth">
            <ion-label color="primary" position="stacked">Role</ion-label>
            <ion-input v-model="contact.role" @ionInput="markDirty()" />
          </ion-item>

          <ion-item class="fullwidth">
            <ion-label color="primary" position="stacked">Email Address</ion-label>
            <ion-input v-model="contact.email" @ionInput="markDirty()" />
          </ion-item>

          <ion-item class="fullwidth">
            <ion-label color="primary" position="stacked">Office Phone</ion-label>
            <ion-input v-model="contact.phone.office" placeholder="Office phone number" @ionInput="markDirty()" />
          </ion-item>

          <ion-item class="fullwidth">
            <ion-label color="primary" position="stacked">Mobile Phone</ion-label>
            <ion-input v-model="contact.phone.mobile" placeholder="Mobile phone number" @ionInput="markDirty()" />
          </ion-item>
        </div>

        <div class="p fullwidth">
          <ion-button color="danger" expand="full" @click="confirmDelete()">Delete Contact</ion-button>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import UncodedHeader from "@/components/UncodedHeader.vue";
import { computed, ref } from "@vue/runtime-core";
import { useRoute, useRouter } from "vue-router";
import { alertController } from "@ionic/vue";
import { usePropertyStore } from "@/stores/property";
import { storeToRefs } from "pinia";

export default {
  components: { UncodedHeader },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const contactTypeID = route.params.contactTypeID;
    const organisationID = route.params.organisationID;
    const contactID = route.params.contactID;
    const propertyStore = usePropertyStore();
    const { allContactTypes } = storeToRefs(propertyStore);
    const dirty = ref(false);
    const photoSelector = ref();

    const contactType = computed(() => allContactTypes.value?.find((ct) => ct.id == contactTypeID));
    const organisation = computed(() => {
      console.log(contactType.value?.organisations?.find((o) => o.id == organisationID));
      return contactType.value?.organisations?.find((o) => o.id == organisationID);
    });

    const contact = computed(() => {
      const data = organisation.value?.contacts?.find((c) => c.id == contactID);
      if (data && !data.phone) data.phone = {};
      console.log("DATA", contactID, organisation.value);
      return data;
    });

    const markDirty = () => {
      dirty.value = true;
    };

    const back = async () => {
      if (dirty.value) {
        const alert = await alertController.create({
          cssClass: "alert",
          header: "Are you sure?",
          message: "You have unsaved changes.",
          buttons: [
            {
              text: "Save & Close",
              handler: () => {
                save();
              },
            },
            { text: "Cancel" },
            {
              text: "Discard Changes",
              role: "destructive",
              handler: () => {
                router.back();
              },
            },
          ],
        });
        await alert.present();
      } else {
        router.back();
      }
    };

    const save = async (pop = true) => {
      propertyStore.saveContact(contactTypeID, organisationID, contactID);
      if (pop) router.back();
    };

    const confirmDelete = async () => {
      const alert = await alertController.create({
        cssClass: "alert",
        header: "Are you sure?",
        message: "Deleting this contact is irreversible.",
        buttons: [
          {
            text: "Delete",
            role: "destructive",
            handler: () => {
              propertyStore.deleteContact(contactTypeID, organisationID, contactID);
            },
          },
          { text: "Cancel" },
        ],
      });
      await alert.present();
    };

    const triggerAddPhoto = () => {
      photoSelector.value.click();
    };

    const addPhoto = async () => {
      const files = event.target.files;
      const file = files[0];
      propertyStore.addContactPhoto(contactTypeID, organisationID, contactID, file);
      if (photoSelector.value) photoSelector.value.value = "";
    };

    const deletePhoto = async () => {
      propertyStore.deleteContactPhoto(contactTypeID, organisationID, contactID);
    };

    return {
      // variables
      dirty,
      contact,
      photoSelector,
      // methods
      triggerAddPhoto,
      addPhoto,
      deletePhoto,
      confirmDelete,
      markDirty,
      back,
      // icons
    };
  },
};
</script>
