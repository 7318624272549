<template>
  <ion-page>
    <ion-content>
      <div class="background noScroll cl boldFont" slot="fixed">
        <div class="flex vertical centered">
          <div class="cl f2 titleFont">Please Upgrade</div>
          <div class="fl tc fullwidth" style="max-width: min(500px, 80vw)">
            A new version of Online Inside is available to download from {{ store.name }}.
          </div>
          <div><img :src="store.logo" @click="openStore" class="storeLogo mt2" /></div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { isPlatform } from "@ionic/vue";

export default {
  setup() {
    const stores = {
      ios: {
        name: "the App Store",
        logo: "/img/appstores/app_store.svg",
        url: process.env.VUE_APP_APPSTORE_URL,
      },
      android: {
        name: "Google Play",
        logo: "/img/appstores/google-play-badge.png",
        url: process.env.VUE_APP_GOOGLEPLAY_URL,
      },
      na: {
        name: "None",
        logo: "/img/placeholders/transparent.png",
      },
    };

    const platform = isPlatform("ios") ? "ios" : isPlatform("android") ? "android" : "na";
    const store = stores[platform];

    const openStore = () => {
      window.open(store.url, "_blank");
    };
    return { openStore, store };
  },
};
</script>

<style scoped>
.storeLogo {
  height: 50px;
  filter: brightness(1) !important;
}
</style>
