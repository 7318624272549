<template>
  <img
    :src="mapURL"
    v-if="mapURL"
    @error="$event.target.src = '/img/placeholders/tranparent.png'"
  />
</template>

<script>
import { onMounted, ref } from "vue";

export default {
  props: ["location", "useLightMode"],
  setup(props) {
    const mapURL = ref();

    onMounted(() => {
      const height = window.screen.height;
      const width = window.screen.width;
      const location = props.location;
      const lat = location?.latitude || 1;
      const long = location?.longitude || 1;
      //const marker = `${process.env.VUE_APP_DOMAIN}/img/marker.png`;
      const marker = `https://postimg.cc/HrJ9jXzx`;

      let url = "https://maps.googleapis.com/maps/api/staticmap";
      url += "?zoom=17";
      url += "&scale=2";
      url += `&size=${width}x${height}`;
      url += `&markers=anchor:bottom%7Cicon:${marker}|${lat},${long}`;
      url += props.useLightMode
        ? "&map_id=545075da7bee79e"
        : "&map_id=d9683fe1b0a309ad";
      url += "&key=" + process.env.VUE_APP_GOOGLE_MAPS_API_KEY;
      mapURL.value = url;
    });

    return {
      mapURL,
    };
  },
};
</script>
