<template>
  <ion-header>
    <ion-toolbar class="pr" :color="isProduction ? 'dark' : 'dark'">
      <ion-buttons slot="start">
        <ion-menu-button color="light"></ion-menu-button>
      </ion-buttons>

      <ion-title>
        <ion-label v-if="title">{{ title }}</ion-label>
        <img :src="headerLogo" style="height: 28px; filter: brightness(0) invert(1)" v-else @click="home" />
      </ion-title>

      <ion-buttons slot="end">
        <ion-icon v-if="showWifi" :icon="wifi" color="light" size="small" @click="connect" />
        <ion-icon
          v-if="buttons?.includes('favourite')"
          :icon="isFavourite ? star : starOutline"
          color="light"
          size="large"
          @click="toggleFavourite"
        />
        <ion-icon v-if="buttons?.includes('delete')" :icon="trashOutline" color="danger" size="large" @click="remove" />
        <ion-icon v-if="buttons?.includes('add')" :icon="addCircleOutline" color="light" size="large" @click="add" />
        <ion-icon
          v-if="buttons?.includes('confirm')"
          :icon="checkmarkCircleOutline"
          color="light"
          size="large"
          :style="{ opacity: isDirty ? 1 : 0.3 }"
          @click="ok"
        />
        <ion-icon v-if="buttons?.includes('done')" :icon="checkmarkCircleOutline" color="light" size="large" @click="done" />
        <ion-icon v-if="buttons?.includes('confirm')" :icon="closeCircleOutline" color="light" size="large" @click="cancel" />
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
</template>

<script>
import { computed } from "@vue/runtime-core";
import {
  wifi,
  starOutline,
  star,
  addCircleOutline,
  checkmarkCircleOutline,
  closeCircleOutline,
  trashOutline,
  homeOutline,
} from "ionicons/icons";
import { Wifi } from "@uncoded-limited/connect";
import { isPlatform } from "@ionic/core";
import { toast } from "@/composables/utilities";
import { useRouter } from "vue-router";
// import { useUserStore} from "@/stores/user";
// import { storeToRefs } from 'pinia';

export default {
  props: ["title", "isFavourite", "buttons", "isDirty", "wifiDetails", "logo"],
  emits: ["favourite", "add", "ok", "cancel", "done", "delete"],
  setup(props, { emit }) {
    // const userStore = useUserStore();
    // const {getUser } = storeToRefs(userStore);
    // const user = getUser;

    const showWifi = computed(() => {
      return (
        isPlatform("cordova") && props.wifiDetails && props.wifiDetails.ssid && props.wifiDetails.password && props.wifiDetails.ssid != ""
      );
    });
    const isProduction = process.env.VUE_APP_MODE == "production";
    const router = useRouter();
    const connect = async () => {
      toast(`Attempting connection to ${props.wifiDetails.ssid}`);
      const result = await Wifi.connect(props.wifiDetails.ssid, props.wifiDetails.password);
      toast(result);
    };

    const headerLogo = computed(() => {
      return props.logo || "/img/logos/logo_text_white.png";
    });

    const toggleFavourite = () => {
      emit("favourite");
    };

    const add = () => {
      emit("add");
    };

    const ok = () => {
      emit("ok");
    };
    const cancel = () => {
      emit("cancel");
    };

    const done = () => {
      emit("done");
    };

    const remove = () => {
      emit("delete");
    };

    const home = () => {
      router.push({ name: "Home" });
    };

    return {
      ok,
      cancel,
      add,
      done,
      remove,
      wifi,
      starOutline,
      star,
      addCircleOutline,
      checkmarkCircleOutline,
      closeCircleOutline,
      trashOutline,
      homeOutline,
      headerLogo,
      home,
      connect,
      toggleFavourite,
      showWifi,
      isProduction,
    };
  },
};
</script>
