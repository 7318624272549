<template>
  <ion-page>
    <ion-content scroll-y="false">
      <div class="background"></div>
      <div
        class="absolute fullheight fullwidth noScroll flex vertical p2 safe-bottom"
      >
        <img src="/img/logos/logo text white.png" style="height: 80px" />
        <img
          src="/img/iphone.png"
          alt="Online Inside on iPhone"
          class="cover grow mt2"
          style="height: 100px"
        />

        <div class="mt2">
          <a href="https://apps.apple.com/app/online-inside/id1587939743">
            <img
              v-if="isPlatform('ios')"
              src="/img/appstores/app_store.svg"
              alt="Download on the App Store"
              style="height: 50px"
          /></a>
          <a href="https://play.google.com/store/apps/details?id=com.uncoded.oi"
            ><img
              v-if="isPlatform('android')"
              src="/img/appstores/google-play-badge.png"
              alt="Get in on Google Play"
              style="height: 50px"
          /></a>
        </div>
        <div class="fs mt2 tc">
          By downloading the Online Inside app you'll unlock access to live
          pricing, saved shortlists, public wifi access and more.
        </div>
        <div @click="useWeb()" class="cp fs mt2">
          No thanks, continue to website.
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { isPlatform } from "@ionic/vue";
import { useRouter, useRoute } from "vue-router";

export default {
  setup() {
    const router = useRouter();
    const route = useRoute();

    const useWeb = () => {
      switch (route.name) {
        case "DownloadShortlist":
          router.push({ name: "SharedShortlist", params: route.params });
          break;
        case "DownloadLandlord":
          router.push({ name: "Landlord", params: route.params });
          break;
        case "DownloadProperty":
          router.push({ name: "Property", params: route.params });
          break;
        case "DownloadSpace":
          router.push({ name: "Space", params: route.params });
          break;
        default:
          router.push({ name: "Home" });
      }
    };

    return { useWeb, isPlatform };
  },
};
</script>

<style scoped>
.headerLogo {
  height: 40px;
  margin-top: 3px;
  margin-left: 20px;
}
</style>
