<template>
  <ion-page>
    <uncoded-header title="Contact Types" />
    <ion-content>
      <div class="flex vertical">
        <div class="grid2 p2 fullwidth">
          <div
            class="card"
            v-for="contactType in allContactTypes"
            :key="contactType.id"
            @click="showContactType(contactType.id)"
          >
            <div class="absolute cp">
              <ion-icon
                :icon="pencilOutline"
                @click="editContactType(contactType.id, $event)"
              />
            </div>
            <div class="tc m2">
              <ion-icon :icon="peopleOutline" class="f6" />
            </div>
            <div class="title">{{ contactType.plural }}</div>
          </div>
        </div>

        <div class="fullwidth ph2">
          <ion-button
            @click="addContactType"
            expand="full"
            class="fullwidth medium primary mb2"
            >Add contact type</ion-button
          >
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import UncodedHeader from "@/components/UncodedHeader.vue";
import { usePropertyStore } from "@/stores/property";
import { personOutline, peopleOutline, pencilOutline } from "ionicons/icons";
import { useRouter } from "vue-router";
import { storeToRefs } from "pinia";

export default {
  components: { UncodedHeader },
  setup() {
    const router = useRouter();
    const propertyStore = usePropertyStore();
    const { allContactTypes } = storeToRefs(propertyStore);

    const editContactType = (contactTypeID, event) => {
      if (event) event.cancelBubble = true;
      console.log(contactTypeID);
      router.push({
        name: "EditContactTypeDetails",
        params: { contactTypeID: contactTypeID },
      });
    };

    const showContactType = (contactTypeID) => {
      router.push({
        name: "EditContactType",
        params: { contactTypeID: contactTypeID },
      });
    };

    const addContactType = async () => {
      propertyStore.createContactType().then((newDoc) => {
        editContactType(newDoc);
      });
    };

    return {
      // methods
      editContactType,
      addContactType,
      showContactType,
      // variables
      allContactTypes,
      // icons
      personOutline,
      pencilOutline,
      peopleOutline,
    };
  },
};
</script>
