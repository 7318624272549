<template>
  <ion-page>
    <div id="loginContainer">
      <uncoded-header title="Register" />

      <ion-content>
        <div class="flex vertical">
          <div></div>
          <div style="max-width: min(90vw, 500px)" class="fullwidth">
            <ion-item>
              <ion-label position="floating">Email</ion-label>
              <ion-input v-model="email" type="email"></ion-input>
              <ion-note color="warning">{{ emailFeedback }}</ion-note>
            </ion-item>

            <ion-item>
              <ion-label position="floating">Password</ion-label>
              <ion-input
                type="password"
                v-model="password"
                v-on:keyup.enter="register()"
              ></ion-input>
              <ion-note color="warning">{{ passwordFeedback }}</ion-note>
            </ion-item>
            <ion-button
              expand="full"
              class="dark bdl mt"
              @click="register()"
              :disabled="!canSubmit"
              >Register</ion-button
            >
          </div>

          <div class="flex pb2 safe-bottom" style="max-width: min(90vw, 500px)">
            <ion-button
              expand="full"
              class="dark bdl mv"
              router-direction="root"
              router-link="/login"
            >
              <ion-label class="fs">Existing User?</ion-label>
            </ion-button>
            <ion-button
              expand="full"
              class="dark bdl mv"
              router-direction="root"
              router-link="/password-reminder"
            >
              <ion-label class="fs">Forgotten Password</ion-label>
            </ion-button>
          </div>
        </div>
      </ion-content>
    </div>
  </ion-page>
</template>

<script>
import { computed, ref } from "vue";
import { useRouter } from "vue-router";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import UncodedHeader from "@/components/UncodedHeader";
import { runFunction } from "@/composables/firebase";
import { isEmailValid } from "@/composables/utilities";

export default {
  components: { UncodedHeader },
  setup() {
    const auth = getAuth();
    const router = useRouter();
    const email = ref();
    const password = ref();
    const emailFeedback = ref();
    const passwordFeedback = ref();

    const canSubmit = computed(() => {
      return isEmailValid(email.value) && password.value?.length > 7;
    });

    const isProduction = process.env.VUE_APP_MODE == "production";

    // Uncomment following line to hide registration
    // if (isProduction) router.push({ name: "Login" });

    const register = async () => {
      if (password.value?.length < 8) return;
      createUserWithEmailAndPassword(auth, email.value, password.value)
        .then((credentials) => {
          window.localStorage.setItem("emailForSignIn", email.value);
          signInWithEmailAndPassword(auth, email.value, password.value);
          runFunction("validateEmail", { validate_email: email.value });
          router.push({ name: "VerifyEmail" });
        })
        .catch((error) => {
          switch (error.code) {
            case "auth/missing-email":
              emailFeedback.value = "Enter your email address";
              passwordFeedback.value = "";
              break;
            case "auth/invalid-email":
              emailFeedback.value = "Invalid email format";
              passwordFeedback.value = "";
              break;
            case "auth/internal-error":
              emailFeedback.value = "";
              passwordFeedback.value = "Invalid password";
              break;
            case "auth/email-already-in-use":
              emailFeedback.value = "Account already exists";
              passwordFeedback.value = "";
              break;
            default:
              emailFeedback.value = "Error logging in";
              passwordFeedback.value = "";
              break;
          }
        });
    };

    return {
      email,
      password,
      emailFeedback,
      passwordFeedback,
      canSubmit,
      register,
    };
  },
};
</script>

<style scoped>
#loginContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.formItems {
  height: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

#loginContainer ion-item,
#loginContainer ion-button {
  width: 100%;
  max-width: 500px;
}

.footerContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: env(safe-area-inset-bottom);
}
.footerContainer ion-button {
  flex: 1 0 1px;
  font-size: 0.7em;
}
</style>
