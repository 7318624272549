<template>
  <ion-page>
    <uncoded-header title="Edit Property" />

    <ion-content :scroll-y="false">
      <div class="flex vertical nogap left" v-if="property">
        <property-header :property="property" />
        <nav-header @back="back">Bullseye </nav-header>
        <div class="fullwidth p">
          <ion-accordion-group class="fullwidth">
            <ion-accordion>
              <ion-item slot="header" class="uppercase fs boldFont" color="dark"
                >Entry Points
                <ion-icon class="ml cs" :icon="checkmarkDone" v-if="property.entryPointsDescription?.length > 15" />
              </ion-item>
              <div slot="content">
                <ion-item class="fs" lines="none">
                  <ion-label color="primary">Count:</ion-label>
                  <ion-input type="number" v-model="property.entryPoints" @ionInput="markDirty()"></ion-input>
                </ion-item>
                <ion-item lines="none">
                  <ion-label position="stacked" color="primary">Description</ion-label>
                  <div class="fullwidth">
                    <quill-editor
                      theme="bubble"
                      v-model:content="property.entryPointsDescription"
                      contentType="html"
                      class="mv bgm"
                      @textChange="markDirty()"
                    ></quill-editor>
                  </div>
                </ion-item>
              </div>
            </ion-accordion>

            <ion-accordion>
              <ion-item slot="header" class="uppercase fs boldFont" color="dark"
                >Risers <ion-icon class="ml cs" :icon="checkmarkDone" v-if="property.risersDescription?.length > 15"
              /></ion-item>
              <div slot="content">
                <ion-item class="fs" lines="none">
                  <ion-label color="primary">Count:</ion-label>
                  <ion-input type="number" v-model="property.risers" @ionInput="markDirty()"></ion-input>
                </ion-item>

                <ion-item lines="none">
                  <ion-label position="stacked" color="primary">Description</ion-label>
                  <div class="fullwidth">
                    <quill-editor
                      theme="bubble"
                      v-model:content="property.risersDescription"
                      contentType="html"
                      class="mv bgm"
                      @textChange="markDirty()"
                    ></quill-editor>
                  </div>
                </ion-item>
              </div>
            </ion-accordion>

            <ion-accordion>
              <ion-item slot="header" class="uppercase fs boldFont" color="dark"
                >Lead Time <ion-icon class="ml cs" :icon="checkmarkDone" v-if="property.leadTimeDescription?.length > 15"
              /></ion-item>
              <div slot="content">
                <ion-item class="fs" lines="none">
                  <ion-label color="primary">Days:</ion-label>
                  <ion-input type="number" v-model="property.leadTime" @ionInput="markDirty()"></ion-input>
                </ion-item>

                <ion-item lines="none">
                  <ion-label position="stacked" color="primary">Description</ion-label>
                  <div class="fullwidth">
                    <quill-editor
                      theme="bubble"
                      v-model:content="property.leadTimeDescription"
                      contentType="html"
                      class="mv bgm"
                      @textChange="markDirty()"
                    ></quill-editor>
                  </div>
                </ion-item>
              </div>
            </ion-accordion>

            <ion-accordion>
              <ion-item slot="header" class="uppercase fs boldFont" color="dark"
                >Carriers <ion-icon class="ml cs" :icon="checkmarkDone" v-if="property.carriersDescription?.length > 15" />
                <ion-icon :icon="addCircleOutline" class="logo mini" @click="showModal($event)" slot="end" />
              </ion-item>
              <div slot="content">
                <img
                  v-for="carrier in propertyCarriers"
                  :key="carrier.id"
                  :src="carrier.logo"
                  class="logo light mini"
                  @click="removeCarrier(carrier.id)"
                />

                <ion-modal :is-open="modalVisible" presenting-element="rootElement" mode="md" @didDismiss="modalVisible = false">
                  <ion-header>
                    <ion-toolbar>
                      <ion-title>Add Carrier</ion-title>
                      <ion-buttons slot="end">
                        <ion-button @click="modalVisible = false">Done</ion-button>
                      </ion-buttons>
                    </ion-toolbar>
                  </ion-header>

                  <ion-content class="p2 mt2">
                    <img
                      v-for="carrier in allCarriers"
                      :key="carrier.id"
                      :src="carrier.logo"
                      class="logo light mini"
                      @click="addCarrier(carrier.id)"
                      v-show="!isCarrier(carrier.id)"
                    />
                  </ion-content>
                </ion-modal>

                <ion-item lines="none">
                  <ion-label position="stacked" color="primary">Description</ion-label>
                  <div class="fullwidth">
                    <quill-editor
                      theme="bubble"
                      v-model:content="property.carriersDescription"
                      contentType="html"
                      class="mv bgm"
                      @textChange="markDirty()"
                    ></quill-editor>
                  </div>
                </ion-item>
              </div>
            </ion-accordion>
          </ion-accordion-group>
        </div>
      </div>
      <wait v-else>Loading details</wait>
    </ion-content>
  </ion-page>
</template>

<script>
import { nextTick, onMounted, ref } from "@vue/runtime-core";
import { useRoute, useRouter } from "vue-router";
import { chevronForward, checkmarkDone, addCircleOutline } from "ionicons/icons";
import { alertController, onIonViewWillEnter } from "@ionic/vue";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.bubble.css";
import UncodedHeader from "@/components/UncodedHeader";
import PropertyHeader from "@/components/PropertyHeader.vue";
import NavHeader from "@/components/NavHeader.vue";
import { usePropertyStore } from "@/stores/property";
import { storeToRefs } from "pinia";

export default {
  name: "Highlights",
  components: { QuillEditor, NavHeader, PropertyHeader, UncodedHeader },
  setup() {
    const dirty = ref(false);
    const propertyID = useRoute().params.propertyID;
    const propertyStore = usePropertyStore();
    const { property, allCarriers, propertyCarriers } = storeToRefs(propertyStore);
    const router = useRouter();
    const modalVisible = ref(false);
    let loaded = false;
    let rootElement;

    onMounted(() => {
      rootElement = document.querySelector("#main-content");
      nextTick(() => {
        loaded = true;
      });
    });

    onIonViewWillEnter(() => {
      propertyStore.setPropertyID(propertyID);
    });

    const markDirty = () => {
      if (loaded) dirty.value = true;
    };

    const addCarrier = (id) => {
      propertyStore.addCarrier(id);
      markDirty();
    };

    const isCarrier = (id) => {
      return property.value?.carrierIDs?.includes(id);
    };

    const removeCarrier = (id) => {
      markDirty();
      propertyStore.removeCarrier(id);
    };

    const showModal = (event) => {
      event.cancelBubble = true;
      modalVisible.value = true;
    };

    const back = async () => {
      if (dirty.value) {
        const alert = await alertController.create({
          cssClass: "alert",
          header: "Are you sure?",
          message: "You have unsaved changes.",
          buttons: [
            {
              text: "Save & Close",
              handler: () => {
                propertyStore.saveProperty();
                router.back();
              },
            },
            { text: "Cancel" },
            {
              text: "Discard Changes",
              role: "destructive",
              handler: () => {
                propertyStore.revertProperty();
                router.back();
              },
            },
          ],
        });
        await alert.present();
      } else {
        router.back();
      }
    };

    return {
      // variables
      property,
      allCarriers,
      propertyCarriers,
      modalVisible,
      rootElement,
      // methods
      markDirty,
      back,
      removeCarrier,
      showModal,
      addCarrier,
      isCarrier,
      // icons
      chevronForward,
      checkmarkDone,
      addCircleOutline,
    };
  },
};
</script>
<style scoped>
.carrierLogo {
  width: 80px;
}
</style>
