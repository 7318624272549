<template>
  <ion-page>
    <uncoded-header />

    <ion-content scroll-y="false">
      <div class="flex vertical centered p bgd" v-if="contact">
        <property-header />

        <nav-header @back="back">{{ contact?.firstname }} {{ contact?.lastname }}</nav-header>
        <img :src="contact?.url || '/img/placeholders/portrait.png'" class="contactPhoto mb2" />

        <div class="fl cp uppercase boldFont cl">
          {{ organisation?.name }}
        </div>
        <div class="fs uppercase cl o8">{{ contact.role }}</div>
        <div class="mt2">
          <a :href="`mailto:${contact.email}`" target="_new">{{ contact.email }}</a>
        </div>
        <div class="mt2 fullwidth">
          <ion-item lines="full" v-for="(number, title, index) in contact.phone" :key="index">
            <ion-icon slot="start" :icon="callOutline" color="light" />
            <ion-label slot="start" color="light" class="uppercase">{{ title }}</ion-label>
            <a :href="`tel:${number}`" target="_new">{{ number }}</a>
          </ion-item>
        </div>
        <div class="grow"></div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { callOutline } from "ionicons/icons";
import { computed } from "@vue/runtime-core";
import { useRoute, useRouter } from "vue-router";
import UncodedHeader from "@/components/UncodedHeader";
import PropertyHeader from "../common/PropertyHeader";
import { usePropertyStore } from "@/stores/property";
import { storeToRefs } from "pinia";
import { onIonViewWillEnter } from "@ionic/vue";

export default {
  components: { UncodedHeader, PropertyHeader },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const propertyStore = usePropertyStore();
    const { property, allContactTypes } = storeToRefs(propertyStore);

    const propertyID = route.params.propertyID;
    const typeID = route.params.typeID;
    const organisationID = route.params.organisationID;
    const contactID = route.params.contactID;

    const type = computed(() => allContactTypes.value?.find((t) => t.id == typeID));
    const organisation = computed(() => type.value?.organisations?.find((o) => o.id == organisationID));
    const contact = computed(() => organisation.value?.contacts?.find((c) => c.id == contactID));

    onIonViewWillEnter(() => {
      propertyStore.setPropertyID(propertyID);
    });

    const back = () => {
      router.back();
    };

    return {
      back,
      property,
      organisation,
      contact,
      callOutline,
    };
  },
};
</script>

<style scoped>
.contactPhoto {
  width: 50%;
  max-width: 200px;
  aspect-ratio: 1;
  border-radius: var(--default-radius);
  object-fit: cover;
}

a {
  text-decoration: none;
  color: var(--ion-color-primary);
}
</style>
