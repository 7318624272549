<template>
  <ion-page>
    <ion-content>
      <div class="background noScroll cl boldFont">
        <div class="flex vertical centered">
          <div class="cl f8 titleFont">404</div>
          <div class="f2">Page not found</div>
          <div class="fl tc fullwidth" style="max-width: min(500px, 80vw)">
            Sorry, the page you're looking for can't be found. Either it doesn't
            exist or one of the developers fell asleep.
          </div>
          <div>
            <ion-button class="bdl mt2 cl" @click="goBack()"
              >Let's try that again...</ion-button
            >
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { useRouter } from "vue-router";
export default {
  setup() {
    const router = useRouter();
    const goBack = () => {
      router.back();
    };
    return { goBack };
  },
};
</script>

<style></style>
