import { defineStore } from "pinia";
import {
  subscribeDocument,
  getCurrentUser,
  getFirebaseDocument,
  updateFirebaseDocument,
  firebaseLogout,
  subscribeCollection,
} from "../composables/firebase";
import { getFirstPhoto } from "../composables/utilities";
import { getAuth } from "firebase/auth";

let unsubscribeProfile, unsubscribeUser, unsubscribeUsers;

export const useUserStore = defineStore("userStore", {
  state: () => ({
    _firebaseUser: null,
    _user: null,
    _profile: null,
    _shortlistProperties: [],
    _shortlistLocations: [],
    _shortlistSpaces: [],
    _recentProperties: [],
    _recentLocations: [],
    _recentSpaces: [],
    _users: [],
  }),
  getters: {
    users: (state) => {
      return state._users;
    },
    user: (state) => {
      return state._firebaseUser
        ? {
            ...state._user,
            ...state._profile,
            id: state._firebaseUser?.email,
            isAnonymous: state._firebaseUser?.isAnonymous,
            emailVerified: state._firebaseUser?.emailVerified,
          }
        : null;
    },
    profile: (state) => {
      return state._profile;
    },
    shortlist: (state) => {
      return {
        properties: state._shortlistProperties,
        locations: state._shortlistLocations,
        spaces: state._shortlistSpaces,
      };
    },
    recent: (state) => {
      return {
        properties: state._recentProperties,
        locations: state._recentLocations,
        spaces: state._recentSpaces,
      };
    },
  },
  actions: {
    async updateUser() {
      this._firebaseUser = await getCurrentUser();
    },
    async subscribe() {
      return new Promise(async (resolve) => {
        this._firebaseUser = await getCurrentUser();
        const id = this._firebaseUser?.email;
        if (!id) {
          if (unsubscribeProfile) unsubscribeProfile;
          if (unsubscribeUser) unsubscribeUser;
          if (unsubscribeUsers) unsubscribeUsers;
          resolve();
          return;
        }
        unsubscribeUser = subscribeDocument("users", id, (user) => {
          this._user = user;
          if (user.roles.includes("userAdmin"))
            unsubscribeUsers = subscribeCollection("users", (users) => {
              this._users = users;
            });
        });
        unsubscribeProfile = subscribeDocument("profiles", id, async (profile) => {
          this._profile = profile;
          this._shortlistProperties = [];
          if (this._profile?.shortlistProperties) {
            for (const propertyID of this._profile?.shortlistProperties) {
              const property = await getFirebaseDocument("properties", propertyID);
              property.featurePhoto = await getFirstPhoto(property);
              this._shortlistProperties.push(property);
            }
          }

          this._recentProperties = [];
          if (this._profile?.recentProperties) {
            let cleanedUp = false;
            for (const propertyID of this._profile?.recentProperties) {
              const property = await getFirebaseDocument("properties", propertyID);
              if (property) {
                property.featurePhoto = await getFirstPhoto(property);
                this._recentProperties.push(property);
              } else {
                const pidIndex = this._profile.recentProperties.findIndex((p) => p == propertyID);
                this._profile.recentProperties.splice(pidIndex, 1);
                cleanedUp = true;
              }
            }
            if (cleanedUp) {
              console.log("Cleaned profile");
              await updateFirebaseDocument("profiles", this.user.id, this._profile);
            }
          }

          if (this._profile?.recentLocations) {
            for (const locationID of this._profile?.recentLocations) {
              const location = await getFirebaseDocument("locations", locationID);
              this._recentLocations.push(location);
            }
          }

          if (!this._profile) {
            // If no profile exists, create a default
            updateFirebaseDocument("profiles", id, {
              firstname: "",
              lastname: "",
              phone: "",
            });
          }
        });

        resolve();
      });
    },

    async updateProfile(firstname, lastname, phone) {
      await updateFirebaseDocument("profiles", this.user.id, {
        firstname,
        lastname,
        phone,
      });
    },

    async saveUser(id, data) {
      await updateFirebaseDocument("users", id, data);
    },

    async addRecentProperty(id) {
      // Do nothing if property is already in recent properties
      if (this._profile?.recentProperties?.includes(id) || !this._profile?.recentProperties) return;

      // Add property ID to recent properties in profile and save to Firestore
      this._profile.recentProperties.push(id);
      await updateFirebaseDocument("profiles", this.user.id, {
        recentProperties: this._profile.recentProperties,
      });

      // Load property information
      const property = await getFirebaseDocument("properties", id);
      //   property.featurePhoto = await getFirstPhoto(property);
      this._shortlistProperties.push(property);
    },

    async removeRecentProperty(id) {
      const pidIndex = this._profile.recentProperties.findIndex((p) => p == id);
      if (pidIndex != -1) {
        this._profile.recentProperties.splice(pidIndex, 1);
        updateFirebaseDocument("profiles", this.user.id, this._profile);
      }
    },

    async addRecentLocation(id) {},

    async addRecentSpace(propertyID, floorID, spaceID) {},

    async toggleFavourite(id) {
      if (!this.profile.shortlistProperties) this.profile.shortlistProperties = [];
      const favouriteIndex = this._profile.shortlistProperties?.findIndex((pid) => pid == id);

      if (favouriteIndex != -1) {
        this._profile.shortlistProperties.splice(favouriteIndex, 1);
      } else {
        this._profile.shortlistProperties.push(id);
      }
      updateFirebaseDocument("profiles", this._user.id, {
        shortlistProperties: this._profile.shortlistProperties,
      });
    },

    async logout() {
      await firebaseLogout();
      this._user = null;
      this._profile = null;
      await this.subscribe();
    },
  },
});
