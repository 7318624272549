<template>
  <ion-page>
    <uncoded-header />

    <ion-content scroll-y="false">
      <div class="flex left vertical fullwidth">
        <property-header />

        <nav-header @back="back">Connectivity Options</nav-header>
        <!-- Filters  -->
        <div class="filters" v-bind:class="{ collapsed: !showFilters }">
          <div class="flex mb uppercase fs" @click="showFilters = !showFilters">
            <ion-label color="primary" class="ml">Filters</ion-label>
            <div class="flex autowidth">
              <div
                @click="resetFilters($event)"
                class="flex nogap cl"
                :style="{
                  opacity: bearerSize == 'All' && term == 'All' && bandwidth == 'All' ? 0.3 : 1,
                }"
              >
                Reset
                <ion-icon class="cl fg mr2" :icon="refreshCircleOutline" />
              </div>
              <ion-icon class="filterIcon" :icon="showFilters ? chevronUp : chevronDown" />
            </div>
          </div>

          <transition-group name="fade">
            <filterSelection
              v-if="bandwidths"
              title="Bandwidth"
              v-model="bandwidth"
              :filterValues="bandwidths"
              v-show="showFilters"
              key="bandwidthFilter"
              type="data"
              @oiChange="resetTariff"
              class="filter"
            />
            <filterSelection
              v-if="bearerSizes"
              title="Bearer"
              v-model="bearerSize"
              :filterValues="bearerSizes"
              v-show="showFilters"
              key="bearerFilter"
              type="data"
              @oiChange="resetTariff"
              class="filter"
            />
            <filterSelection
              v-if="terms"
              title="Term"
              v-model="term"
              :filterValues="terms"
              v-show="showFilters"
              key="termFilter"
              type="months"
              @oiChange="resetTariff"
              class="filter"
            />
            <ion-item v-show="showFilters" key="expressToggle"
              ><ion-toggle v-model="expressOnly" slot="start" color="primary" /><ion-label>
                <div class="flex left"><ion-icon :icon="flash" class="cp" />Express availability only</div>
              </ion-label>
            </ion-item>
          </transition-group>
        </div>
        <!-- End of Filters  -->

        <div class="fullwidth cl tc fs ph2">Prices last updated {{ priceDate }}</div>

        <div class="grid2 p2 fullwidth">
          <div v-for="(item, index) in filteredPrices" :key="index" @click="enquire(item)" class="card">
            <div class="flex centered">
              <img :src="getLogo(item.supplier)" class="carrierLogo mt" />
            </div>

            <div class="tc titleFont fh cl">
              {{ formatBandwidth(item.bandwidth) }}<span class="fs">/{{ formatBandwidth(item.bearer) }} bearer</span>
            </div>

            <div class="tc cl o6">over {{ item.term }} months</div>

            <div class="flex centered fm titleFont">
              <ion-label color="primary">£{{ Math.ceil(item.monthly_cost) }}<span class="ft">/month</span></ion-label>
            </div>

            <div class="flex centered fs cl o6">
              <div v-if="item.install_cost > 0">£{{ Math.ceil(item.install_cost) }} installation</div>
              <div v-else>No installation cost</div>
            </div>

            <div v-if="isExpress(item.supplier)" class="icon fl">
              <ion-icon :icon="flash" />
            </div>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  chevronBackCircleOutline,
  chevronDown,
  chevronUp,
  informationCircleOutline,
  refreshCircleOutline,
  rocketOutline,
  rocket,
  flash,
  flashOutline,
} from "ionicons/icons";
import { computed, ref } from "@vue/runtime-core";
import FilterSelection from "@/components/FilterSelection";
import { formatBandwidth, toast } from "@/composables/utilities";
import { useRoute, useRouter } from "vue-router";
import PriceModal from "./PriceModal.vue";
import { modalController, onIonViewWillEnter } from "@ionic/vue";
import UncodedHeader from "@/components/UncodedHeader";
import PropertyHeader from "../common/PropertyHeader";
import NavHeader from "@/components/NavHeader.vue";
import { usePropertyStore } from "@/stores/property";
import { storeToRefs } from "pinia";

export default {
  components: {
    FilterSelection,
    NavHeader,
    UncodedHeader,
    PropertyHeader,
  },
  setup() {
    const propertyStore = usePropertyStore();
    const propertyID = useRoute().params.propertyID;
    const { property, prices, bandwidths, bearerSizes, terms, priceDate, allCarriers } = storeToRefs(propertyStore);

    onIonViewWillEnter(async () => {
      await propertyStore.setPropertyID(propertyID);
    });

    const router = useRouter();
    const expressOnly = ref(false);

    const term = ref("All");
    const bandwidth = ref("All");
    const bearerSize = ref("All");

    const showFilters = ref(false);

    const tariff = ref();
    const priceVisible = ref(false);
    const compareVisible = ref(false);

    const checkedPrices = ref([]);

    const filteredPrices = computed(() => {
      let result = prices.value;

      if (expressOnly.value) {
        const carrierAPINames = carriers.value.map((c) => c.apiName);
        result = result.filter((item) => {
          return carrierAPINames.includes(item.supplier);
        });
      }
      if (bandwidth.value != "All") {
        result = result.filter((item) => item.bandwidth == parseInt(bandwidth.value));
      }
      if (term.value != "All") {
        result = result.filter((item) => item.term == parseInt(term.value));
      }
      if (bearerSize.value != "All") {
        result = result.filter((item) => item.bearer == parseInt(bearerSize.value));
      }
      return result;
    });

    const getLogo = (name) => {
      const carrier = allCarriers.value?.find((item) => item?.apiName == name);
      return carrier?.logo;
    };

    const isExpress = (name) => {
      const carrier = allCarriers.value.find((c) => c.name == name);
      return property.value?.carrierIDs?.includes(carrier?.id);
    };

    const isPriceChecked = (price) => {
      const result = checkedPrices.value.filter((e) => e === price).length > 0;
      return result;
    };

    const resetTariff = () => {
      checkedPrices.value = [];
    };

    const resetFilters = (e) => {
      e.cancelBubble = true;
      bandwidth.value = "All";
      term.value = "All";
      bearerSize.value = "All";
    };

    const togglePrice = async (price, event) => {
      if (event.detail.checked) {
        if (checkedPrices.value.length > 4) {
          event.target.checked = false;
          toast("Maximum 5 tariffs selected.");
          return;
        } else {
          checkedPrices.value.push(price);
        }
      } else {
        const index = checkedPrices.value.findIndex((e) => e === price);
        if (index != -1) checkedPrices.value.splice(index, 1);
      }
    };

    const enquire = async (price) => {
      const mainContent = document.querySelector("#main-content");

      const modal = await modalController.create({
        component: PriceModal,
        presentingElement: mainContent,
        canDismiss: true,
        componentProps: {
          property: property.value,
          price: {
            bandwidth: formatBandwidth(price.bandwidth),
            bearer: formatBandwidth(price.bearer),
            term: price.term,
          },
        },
      });
      return modal.present();
    };

    const back = () => {
      router.back();
    };

    return {
      back,
      property,
      prices,
      bearerSizes,
      bandwidths,
      terms,
      priceDate,
      bandwidth,
      term,
      bearerSize,
      filteredPrices,
      tariff,
      showFilters,
      priceVisible,
      compareVisible,
      checkedPrices,
      resetTariff,
      enquire,
      resetFilters,
      getLogo,
      isPriceChecked,
      isExpress,
      expressOnly,
      formatBandwidth,
      togglePrice,
      chevronBackCircleOutline,
      chevronDown,
      chevronUp,
      informationCircleOutline,
      refreshCircleOutline,
      rocketOutline,
      rocket,
      flash,
      flashOutline,
    };
  },
};
</script>
<style scoped>
ion-checkbox {
  --border-color: rgba(var(--ion-color-primary-rgb), 0.5);
}

ion-list {
  margin-bottom: 100px;
}

.filters {
  width: 100%;
  padding-right: 10px;
  padding-top: 10px;
  border-top: 1px solid rgba(var(--ion-color-light-rgb), 0.6);
  border-bottom: 1px solid rgba(var(--ion-color-light-rgb), 0.6);
  transition: height 0.2s ease-in;
}

.filters.collapsed {
  height: 2.8em;
  transition: height 0.2s ease-in;
}

.filterTitle {
  padding: 0px 5px 5px 10px;
  text-transform: uppercase;
  font-size: 0.8em;
  font-family: "Bold";
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filterIcon {
  font-size: 1.5rem;
  color: var(--ion-color-light);
}

.validDate {
  font-size: 0.7em;
  margin: 5px 0px 10px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.enabled {
  filter: brightness(1);
  transition: none;
  -webkit-transition: -webkit-filter 0.5s ease-in;
}

.disabled {
  filter: brightness(0.7);
  transition: none;
  -webkit-transition: -webkit-filter 0.5s ease-in;
}
.carrierLogo {
  height: 40px;
  filter: brightness(0) invert(1);
}
</style>
