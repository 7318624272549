<template>
  <ion-page>
    <uncoded-header title="Edit Property" />

    <ion-content :scroll-y="false" v-if="property">
      <div class="flex vertical nogap left">
        <property-header :property="property" />
        <nav-header @back="back">Location</nav-header>
        <div class="fullwidth p">
          <ion-item>
            <ion-label position="stacked" color="secondary">Address</ion-label>
            <ion-input placeholder="Address Line 1" v-model="property.address1" @ionInput="markDirty()"></ion-input>
            <ion-input placeholder="Address Line 2" v-model="property.address2" @ionInput="markDirty()"></ion-input>
            <ion-input placeholder="Address Line 3" v-model="property.address3" @ionInput="markDirty()"></ion-input>
            <ion-input placeholder="Town/City" v-model="property.town" @ionInput="markDirty()"></ion-input>
            <ion-input placeholder="County" v-model="property.county" @ionInput="markDirty()"></ion-input>
            <ion-input placeholder="Postcode" v-model="property.postcode" @ionInput="markDirty()"></ion-input>
          </ion-item>

          <ion-item>
            <ion-label position="stacked" color="secondary">Coordinates (Latitude / Longitude)</ion-label>
            <div class="flex left">
              <div>
                <ion-input class="equal" placeholder="Latitude" v-model="property.location.latitude" @ionInput="markDirty()"> </ion-input>
              </div>
              <div>
                <ion-input class="equal" placeholder="Longitude" v-model="property.location.longitude" @ionInput="markDirty()"></ion-input>
              </div>
            </div>
          </ion-item>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { onMounted, ref } from "@vue/runtime-core";
import { useRoute, useRouter } from "vue-router";
import { chevronForward } from "ionicons/icons";
import { alertController, onIonViewWillEnter } from "@ionic/vue";
import UncodedHeader from "@/components/UncodedHeader";
import PropertyHeader from "@/components/PropertyHeader.vue";
import NavHeader from "@/components/NavHeader.vue";
import { usePropertyStore } from "@/stores/property";
import { storeToRefs } from "pinia";

export default {
  name: "Location",
  components: { NavHeader, PropertyHeader, UncodedHeader },
  setup() {
    const dirty = ref(false);
    const propertyID = useRoute().params.propertyID;
    const propertyStore = usePropertyStore();
    const { property } = storeToRefs(propertyStore);
    const router = useRouter();

    onIonViewWillEnter(() => {
      propertyStore.setPropertyID(propertyID);
    });

    const save = async () => {
      propertyStore.saveProperty();
      router.back();
    };

    const markDirty = () => {
      dirty.value = true;
    };

    const back = async () => {
      if (dirty.value) {
        const alert = await alertController.create({
          cssClass: "alert",
          header: "Are you sure?",
          message: "You have unsaved changes.",
          buttons: [
            {
              text: "Save & Close",
              handler: () => {
                save();
              },
            },
            { text: "Cancel" },
            {
              text: "Discard Changes",
              role: "destructive",
              handler: () => {
                router.back();
              },
            },
          ],
        });
        await alert.present();
      } else {
        router.back();
      }
    };

    return {
      // variables
      property,
      // methods
      markDirty,
      back,
      // icons
      chevronForward,
    };
  },
};
</script>
