<template>
  <ion-page>
    <uncoded-header title="Edit Property" />

    <ion-content :scroll-y="false" v-if="property">
      <div class="flex vertical nogap left">
        <property-header :property="property" />
        <nav-header @back="back">QR Code</nav-header>
        <div class="fullwidth p">
          <div v-html="qr?.display" class="fullwidth fullheight contain tc p2" @click="downloadQR" />
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { onMounted, ref } from "vue";
import { getFirebaseDocument } from "@/composables/firebase";
import { useRoute, useRouter } from "vue-router";
import { getQR } from "@/composables/qr";
import UncodedHeader from "@/components/UncodedHeader";
import PropertyHeader from "@/components/PropertyHeader.vue";
import NavHeader from "@/components/NavHeader.vue";

export default {
  name: "QR",
  components: { NavHeader, UncodedHeader, PropertyHeader },
  setup() {
    const propertyID = useRoute().params.propertyID;
    const property = ref();
    const downloadLink = ref("");
    const isProduction = process.env.VUE_APP_MODE == "production";
    const qr = ref();
    const router = useRouter();

    const downloadQR = () => {
      const link = document.createElement("a");
      link.href = downloadLink.value;
      link.download = `${property.value?.title}.svg`;
      link.click();
    };

    onMounted(async () => {
      property.value = await getFirebaseDocument("properties", propertyID);
      qr.value = getQR(`${process.env.VUE_APP_DOMAIN}/qr/${property.value?.shortcode}`);

      downloadLink.value =
        "data:image/svg+xml;charset=utf-8," + encodeURIComponent(isProduction ? qr.value.download : qr.value.downloadDev);
    });

    const back = () => {
      router.back();
    };

    return {
      // variables
      downloadLink,
      property,
      qr,
      // methods
      downloadQR,
      back,
    };
  },
};
</script>

<style scoped>
.test svg {
  width: 50px;
}
</style>
