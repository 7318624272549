<template>
  <div id="filterContainer">
    <div class="title">{{ title }}</div>
    <div class="moreButton">{{ moreVisible ? "&lt;" : "" }}</div>
    <div id="itemContainer" ref="itemContainer">
      <div ref="itemChildren">
        <div
          class="item"
          :class="{ selected: modelValue == 'All' }"
          @click="selectItem('All')"
        >
          All
        </div>
        <div
          class="item"
          v-for="(item, index) in filterValues"
          :key="index"
          :class="{ selected: modelValue == item }"
          @click="selectItem(item)"
        >
          {{ format(item) }}
        </div>
      </div>
    </div>
    <div class="moreButton">{{ moreVisible ? "&gt;" : "" }}</div>
  </div>
</template>

<script>
import { formatBandwidth } from "@/composables/utilities";
import { ref, defineComponent, nextTick, onMounted } from "vue";

export default defineComponent({
  props: ["filterValues", "modelValue", "title", "type"],
  emits: ["update:modelValue", "oiChange"],
  setup(props, { emit }) {
    const itemContainer = ref();
    const itemChildren = ref();
    const moreVisible = ref(false);

    const selectItem = (item) => {
      emit("update:modelValue", item);
      emit("oiChange");
    };

    onMounted(() => {
      new ResizeObserver(() => {
        nextTick(() => {
          const container = itemContainer.value?.offsetWidth;
          const children = itemChildren.value?.offsetWidth;
          moreVisible.value = container < children;
        });
      }).observe(itemContainer.value);
    });

    const format = (item) => {
      switch (props.type) {
        case "data":
          return formatBandwidth(item);
        case "months":
          return `${item} months`;
        default:
          return item;
      }
    };

    return {
      formatBandwidth,
      selectItem,
      format,
      moreVisible,
      itemContainer,
      itemChildren,
    };
  },
});
</script>

<style scoped>
#filterContainer {
  display: flex;
  width: 100%;
  font-size: 1.1em;
}
.title {
  display: flex;
  align-items: center;
  justify-content: right;
  width: 6em;
  margin: 0px 10px;
  flex: 0 0 auto;
  color: var(--ion-color-light);
  font-size: 0.8em;
}
#itemContainer {
  flex: 2 1 auto;
  white-space: nowrap;
  overflow-x: auto;
  display: flex;
  justify-content: left;
  align-items: center;
  margin: 0px 5px;
}
#itemContainer::-webkit-scrollbar {
  display: none;
}
.item {
  display: inline-block;
  padding: 5px;
  color: var(--ion-color-light);
}
.item.selected {
  color: var(--ion-color-primary);
}
.moreButton {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
  width: 15px;
}
</style>
