<template>
  <ion-page>
    <uncoded-header title="Edit Property" />
    <ion-content :scroll-y="false" v-if="property">
      <property-header :property="property" />
      <nav-header @back="back">Links</nav-header>

      <wait v-if="waitMessage">{{ waitMessage }}</wait>
      <div class="flex vertical p autoheight" v-if="property">
        <div class="flex vertical nogap left">
          <div class="fullwidth p">
            <ion-list>
              <ion-item>
                <div class="fullwidth grow">
                  <ion-input v-model="url" placeholder="Enter URL" />
                  <ion-input v-model="description" placeholder="Enter description" />
                </div>
                <ion-button color="secondary" slot="end" @click="addRecord()">Add</ion-button>
              </ion-item>
              <ion-item v-for="(item, i) in property.links" :key="i">
                <div class="flex fullwidth">
                  <div class="grow">
                    <ion-input v-model="item.url" @ionChange="item.dirty = true" />
                    <ion-input v-model="item.description" @ionChange="item.dirty = true" />
                  </div>
                  <div class="flex autowidth">
                    <ion-icon :icon="bookmarkOutline" :class="{ o3: !item.featured }" color="secondary" @click="setFeatured(item)" />
                    <ion-icon :icon="lockClosedOutline" :class="{ o3: !item.private }" color="secondary" @click="setPrivate(item)" />
                  </div>
                </div>

                <ion-icon slot="end" :icon="trashOutline" color="danger" @click="deleteRecord(item)" />
              </ion-item>
            </ion-list>
          </div>
        </div>
      </div>
      <wait v-else>Loading links</wait>
    </ion-content>
  </ion-page>
</template>

<script>
import { onMounted, ref } from "@vue/runtime-core";
import { useRoute, useRouter } from "vue-router";
import { chevronForward, trashOutline, linkOutline, returnDownBack, bookmark, bookmarkOutline, lockClosedOutline } from "ionicons/icons";
import { alertController } from "@ionic/vue";
import { getFirebaseDocument, updateFirebaseDocument } from "@/composables/firebase";
import UncodedHeader from "@/components/UncodedHeader";
import PropertyHeader from "@/components/PropertyHeader.vue";
import NavHeader from "@/components/NavHeader.vue";

export default {
  name: "Documents",
  components: { NavHeader, PropertyHeader, UncodedHeader },

  setup() {
    const dirty = ref(false);
    const propertyID = useRoute().params.propertyID;
    const property = ref();
    const url = ref();
    const description = ref();
    const waitMessage = ref();
    const router = useRouter();
    let rootElement;

    const refreshData = async () => {
      property.value = await getFirebaseDocument("properties", propertyID);
    };

    onMounted(() => {
      refreshData();
      rootElement = document.querySelector("#main-content");
    });

    const setFeatured = (item) => {
      property.value.links.forEach((link) => {
        link.featured = link == item;
      });
      markDirty();
    };

    const setPrivate = (item) => {
      item.private = !!item.private;
      item.private = !item.private;
      markDirty();
    };

    const addRecord = () => {
      if (!property.value.links) property.value.links = [];
      property.value.links.push({ url: url.value, description: description.value });
      url.value = null;
      description.value = null;
      markDirty();
    };

    const deleteRecord = (item) => {
      property.value.links = property.value.links.filter((i) => i != item);
    };

    const save = async (pop = true) => {
      await updateFirebaseDocument("properties", propertyID, property.value);
      if (pop) router.back();
    };

    const markDirty = () => {
      dirty.value = true;
    };

    const back = async () => {
      if (dirty.value) {
        const alert = await alertController.create({
          cssClass: "alert",
          header: "Are you sure?",
          message: "You have unsaved changes.",
          buttons: [
            {
              text: "Save & Close",
              handler: () => {
                save();
              },
            },
            { text: "Cancel" },
            {
              text: "Discard Changes",
              role: "destructive",
              handler: () => {
                router.back();
              },
            },
          ],
        });
        await alert.present();
      } else {
        router.back();
      }
    };

    return {
      // variables
      property,
      rootElement,
      waitMessage,
      url,
      description,
      // methods
      markDirty,
      back,
      addRecord,
      deleteRecord,
      setFeatured,
      setPrivate,
      // icons
      chevronForward,
      trashOutline,
      linkOutline,
      returnDownBack,
      bookmark,
      bookmarkOutline,
      lockClosedOutline,
    };
  },
};
</script>
