<template>
  <ion-page>
    <ion-content class="ion-padding">
      <div class="flex vertical centered p2">
        <div v-if="validCode" class="fullwidth ph2" style="max-width: min(90vw, 500px)">
          <ion-input v-model="newPassword" placeholder="Enter new password" type="password" class="fullwidth bdl mb" />
          <ion-input v-model="verifyPassword" placeholder="Re-enter new password" type="password" class="fullwidth bdl" />
          <div class="ft cw pv" style="height: 60px">&nbsp;{{ errorMessage }}</div>
          <div class="flex">
            <ion-button expand="full" color="light" @click="resetPassword()" :disabled="!!errorMessage" class="bdl mt2 equal"
              >Change</ion-button
            >
            <ion-button expand="full" color="dark" @click="goBack()" class="bdl mt2 equal">Cancel</ion-button>
          </div>
        </div>

        <div v-else class="flex vertical centered">
          <div class="p2 tc">This password reset link has expired. Please request a new link.</div>
          <div class="tc p2">
            <ion-button class="bdl" @click="login()">Return to Login</ion-button>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { verifyPasswordResetCode, confirmPasswordReset, signInWithEmailAndPassword, getAuth } from "firebase/auth";
import { ref } from "@vue/reactivity";
import { computed, onMounted } from "@vue/runtime-core";
import { useRoute, useRouter } from "vue-router";

export default {
  setup() {
    const validCode = ref(true);
    const newPassword = ref();
    const verifyPassword = ref();
    let email, actionCode;
    const route = useRoute();
    const router = useRouter();
    //const updateUser = inject("updateUser");
    //const authUser = inject("authUser");
    const auth = getAuth();

    const errorMessage = computed(() => {
      if (newPassword.value?.length < 8)
        return "Password must be at least 8 characters including uppercase, lowercase, a number and a special character.";
      if (newPassword.value != verifyPassword.value) return "Passwords must match";
      return null;
    });

    const checkLink = async () => {
      const link = route.params.link;
      actionCode = new URL(link).searchParams.get("oobCode");
      email = await verifyPasswordResetCode(auth, actionCode);
      if (!email) validCode.value = false;
    };

    const resetPassword = async () => {
      try {
        const resp = await confirmPasswordReset(auth, actionCode, newPassword.value);

        await signInWithEmailAndPassword(auth, email, newPassword.value);
        router.push({ name: "Home" });
        await authUser.value?.reload();
        console.log("reloaded");
        if (authUser.value?.emailVerified) updateUser();
        console.log("updated");
      } catch (error) {
        console.log("Error resetting password", error);
      }
    };

    onMounted(() => {
      checkLink();
    });

    const login = () => {
      router.push({ name: "Login" });
    };

    const goBack = () => {
      router.push({ name: "Login" });
    };

    return {
      validCode,
      newPassword,
      verifyPassword,
      errorMessage,
      login,
      goBack,
      resetPassword,
    };
  },
};
</script>

<style></style>
