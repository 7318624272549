<template>
  <ion-page>
    <uncoded-header />
    <ion-content scroll-y="false" v-if="property">
      <div class="flex vertical fullwidth bgd">
        <div class="flex left cl uppercase titleFont fm pt2 pl2">
          <div>{{ property?.title }}</div>
          <ion-icon :icon="shieldCheckmarkOutline" class="ft superscript cp" v-if="property?.verified" />
        </div>

        <nav-header @back="back">Property Details</nav-header>

        <GMapMap :center="mapData.center" :zoom="15" :options="mapData.options" class="map" :key="property.id">
          <GMapMarker :position="markerData.position" :icon="markerData.icon" @click="markerClicked">
            <GMapInfoWindow :opened="showMarkerDetails" ref="test" class="test">
              <div class="uppercase cd titleFont">
                {{ property?.title }}
              </div>
            </GMapInfoWindow>
          </GMapMarker>
        </GMapMap>

        <div class="fullwidth ph cl">
          <div @click="showDirections">
            <div class="ph tc uppercase" v-html="getAddress(property, false)" />
            <div class="flex ph uppercase fs cp centered">
              <ion-icon :icon="mapOutline" />
              <ion-label class="boldFont"> Get directions</ion-label>
            </div>
          </div>

          <div class="fullwidth p safe-bottom">
            <div class="action" @click="showDocuments">
              <ion-label>Documents</ion-label>
              <ion-icon :icon="chevronForward" />
            </div>
            <div class="action" @click="showLinks">
              <ion-label>Links</ion-label>
              <ion-icon :icon="chevronForward" />
            </div>
            <div class="action" @click="showAccreditations">
              <ion-label>Accreditations</ion-label>
              <ion-icon :icon="chevronForward" />
            </div>
            <div class="action" @click="showGallery">
              <ion-label>Gallery</ion-label>
              <ion-icon :icon="chevronForward" />
            </div>
            <div class="action" @click="showContacts">
              <ion-label>Contacts</ion-label>
              <ion-icon :icon="chevronForward" />
            </div>
            <div class="action" @click="showSpaces">
              <ion-label>Spaces</ion-label>
              <ion-icon :icon="chevronForward" />
            </div>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { chevronBackCircleOutline, chevronForward, mapOutline, shieldCheckmarkOutline } from "ionicons/icons";
import { getAddress } from "@/composables/utilities";
import { useRoute, useRouter } from "vue-router";
import UncodedHeader from "@/components/UncodedHeader";
import { usePropertyStore } from "@/stores/property";
import { storeToRefs } from "pinia";
import { onIonViewWillEnter } from "@ionic/vue";
import { computed, ref } from "vue-demi";

export default {
  components: { UncodedHeader },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const propertyID = route.params.propertyID;
    const googleMapID = process.env.VUE_APP_GOOGLE_MAPS_ID;
    const propertyStore = usePropertyStore();
    const { property } = storeToRefs(propertyStore);
    propertyStore.setPropertyID(propertyID);
    const showMarkerDetails = ref(false);

    const mapData = computed(() => {
      return {
        center: {
          lat: parseFloat(property.value?.location?.latitude || 0),
          lng: parseFloat(property.value?.location?.longitude || 0),
        },
        options: {
          mapId: googleMapID,
          disableDefaultUI: true,
          keyboardShortcuts: false,
        },
      };
    });

    const markerData = computed(() => {
      return {
        position: {
          lat: parseFloat(property.value?.location?.latitude || 0),
          lng: parseFloat(property.value?.location?.longitude || 0),
        },
        icon: "/img/marker.svg",
      };
    });

    const markerClicked = () => {
      showMarkerDetails.value = !showMarkerDetails.value;
    };

    onIonViewWillEnter(async () => {
      await propertyStore.setPropertyID(propertyID);
    });

    const back = () => {
      router.back();
    };

    const showDirections = () => {
      const url = `https://www.google.com/maps/dir/?api=1&destination=${property.value?.location?.latitude},${property.value?.location?.longitude}`;
      window.open(url, "_new");
    };

    const showAccreditations = () => {
      router.push({
        name: "PropertyAccreditations",
        params: { propertyID: propertyID },
      });
    };

    const showContacts = () => {
      router.push({
        name: "PropertyContacts",
        params: { propertyID: propertyID },
      });
    };

    const showGallery = () => {
      router.push({
        name: "PropertyGallery",
        params: { propertyID: propertyID },
      });
    };

    const showSpaces = () => {
      router.push({
        name: "PropertySpaces",
        params: { propertyID: propertyID },
      });
    };

    const showDocuments = () => {
      router.push({
        name: "PropertyDocuments",
        params: { propertyID: propertyID },
      });
    };

    const showLinks = () => {
      router.push({
        name: "PropertyLinks",
        params: { propertyID: propertyID },
      });
    };

    return {
      back,
      property,
      mapData,
      markerData,
      markerClicked,
      showMarkerDetails,
      chevronBackCircleOutline,
      shieldCheckmarkOutline,
      chevronForward,
      mapOutline,
      getAddress,
      showDirections,
      showAccreditations,
      showSpaces,
      showContacts,
      showGallery,
      showDocuments,
      showLinks,
    };
  },
};
</script>
<style scoped>
.map {
  flex: 1 0 10%;
  width: 100%;
  overflow: hidden;
}
.map > img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
</style>
