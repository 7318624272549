<template>
  <ion-page>
    <uncoded-header title="Properties" />
    <ion-content>
      <div class="flex vertical">
        <div class="grid2 p">
          <div class="card" v-for="(property, index) in allProperties" :key="index" @click="editProperty(property.id)">
            <img :src="property?.featurePhoto" class="photo" :class="{ o5: !property?.isLoaded }" @load="property.imageLoaded = true" />
            <div class="title">{{ property?.title }}</div>
            <div class="subtitle">{{ property?.town }}{{ property?.town && property?.postcode ? ", " : "" }}{{ property?.postcode }}</div>
          </div>
        </div>
        <div class="fullwidth ph2">
          <ion-button @click="addProperty" class="fullwidth medium primary mb2">Add property</ion-button>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import UncodedHeader from "@/components/UncodedHeader.vue";
import { useRouter } from "vue-router";
import { usePropertyStore } from "@/stores/property";
import { storeToRefs } from "pinia";
import { onIonViewWillEnter } from "@ionic/vue";

export default {
  components: { UncodedHeader },
  setup() {
    const router = useRouter();
    const propertyStore = usePropertyStore();
    const { allProperties } = storeToRefs(propertyStore);

    onIonViewWillEnter(() => {
      propertyStore.fetchPropertySkeletons();
    });

    const editProperty = (propertyID) => {
      console.log(propertyID);
      router.push({
        name: "EditProperty",
        params: { propertyID: propertyID },
      });
    };

    const addProperty = async () => {
      propertyStore.createProperty().then((newDocID) => {
        editProperty(newDocID);
      });
    };

    return {
      // methods
      addProperty,
      editProperty,
      // variables
      allProperties,
      // icons
    };
  },
};
</script>
