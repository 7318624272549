<template>
  <ion-page>
    <div id="loginContainer">
      <uncoded-header title="Login" />
      <ion-content>
        <div class="flex vertical">
          <div></div>
          <div style="max-width: min(90vw, 500px)" class="fullwidth">
            <ion-item lines="full">
              <ion-label position="floating">Email</ion-label>
              <ion-input v-model="email" type="email"></ion-input>
              <ion-note color="warning">{{ emailFeedback }}</ion-note>
            </ion-item>

            <ion-item lines="full">
              <ion-label position="floating">Password</ion-label>
              <ion-input
                type="password"
                v-model="password"
                v-on:keyup.enter="login()"
              ></ion-input>
              <ion-note color="warning">{{ passwordFeedback }}</ion-note>
            </ion-item>
            <div class="tc">
              <ion-button
                expand="full"
                class="dark bdl mt equal"
                @click="login()"
                id="login-button"
                :disabled="!canSubmit"
                >Login</ion-button
              >
              <div class="fs cp mt2" @click="loginWithMagicLink()">
                <span class="cl">or</span> get magic link
              </div>
            </div>
          </div>

          <div class="flex pb2 safe-bottom" style="max-width: min(90vw, 500px)">
            <ion-button
              expand="full"
              class="dark bdl mv"
              router-direction="root"
              router-link="/register"
            >
              <ion-label class="fs">Register</ion-label>
            </ion-button>
            <ion-button
              expand="full"
              class="dark bdl mv"
              router-direction="root"
              router-link="/password-reminder"
              ><ion-label class="fs">Forgotten Password</ion-label></ion-button
            >
          </div>
        </div>
      </ion-content>
    </div>
  </ion-page>
</template>

<script>
import { isPlatform } from "@ionic/vue";
import { computed, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { Keyboard } from "@capacitor/keyboard";
import UncodedHeader from "@/components/UncodedHeader";
import { isEmailValid } from "@/composables/utilities";
import { useUserStore } from "@/stores/user";

export default {
  components: { UncodedHeader },
  setup() {
    const auth = getAuth();
    const userStore = useUserStore();

    const router = useRouter();
    const route = useRoute();
    const email = ref(route.params?.email);
    const password = ref();
    const emailFeedback = ref();
    const passwordFeedback = ref();
    const isProduction = process.env.VUE_APP_MODE == "production";

    const canSubmit = computed(() => {
      return isEmailValid(email.value) && password.value?.length > 5;
    });

    const login = async () => {
      if (isPlatform("cordova")) Keyboard.hide();
      signInWithEmailAndPassword(auth, email.value, password.value)
        .then(async (credentials) => {
          await userStore.subscribe();

          if (!credentials.user.emailVerified) {
            router.push({ name: "VerifyEmail" });
          } else {
            router.push({ name: "Onboarding" });
          }
        })
        .catch((error) => {
          switch (error.code) {
            case "auth/missing-email":
              emailFeedback.value = "Enter your email address";
              passwordFeedback.value = "";
              break;
            case "auth/invalid-email":
              emailFeedback.value = "Invalid email format";
              passwordFeedback.value = "";
              break;
            case "auth/internal-error":
              emailFeedback.value = "";
              passwordFeedback.value = "Invalid password";
              break;
            case "auth/wrong-password":
              emailFeedback.value = "Incorrect email/password";
              passwordFeedback.value = "";
              break;
            default:
              emailFeedback.value = "Error logging in";
              passwordFeedback.value = "";
              break;
          }
        });
    };

    const loginWithMagicLink = () => {
      router.push({
        name: "Login",
        params: { email: email.value },
      });
    };

    return {
      email,
      password,
      emailFeedback,
      passwordFeedback,
      isProduction,
      canSubmit,
      login,
      loginWithMagicLink,
    };
  },
};
</script>

<style scoped>
#loginContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.formItems {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: space-around;
}

#loginContainer ion-item,
#loginContainer ion-button {
  width: 100%;
}

.footerContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: env(safe-area-inset-bottom);
}
.footerContainer ion-button {
  flex: 1 0 1px;
  font-size: 0.7em;
}
</style>
