<template>
  <ion-page>
    <ion-content class="ion-padding">
      <div class="flex vertical centered">Please wait...</div>
    </ion-content>
  </ion-page>
</template>

<script>
import { computed } from "@vue/runtime-core";
import { useRoute, useRouter } from "vue-router";
import { onIonViewWillEnter } from "@ionic/vue";

export default {
  setup() {
    const router = useRouter();
    const route = useRoute();

    const link = computed(() => {
      const url = route.params.url;
      return url || window.location.href;
    });

    onIonViewWillEnter(() => {
      processLink();
    });

    const processLink = () => {
      if (!link.value) return;
      const mode = new URL(link.value).searchParams.get("mode");
      switch (mode) {
        case "signIn":
          router.push({ name: "MagicLink", params: { link: link.value } });
          break;
        case "resetPassword":
          router.push({ name: "ResetPassword", params: { link: link.value } });
          break;
        case "verifyEmail":
          router.push({ name: "ValidateEmail", params: { link: link.value } });
          break;
        default:
          console.log("No mode detected");
          break;
      }
    };
  },
};
</script>

<style></style>
