<template>
  <ion-page>
    <uncoded-header title="Edit Property" />
    <ion-content :scroll-y="false" v-if="property">
      <property-header :property="property" />
      <nav-header @back="back">Documents</nav-header>

      <wait v-if="waitMessage">{{ waitMessage }}</wait>
      <div class="flex vertical p autoheight" v-if="property">
        <ion-button expand="full" class="fullwidth medium primary" @click="triggerDocumentSelector">Add document</ion-button>
        <div class="fullwidth">
          <ion-item v-for="(document, index) in property.documents" :key="index" class="fullwidth">
            <ion-icon :icon="documentOutline" color="primary" />
            <div class="pl flex">
              <ion-input v-model="document.description" @ionInput="markDirty()" :placeholder="`${document.filename}`"></ion-input>
              <div @click="showDocumentTypes(document)" class="cp fs">
                {{ getDocumentTypeLabel(document.documentTypeID) }}
              </div>
            </div>
            <ion-icon :icon="trashOutline" slot="end" @click="deleteDocument(document)" color="danger" />
          </ion-item>
        </div>

        <input type="file" ref="documentSelector" hidden multiple @change="addDocuments" />
      </div>
      <wait v-else>Loading documents</wait>
    </ion-content>

    <ion-modal :is-open="documentTypesVisible" presenting-element="rootElement" mode="md" @didDismiss="documentTypesVisible = false">
      <ion-header>
        <ion-toolbar>
          <ion-title>Select Document Type</ion-title>
        </ion-toolbar>
      </ion-header>

      <ion-content>
        <ion-list>
          <ion-item v-for="type in documentTypes" :key="type.id" @click="selectDocumentType(type)">
            <ion-label>{{ type.name }}</ion-label>
          </ion-item>
        </ion-list>
      </ion-content>
    </ion-modal>
  </ion-page>
</template>

<script>
import { onMounted, ref } from "@vue/runtime-core";
import { useRoute, useRouter } from "vue-router";
import { chevronForward, trashOutline, documentOutline } from "ionicons/icons";
import { alertController } from "@ionic/vue";
import {
  getFirebaseDocument,
  getFirebaseQuery,
  updateFirebaseDocument,
  uploadFirebaseFile,
  deleteFirebaseFile,
} from "@/composables/firebase";
import UncodedHeader from "@/components/UncodedHeader";
import PropertyHeader from "@/components/PropertyHeader.vue";
import NavHeader from "@/components/NavHeader.vue";

export default {
  name: "Documents",
  components: { NavHeader, PropertyHeader, UncodedHeader },

  setup() {
    const dirty = ref(false);
    const propertyID = useRoute().params.propertyID;
    const property = ref();
    const documentTypes = ref([]);
    const documentSelector = ref();
    const documentTypesVisible = ref(false);
    const currentDocument = ref();
    const waitMessage = ref();
    const router = useRouter();
    let rootElement;

    const refreshData = async () => {
      property.value = await getFirebaseDocument("properties", propertyID);
      documentTypes.value = await getFirebaseQuery("documentTypes", "scope", "array-contains", "building");
    };

    onMounted(() => {
      refreshData();
      rootElement = document.querySelector("#main-content");
    });

    const save = async (pop = true) => {
      await updateFirebaseDocument("properties", propertyID, property.value);
      if (pop) router.back();
    };

    const markDirty = () => {
      dirty.value = true;
    };

    const back = async () => {
      if (dirty.value) {
        const alert = await alertController.create({
          cssClass: "alert",
          header: "Are you sure?",
          message: "You have unsaved changes.",
          buttons: [
            {
              text: "Save & Close",
              handler: () => {
                save();
              },
            },
            { text: "Cancel" },
            {
              text: "Discard Changes",
              role: "destructive",
              handler: () => {
                router.back();
              },
            },
          ],
        });
        await alert.present();
      } else {
        router.back();
      }
    };

    const triggerDocumentSelector = () => {
      documentSelector.value.click();
    };

    const addDocuments = async () => {
      const files = event.target.files;

      for (const [, file] of Object.entries(files)) {
        waitMessage.value = "Uploading document...";
        const result = await uploadFirebaseFile(`Properties/${property.value.title}/documents`, file.name, file);

        const record = {
          filename: result.ref.name,
          path: result.ref.fullPath,
          description: result.ref.name,
          documentTypeID: documentTypes.value[0].id,
        };
        if (!property.value.documents) property.value.documents = [];
        property.value.documents.push(record);
        save(false);
        documentSelector.value.value = "";
        waitMessage.value = null;
      }
    };

    const showDocumentTypes = (document) => {
      currentDocument.value = document;
      try {
        documentTypesVisible.value = true;
      } catch (error) {
        console.log(error);
      }
    };

    const selectDocumentType = (documentType) => {
      currentDocument.value.documentTypeID = documentType.id;
      documentTypesVisible.value = false;
      markDirty();
    };

    const deleteDocument = async (document) => {
      const alert = await alertController.create({
        cssClass: "alert",
        header: "Are you sure?",
        message: "This document and associated information will be deleted immediately.",
        buttons: [
          {
            text: "Delete",
            role: "destructive",
            handler: () => {
              property.value.documents = property.value.documents.filter((item) => item != document);
              deleteFirebaseFile(document.path);
              save(false);
            },
          },
          {
            text: "Cancel",
            role: "cancel",
          },
        ],
      });
      await alert.present();
    };

    const getDocumentTypeLabel = (documentTypeID) => {
      const foundType = documentTypes.value.find((dt) => dt.id == documentTypeID);
      return foundType?.name || "Choose document type";
    };

    return {
      // variables
      property,
      documentSelector,
      documentTypesVisible,
      currentDocument,
      documentTypes,
      rootElement,
      waitMessage,
      // methods
      getDocumentTypeLabel,
      addDocuments,
      showDocumentTypes,
      selectDocumentType,
      deleteDocument,
      triggerDocumentSelector,
      markDirty,
      back,
      // icons
      chevronForward,
      trashOutline,
      documentOutline,
    };
  },
};
</script>
