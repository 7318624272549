<template>
  <ion-page>
    <uncoded-header
      :wifiDetails="{
        ssid: property?.publicWifi,
        password: property?.publicWifiPassword,
      }"
      :logo="property?.logo"
    />

    <ion-content :scroll-y="false" class="transparent">
      <div class="background">
        <img :src="property?.featurePhoto" />
      </div>

      <div class="flex vertical nogap">
        <property-header />

        <div class="grow fullwidth flex centered" style="margin-top: -10%">
          <bullseye :property="property" />
        </div>

        <highlights :highlights="property?.highlights" class="mb2" @highlightClicked="highlightClicked" />
        <div class="fullwidth ph pb">
          <div class="action" @click="toggleFavourite" v-if="!user.isAnonymous">
            <ion-label v-show="!isFavourite" color="primary">Add to shortlisted properties</ion-label>
            <ion-label v-show="isFavourite" color="primary">Remove from shortlisted properties</ion-label>
            <ion-icon color="primary" :icon="isFavourite ? star : starOutline" />
          </div>
          <div class="action" v-if="!property?.hideConnectivity">
            <ion-label class="fullwidth keepOnTop" @click="showPricing">Connectivity Options</ion-label><ion-icon :icon="chevronForward" />
          </div>
          <!-- <div class="action">
            <ion-label class="fullwidth keepOnTop" @click="showPricing">Connectivity Process</ion-label><ion-icon :icon="chevronForward" />
          </div> -->
          <div class="action" v-if="!property?.hideDetails">
            <ion-label class="fullwidth keepOnTop" @click="showDetails">Property Details</ion-label><ion-icon :icon="chevronForward" />
          </div>
          <div class="action" v-if="featuredLink">
            <ion-label class="fullwidth keepOnTop" @click="showFeaturedLink">{{ featuredLink.description }} </ion-label
            ><ion-icon :icon="bookmark" /><ion-icon :icon="chevronForward" />
          </div>
          <div class="action" v-if="property?.typeformID">
            <ion-label class="fullwidth keepOnTop" @click="showFeedback">Give Feedback</ion-label><ion-icon :icon="chevronForward" />
          </div>
        </div>

        <div class="fullwidth nogrow bgd">
          <footer-actions class="safe-bottom" :property="property" :user="userStore.getUser" />
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { usePropertyStore } from "@/stores/property";
import { useUserStore } from "@/stores/user";
import { storeToRefs } from "pinia";
import PropertyHeader from "./property/common/PropertyHeader";
import UncodedHeader from "@/components/UncodedHeader.vue";
import FooterActions from "./property/FooterActions";
import { computed, defineComponent } from "@vue/runtime-core";
import { useRoute, useRouter } from "vue-router";
import { chevronForward, star, starOutline, bookmark } from "ionicons/icons";
import Bullseye from "./property/bullseye/Bullseye";
import Highlights from "./property/common/Highlights";
import { onIonViewWillEnter } from "@ionic/vue";
import mixpanel from "mixpanel-browser";

export default defineComponent({
  components: {
    UncodedHeader,
    PropertyHeader,
    FooterActions,
    Bullseye,
    Highlights,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    let propertyID = route.params.propertyID;
    const propertyStore = usePropertyStore();
    const userStore = useUserStore();
    const { property } = storeToRefs(propertyStore);
    const { user } = storeToRefs(userStore);

    onIonViewWillEnter(async () => {
      await propertyStore.setPropertyID(propertyID);
      mixpanel.track("Property Viewed", { property: property.value?.title });
    });

    const isFavourite = computed(() => {
      return user.value.shortlistProperties?.includes(propertyID);
    });

    const featuredLink = computed(() => {
      const link = property.value?.links?.find((item) => item.featured);
      if (!link) return null;
      return !link.private || user.value?.properties?.includes(propertyID) ? link : null;
    });

    const showPricing = () => {
      router.push({
        name: "PropertyPricing",
        params: { propertyID: propertyID },
      });
    };

    const showDetails = async () => {
      router.push({
        name: "PropertyDetails",
        params: { propertyID: propertyID },
      });
    };

    const showFeaturedLink = (url) => {
      window.open(url, "_new");
    };

    const highlightClicked = (index) => {
      showBullseyeDetail(index);
    };

    const showBullseyeDetail = (section) => {
      router.push({
        name: "PropertyBullseyeDetails",
        params: {
          propertyID: propertyID,
          section: section,
        },
      });
    };

    const showFeedback = () => {
      router.push({
        name: "PropertyFeedback",
        params: {
          propertyID: propertyID,
        },
      });
    };

    const toggleFavourite = async () => {
      userStore.toggleFavourite(propertyID);
    };

    return {
      // variables
      property,
      user,
      userStore,
      // methods
      showPricing,
      showFeedback,
      highlightClicked,
      showDetails,
      isFavourite,
      featuredLink,
      toggleFavourite,
      showFeaturedLink,
      // icons
      chevronForward,
      starOutline,
      star,
      bookmark,
    };
  },
});
</script>
