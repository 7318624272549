<template>
  <ion-page>
    <uncoded-header title="Edit Property" />
    <ion-content>
      <wait v-if="waitMessage">{{ waitMessage }}</wait>
      <div class="flex vertical fullheight p" v-if="floor">
        <property-header :property="property" />
        <nav-header @back="back">Documents</nav-header>
        <div class="fullwidth">
          <ion-item v-for="(document, index) in floor.documents" :key="index" class="fullwidth">
            <ion-icon :icon="documentOutline" color="primary" />
            <div class="flex pl">
              <ion-input v-model="document.description" @ionInput="markDirty()" :placeholder="`${document.filename}`"></ion-input>
              <div @click="showDocumentTypes(document)" class="cp fs">
                {{ getDocumentTypeLabel(document.documentTypeID) }}
              </div>
            </div>
            <ion-icon :icon="trashOutline" slot="end" @click="deleteDocument(document)" color="danger" />
          </ion-item>
        </div>
        <div class="grow"></div>
        <ion-button expand="full" class="fullwidth medium primary" @click="triggerDocumentSelector">Add document</ion-button>

        <input type="file" ref="documentSelector" hidden multiple @change="addDocuments" />
      </div>
      <wait v-else>Loading documents</wait>
    </ion-content>

    <ion-modal :is-open="documentTypesVisible" presenting-element="rootElement" @didDismiss="documentTypesVisible = false">
      <ion-header>
        <ion-toolbar>
          <ion-title>Select Document Type</ion-title>
        </ion-toolbar>
      </ion-header>

      <ion-content>
        <ion-list>
          <ion-item v-for="type in floorDocumentTypes" :key="type.id" @click="selectDocumentType(type)">
            <ion-label>{{ type.name }}</ion-label>
          </ion-item>
        </ion-list>
      </ion-content>
    </ion-modal>
  </ion-page>
</template>

<script>
import { onMounted, ref } from "@vue/runtime-core";
import { useRoute, useRouter } from "vue-router";
import { chevronForward, trashOutline, documentOutline } from "ionicons/icons";
import { alertController, onIonViewWillEnter } from "@ionic/vue";
import UncodedHeader from "@/components/UncodedHeader";
import PropertyHeader from "@/components/PropertyHeader.vue";
import NavHeader from "@/components/NavHeader.vue";
import { usePropertyStore } from "@/stores/property";
import { storeToRefs } from "pinia";

export default {
  components: { NavHeader, PropertyHeader, UncodedHeader },
  setup() {
    const dirty = ref(false);
    const route = useRoute();
    const router = useRouter();
    const propertyID = route.params.propertyID;
    const floorID = route.params.floorID;
    const propertyStore = usePropertyStore();
    const { property, floor, floorDocumentTypes } = storeToRefs(propertyStore);

    const documentSelector = ref();
    const documentTypesVisible = ref(false);
    const currentDocument = ref();
    const waitMessage = ref();
    const rootElement = document.querySelector(".ion-page");

    onIonViewWillEnter(() => {
      propertyStore.setPropertyID(propertyID);
      propertyStore.setFloorID(floorID);
    });

    const markDirty = () => {
      dirty.value = true;
    };

    const back = async () => {
      if (dirty.value) {
        const alert = await alertController.create({
          header: "Are you sure?",
          message: "You have unsaved changes.",
          buttons: [
            {
              text: "Save & Close",
              handler: () => {
                propertyStore.saveFloor(floor.value);
                router.back();
              },
            },
            { text: "Cancel" },
            {
              text: "Discard Changes",
              role: "destructive",
              handler: () => {
                propertyStore.restoreFloor();
                router.back();
              },
            },
          ],
        });
        await alert.present();
      } else {
        router.back();
      }
    };

    const triggerDocumentSelector = () => {
      documentSelector.value.click();
    };

    const addDocuments = async () => {
      const files = event.target.files;

      for (const [, file] of Object.entries(files)) {
        waitMessage.value = "Uploading document...";
        await propertyStore.addFloorDocument(file);
        waitMessage.value = null;
      }
      documentSelector.value.value = "";
    };

    const showDocumentTypes = (document) => {
      currentDocument.value = document;
      documentTypesVisible.value = true;
    };

    const selectDocumentType = (documentType) => {
      currentDocument.value.documentTypeID = documentType.id;
      documentTypesVisible.value = false;
      markDirty();
    };

    const deleteDocument = async (document) => {
      const alert = await alertController.create({
        cssClass: "alert",
        header: "Are you sure?",
        message: "This document and associated information will be deleted immediately.",
        buttons: [
          {
            text: "Delete",
            role: "destructive",
            handler: () => {
              propertyStore.deleteFloorDocument(document);
            },
          },
          {
            text: "Cancel",
            role: "cancel",
          },
        ],
      });
      await alert.present();
    };

    const getDocumentTypeLabel = (documentTypeID) => {
      const foundType = floorDocumentTypes.value.find((dt) => dt.id == documentTypeID);
      return foundType?.name || "Choose document type";
    };

    return {
      // variables
      floor,
      property,
      documentSelector,
      documentTypesVisible,
      floorDocumentTypes,
      currentDocument,
      rootElement,
      waitMessage,
      // methods
      getDocumentTypeLabel,
      addDocuments,
      showDocumentTypes,
      selectDocumentType,
      deleteDocument,
      triggerDocumentSelector,
      markDirty,
      back,
      // icons
      chevronForward,
      trashOutline,
      documentOutline,
    };
  },
};
</script>
