<template>
  <ion-page>
    <uncoded-header title="Edit Users" />

    <ion-content :scroll-y="false">
      <div class="flex vertical nogap">
        <div class="flex vertical ph left">
          <div>
            <ion-segment v-model="tab">
              <ion-segment-button value="users">Users</ion-segment-button>
              <ion-segment-button value="properties">Properties</ion-segment-button>
            </ion-segment>
          </div>

          <div class="fullwidth">
            <div v-if="tab == 'users'">
              <div class="pl mt"><ion-input placeholder="search" v-model="userFilter"></ion-input></div>
              <ion-item v-for="user in filteredUsers" :key="user.id" @click="showUser(user)">
                {{ user.id }}
              </ion-item>
            </div>
            <div v-if="tab == 'properties'">
              <div class="pl mt">
                <ion-input placeholder="search" v-model="propertyFilter"></ion-input>
              </div>
              <ion-item v-for="property in filteredProperties" :key="property.id" @click="showProperty(property)">
                {{ property.title }}
              </ion-item>
            </div>
          </div>
        </div>
      </div>
    </ion-content>

    <ion-modal :is-open="userVisible" @didDismiss="userVisible = false" :initial-breakpoint="1" :breakpoints="[0, 1]">
      <!-- <ion-content> -->
      <div class="flex vertical left bgd r1">
        <div class="fg p cp">{{ currentUser.id }}</div>
        <div class="fullwidth">
          <ion-input class="cs" placeholder="search" v-model="propertyFilter"></ion-input>
        </div>
        <div class="scroll fullwidth">
          <ion-item v-for="property in filteredProperties" :key="property.id" @click="toggleUserProperty(currentUser, property)">
            <ion-label>{{ property.title }}</ion-label>
            <div slot="end">
              <ion-icon :icon="checkmark" v-if="currentUser.properties?.includes(property.id)" />
            </div>
          </ion-item>
        </div>
        <div class="grow"></div>
      </div>
      <!-- </ion-content> -->
    </ion-modal>

    <ion-modal :is-open="propertyVisible" @didDismiss="propertyVisible = false" :initial-breakpoint="1" :breakpoints="[0, 1]">
      <!-- <ion-content> -->
      <div class="flex vertical left bgd r1">
        <div class="fg p">{{ currentProperty.title }}</div>
        <div class="fullwidth">
          <ion-input placeholder="search" v-model="userFilter"></ion-input>
        </div>
        <div class="scroll fullwidth">
          <ion-item v-for="user in filteredUsers" :key="user.id" @click="toggleUserProperty(user, currentProperty)">
            <ion-label>{{ user.id }}</ion-label>
            <div slot="end">
              <ion-icon :icon="checkmark" v-if="user.properties?.includes(currentProperty.id)" />
            </div>
          </ion-item>
        </div>
        <div class="grow"></div>
      </div>
      <!-- </ion-content> -->
    </ion-modal>
  </ion-page>
</template>

<script>
import UncodedHeader from "@/components/UncodedHeader.vue";
import { useRoute, useRouter } from "vue-router";
import { useUserStore } from "@/stores/user";
import { usePropertyStore } from "@/stores/property";
import { storeToRefs } from "pinia";
import { computed, onMounted, ref } from "vue";
import { onIonViewWillEnter } from "@ionic/vue";
import { checkmark } from "ionicons/icons";

export default {
  name: "EditUsers",
  components: { UncodedHeader },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const userStore = useUserStore();
    const propertyStore = usePropertyStore();
    const { users } = storeToRefs(userStore);
    const { allProperties } = storeToRefs(propertyStore);
    const userFilter = ref();
    const propertyFilter = ref();
    const tab = ref("users");
    const userVisible = ref(false);
    const propertyVisible = ref(false);
    const currentUser = ref();
    const currentProperty = ref();
    let rootElement;

    const showUser = (user) => {
      currentUser.value = user;
      userVisible.value = true;
    };

    const showProperty = (property) => {
      currentProperty.value = property;
      propertyVisible.value = true;
    };

    const toggleUserProperty = (user, property) => {
      console.log(user, property);
      if (user.properties?.includes(property.id)) {
        user.properties = user.properties.filter((p) => p != property.id);
      } else {
        if (!user.properties) user.properties = [];
        user.properties.push(property.id);
      }
      userStore.saveUser(user.id, user);
    };

    const filteredUsers = computed(() => {
      if (!userFilter.value || userFilter.value == "") return users.value;
      return users.value.filter((user) => user.id.indexOf(userFilter.value) != -1);
    });

    const filteredProperties = computed(() => {
      if (!propertyFilter.value || propertyFilter.value == "") return allProperties.value;
      return allProperties.value.filter((property) => property.title.toLowerCase().indexOf(propertyFilter.value.toLowerCase()) != -1);
    });

    onIonViewWillEnter(() => {
      propertyStore.fetchPropertySkeletons();
    });

    onMounted(() => {
      rootElement = document.querySelector(".ion-page");
    });

    return {
      // variables
      filteredUsers,
      filteredProperties,
      propertyFilter,
      userFilter,
      tab,
      currentUser,
      currentProperty,
      userVisible,
      propertyVisible,
      rootElement,
      // methods
      showUser,
      showProperty,
      toggleUserProperty,
      // icons
      checkmark,
    };
  },
};
</script>

<style scoped>
ion-segment-button {
  padding: 5px 10px;
  margin-top: 10px;
}

ion-segment-button::part(indicator-background) {
  color: var(--ion-color-primary);
}

ion-segment-button::part(native) {
  color: var(--ion-color-light);
}

.segment-button-checked::part(native) {
  color: var(--ion-color-primary);
}

.segment-button-checked.ios::part(native) {
  color: var(--ion-color-dark);
}

ion-segment-button::part(indicator-background) {
  border-radius: 20px;
}
</style>
