<template>
  <IonApp>
    <IonSplitPane content-id="main-content">
      <ion-menu content-id="main-content" type="overlay" :swipe-gesture="false">
        <ion-header>
          <div class="flex centered mt safe-top">
            <ion-chip v-if="!isProduction" color="warning" :outline="true" class="mt2"> Development Environment </ion-chip>
          </div>
          <img src="/img/logos/logo text black.png" alt="Online Inside" @click="goHome" class="logo light"
        /></ion-header>

        <ion-content>
          <!-- <div class="content"> -->
          <ion-list>
            <div v-for="(item, index) in menuItems" :key="index">
              <ion-item
                :router-link="item.routerLink"
                v-if="hasPermissions(item.requiredRoles) && (!item.requiresRegistered || (user && !user.isAnonymous))"
                @click="closeMenu"
                :lines="item.lines || 'inset'"
              >
                <ion-icon slot="start" :icon="item.icon" color="primary" />
                <ion-label color="light">{{ item.label }}</ion-label>
              </ion-item>
            </div>
          </ion-list>

          <ion-list v-if="user?.roles?.length > 0">
            <ion-item lines="none" color="medium"> <ion-label color="primary">ADMINISTRATION</ion-label></ion-item>
            <div v-for="(item, index) in adminMenuItems" :key="index">
              <ion-item
                :router-link="item.routerLink + (item.isOrganisationSpecific ? selectedOrganisationID : '')"
                v-if="hasPermissions(item.requiredRoles)"
                @click="closeMenu"
                :lines="item.lines || 'inset'"
              >
                <ion-icon slot="start" :icon="item.icon" color="primary" />
                <ion-label color="light">{{ item.label }}</ion-label>
              </ion-item>
            </div>
          </ion-list>
          <!-- </div> -->
        </ion-content>

        <ion-footer>
          <ion-button @click="handleLogin" expand="full" class="dark bdl mt2 mh2">
            <ion-label v-if="!user || user.isAnonymous">Log in</ion-label>
            <ion-label v-else>Logout</ion-label>
          </ion-button>

          <div class="flex centered mt pb2 safe-bottom">
            <div class="fs cl">
              {{ user?.id }}
            </div>
            <ion-icon v-if="user?.emailVerified" class="ft cp" :icon="checkmark" />
          </div>
        </ion-footer>
      </ion-menu>

      <ion-router-outlet id="main-content" />
    </IonSplitPane>
  </IonApp>
</template>

<script>
import { IonApp, IonRouterOutlet, IonMenu, IonSplitPane, menuController } from "@ionic/vue";
import { defineComponent, onMounted } from "vue";
import { useRouter } from "vue-router";
import { App } from "@capacitor/app";
import { SplashScreen } from "@capacitor/splash-screen";
import { useUserStore } from "@/stores/user";

import {
  informationCircleOutline,
  personOutline,
  bookmarksOutline,
  bookOutline,
  businessOutline,
  peopleOutline,
  optionsOutline,
  pulseOutline,
  calendarNumberOutline,
  mailOutline,
  checkmark,
} from "ionicons/icons";
import { storeToRefs } from "pinia";

export default defineComponent({
  name: "Main",
  components: {
    IonApp,
    IonRouterOutlet,
    IonMenu,
    IonSplitPane,
  },
  setup() {
    const router = useRouter();
    const userStore = useUserStore();
    const { user } = storeToRefs(userStore);

    const isProduction = process.env.VUE_APP_MODE == "production";
    const mapkey = process.env.VUE_APP_GOOGLE_MAPS_API_KEY;
    SplashScreen.hide();

    onMounted(() => {
      App.addListener("appUrlOpen", (event) => {
        if (/^https:\/\/.{0,10}\.?onlineinside\.com\/auth/.test(event.url)) {
          router.push({ name: "Auth", params: { url: event.url } });
          return;
        }

        if (/^https:\/\/.{0,10}\.?onlineinside\.com\/qr/.test(event.url)) {
          const slug = event.url.split("onlineinside.com").pop();
          if (slug) {
            router.push({ path: slug });
          }
        }

        if (/^https:\/\/.{0,10}\.?onlineinside\.com\/link/.test(event.url)) {
          const slug = event.url.split("onlineinside.com").pop();
          if (slug) {
            router.push({ path: slug });
          }
        }
      });
    });

    const handleLogin = async () => {
      menuController.close();
      if (user.value && !user.value?.isAnonymous) {
        await userStore.logout();
        router.push({ name: "Home" });
      } else {
        router.push({ name: "Login" });
      }
    };

    const closeMenu = () => {
      menuController.close();
    };

    const goHome = () => {
      router.push("/");
      menuController.close();
    };

    const hasPermissions = (permissions) => {
      if (!permissions) return true;
      if (!user.value?.roles) return false;
      if (user.value?.roles?.includes("superAdmin")) return true;
      return permissions.every((item) => user.value?.roles.includes(item));
    };

    const menuItems = [
      {
        label: "Shortlist",
        routerLink: "/shortlist",
        requiresRegistered: true,
        icon: bookmarksOutline,
      },
      {
        label: "Recently Viewed",
        routerLink: "/recent",
        requiresRegistered: true,
        icon: calendarNumberOutline,
      },
      {
        label: "My Account",
        routerLink: "/account",
        requiresRegistered: true,
        icon: personOutline,
      },
      {
        label: "Terms & Conditions",
        routerLink: "/terms",
        requiresRegistered: false,
        icon: bookOutline,
      },
      {
        label: "About Online Inside",
        routerLink: "/about",
        requiresRegistered: false,
        icon: informationCircleOutline,
      },
      {
        label: "Contact Us",
        routerLink: "/contact",
        requiresRegistered: false,
        icon: mailOutline,
        lines: "none",
      },
    ];

    const adminMenuItems = [
      {
        label: "Properties",
        routerLink: "/property/list",
        requiresRegistered: true,
        requiredRoles: ["propertyAdmin"],
        icon: businessOutline,
      },
      {
        label: "Contacts",
        routerLink: "/contactTypes/list",
        requiresRegistered: true,
        requiredRoles: ["contactAdmin"],
        icon: peopleOutline,
      },
      {
        label: "Carriers",
        routerLink: "/carrier/list",
        requiresRegistered: true,
        requiredRoles: ["carrierAdmin"],
        icon: pulseOutline,
      },
      {
        label: "Users",
        routerLink: "/users/edit",
        requiresRegistered: true,
        requiredRoles: ["userAdmin"],
        icon: personOutline,
        lines: "none",
      },
    ];

    return {
      user,
      isProduction,
      mapkey,
      menuItems,
      adminMenuItems,
      handleLogin,
      hasPermissions,
      closeMenu,
      goHome,
      optionsOutline,
      checkmark,
    };
  },
});
</script>
