<template>
  <ion-page>
    <uncoded-header title="Edit Contact Type" />
    <nav-header class="cl" @back="back">{{ contactType?.plural }}</nav-header>

    <ion-content :scroll-y="false">
      <div class="flex vertical" v-if="contactType">
        <div class="scroll fullwidth">
          <ion-item>
            <ion-label color="primary" position="stacked">Name</ion-label>
            <ion-input v-model="contactType.name" @ionInput="markDirty()" />
          </ion-item>
          <ion-item class="fullwidth">
            <ion-label color="primary" position="stacked"
              >Plural name</ion-label
            >
            <ion-input v-model="contactType.plural" @ionInput="markDirty()" />
          </ion-item>
          <ion-item class="fullwidth">
            <ion-label color="primary" position="stacked"
              >Contact title</ion-label
            >
            <ion-input v-model="contactType.contact" @ionInput="markDirty()" />
          </ion-item>
          <ion-item class="fullwidth">
            <ion-label color="primary" position="stacked"
              >Contact title (plural)</ion-label
            >
            <ion-input
              v-model="contactType.contactPlural"
              @ionInput="markDirty()"
            />
          </ion-item>
        </div>
        <div class="p fullwidth">
          <ion-button color="danger" expand="full" @click="confirmDelete()"
            >Delete Contact Type</ion-button
          >
        </div>
      </div>
      <wait v-else>Loading contact types...</wait>
    </ion-content>
  </ion-page>
</template>

<script>
import UncodedHeader from "@/components/UncodedHeader.vue";
import { useRoute, useRouter } from "vue-router";
import { alertController } from "@ionic/vue";
import { usePropertyStore } from "@/stores/property";
import { storeToRefs } from "pinia";
import { computed, ref } from "vue-demi";

export default {
  components: { UncodedHeader },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const contactTypeID = route.params.contactTypeID;

    const propertyStore = usePropertyStore();
    const { allContactTypes } = storeToRefs(propertyStore);
    const dirty = ref(false);

    const contactType = computed(() =>
      allContactTypes.value?.find((ct) => ct.id == contactTypeID)
    );

    const markDirty = () => {
      dirty.value = true;
    };

    const back = async () => {
      if (dirty.value) {
        const alert = await alertController.create({
          cssClass: "alert",
          header: "Are you sure?",
          message: "You have unsaved changes.",
          buttons: [
            {
              text: "Save & Close",
              handler: () => {
                save();
              },
            },
            { text: "Cancel" },
            {
              text: "Discard Changes",
              role: "destructive",
              handler: () => {
                router.back();
              },
            },
          ],
        });
        await alert.present();
      } else {
        router.back();
      }
    };

    const save = async (pop = true) => {
      await propertyStore.saveContactType(contactTypeID);
      if (pop) router.back();
    };

    const confirmDelete = async () => {
      const alert = await alertController.create({
        cssClass: "alert",
        header: "Are you sure?",
        message:
          "Deleting this contact type will also delete all organisations of this type.",
        buttons: [
          {
            text: "Delete",
            role: "destructive",
            handler: () => {
              propertyStore.deleteContactType(contactTypeID);
            },
          },
          { text: "Cancel" },
        ],
      });
      await alert.present();
    };

    return {
      // variables
      dirty,
      contactType,
      // methods
      confirmDelete,
      markDirty,
      back,
      // icons
    };
  },
};
</script>
