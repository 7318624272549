/* eslint-disable no-console */
import { createApp, h } from "vue";
import Main from "./Main.vue";
import router from "./router";
import "./registerServiceWorker";
import { initialiseFirebase } from "@/composables/firebase";
import VueGoogleMaps from "@fawmi/vue-google-maps";

/* Sentry  logging */
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import "./theme/uncoded.css";

/* Ionic Components */
import {
  IonBackdrop,
  IonButtons,
  IonCheckbox,
  IonicVue,
  IonMenuButton,
  IonSpinner,
  IonPage,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonSegment,
  IonSegmentButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonList,
  IonNote,
  IonHeader,
  IonToolbar,
  IonButton,
  IonFooter,
  IonGrid,
  IonCol,
  IonRow,
  IonTitle,
  IonMenu,
  IonListHeader,
  IonItemGroup,
  IonThumbnail,
  IonToggle,
  IonReorderGroup,
  IonReorder,
  IonInput,
  IonTextarea,
  IonAccordion,
  IonAccordionGroup,
  IonRadio,
  IonRadioGroup,
  IonModal,
  IonRefresher,
  IonRefresherContent,
  IonChip,
  IonNav,
  IonSelect,
  IonSelectOption,
} from "@ionic/vue";

import Wait from "@/components/Wait.vue";
import NavHeader from "@/components/NavHeader.vue";
import { createPinia } from "pinia";
import mixpanel from "mixpanel-browser";

const store = createPinia();
const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
};
if (process.env.VUE_APP_FIREBASE_MEASUREMENT_ID) firebaseConfig.measurementID = process.env.VUE_APP_FIREBASE_MEASUREMENT_ID;

const mode = process.env.VUE_APP_MODE;
mixpanel.init(process.env.VUE_APP_MIXPANEL_TOKEN, { host: "api-eu.mixpanel.com", debug: true, ignore_dnt: true });
initialiseFirebase(firebaseConfig);

const app = createApp({
  render: () => h(Main),
});

if (mode == "production") {
  Sentry.init({
    app,
    dsn: "https://f0559ff39a6d46b48763fc3d26729a6e@o1113233.ingest.sentry.io/6143452",
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        // tracingOrigins: ["localhost", "online-inside.web.app", /^\//],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,
  });
}

app
  .use(IonicVue, { swipeBackEnabled: false })
  .use(router)
  .use(store)
  .use(VueGoogleMaps, {
    load: {
      key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
    },
  });
app.component("IonPage", IonPage);
app.component("IonContent", IonContent);
app.component("IonIcon", IonIcon);
app.component("IonItem", IonItem);
app.component("IonLabel", IonLabel);
app.component("IonSegment", IonSegment);
app.component("IonSegmentButton", IonSegmentButton);
app.component("IonCard", IonCard);
app.component("IonCardContent", IonCardContent);
app.component("IonCardHeader", IonCardHeader);
app.component("IonCardTitle", IonCardTitle);
app.component("IonCardSubtitle", IonCardSubtitle);
app.component("IonList", IonList);
app.component("IonNote", IonNote);
app.component("IonHeader", IonHeader);
app.component("IonButton", IonButton);
app.component("IonMenuButton", IonMenuButton);
app.component("IonButtons", IonButtons);
app.component("IonFooter", IonFooter);
app.component("IonToolbar", IonToolbar);
app.component("IonTitle", IonTitle);
app.component("IonMenu", IonMenu);
app.component("IonListHeader", IonListHeader);
app.component("IonItemGroup", IonItemGroup);
app.component("IonThumbnail", IonThumbnail);
app.component("IonToggle", IonToggle);
app.component("IonReorderGroup", IonReorderGroup);
app.component("IonReorder", IonReorder);
app.component("IonInput", IonInput);
app.component("IonTextarea", IonTextarea);
app.component("IonGrid", IonGrid);
app.component("IonCol", IonCol);
app.component("IonRow", IonRow);
app.component("IonAccordionGroup", IonAccordionGroup);
app.component("IonAccordion", IonAccordion);
app.component("IonRadio", IonRadio);
app.component("IonRadioGroup", IonRadioGroup);
app.component("IonBackdrop", IonBackdrop);
app.component("IonModal", IonModal);
app.component("IonCheckbox", IonCheckbox);
app.component("IonSpinner", IonSpinner);
app.component("IonChip", IonChip);
app.component("IonNav", IonNav);
app.component("Wait", Wait);
app.component("IonSelect", IonSelect);
app.component("IonSelectOption", IonSelectOption);

app.component("NavHeader", NavHeader);
app.component("IonRefresher", IonRefresher);
app.component("IonRefresherContent", IonRefresherContent);

router.isReady().then(() => {
  app.mount("#app");
});
