<template>
  <ion-page>
    <uncoded-header title="Edit Property" />

    <ion-content scroll-y="false">
      <div class="flex vertical ph">
        <property-header :property="property" />

        <nav-header @back="back">
          {{ space?.title }}
        </nav-header>
        <div class="fullwidth scroll" v-if="space">
          <ion-item lines="none">
            <ion-label color="secondary" position="stacked">Title</ion-label>
            <ion-input v-model="space.title" @ionInput="markDirty()" />
          </ion-item>

          <ion-item lines="none">
            <ion-label color="secondary" position="stacked">Subtitle</ion-label>
            <ion-input placeholder="Subtitle" v-model="space.subtitle" @ionInput="markDirty()"></ion-input>
          </ion-item>

          <ion-item lines="none">
            <ion-label color="secondary" position="stacked">Square footage</ion-label>
            <ion-input placeholder="Square feet" v-model="space.sqfeet" @ionInput="markDirty()"></ion-input>
          </ion-item>

          <ion-item lines="none">
            <ion-segment mode="ios" v-model="space.availability" @ionChange="markDirty()">
              <ion-segment-button value="Available"><ion-label>Available</ion-label></ion-segment-button>
              <ion-segment-button value="Leased"><ion-label>Leased</ion-label></ion-segment-button>
            </ion-segment>
          </ion-item>

          <ion-item lines="none">
            <ion-label color="secondary" position="stacked">Description</ion-label>
            <div></div>
            <ion-textarea
              placeholder="Description"
              v-model="space.description"
              @ionInput="markDirty()"
              :rows="5"
              class="mb2"
            ></ion-textarea>
          </ion-item>

          <div class="action cs fullwidth" @click="showDocuments()">
            <ion-label>Documents</ion-label>
            <ion-icon :icon="chevronForward" />
          </div>
          <div class="action cs fullwidth" @click="showPhotos()">
            <ion-label>Photos</ion-label>
            <ion-icon :icon="chevronForward" />
          </div>
          <div class="action cs fullwidth" @click="showFeatures()">
            <ion-label>Features</ion-label>
            <ion-icon :icon="chevronForward" />
          </div>
          <div class="action cs fullwidth" @click="showTerms()">
            <ion-label>Terms &amp; Conditions</ion-label>
            <ion-icon :icon="chevronForward" />
          </div>
        </div>
        <div class="grow"></div>
        <div class="fullwidth mb2">
          <ion-button @click="confirmDelete()" class="fullwidth danger">Delete Space</ion-button>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { computed, ref } from "@vue/runtime-core";
import { useRoute, useRouter } from "vue-router";
import { chevronForward } from "ionicons/icons";
import { alertController, onIonViewWillEnter } from "@ionic/vue";
import UncodedHeader from "@/components/UncodedHeader";
import PropertyHeader from "@/components/PropertyHeader.vue";
import NavHeader from "@/components/NavHeader.vue";
import { usePropertyStore } from "@/stores/property";
import { storeToRefs } from "pinia";

export default {
  name: "Space",
  components: { NavHeader, PropertyHeader, UncodedHeader },
  setup() {
    const dirty = ref(false);
    const router = useRouter();
    const route = useRoute();
    const propertyID = route.params.propertyID;
    const floorID = route.params.floorID;
    const spaceID = route.params.spaceID;
    const propertyStore = usePropertyStore();
    const { property, floor } = storeToRefs(propertyStore);

    onIonViewWillEnter(() => {
      propertyStore.setPropertyID(propertyID);
      propertyStore.setFloorID(floorID);
    });

    const space = computed(() => floor.value?.spaces?.find((s) => s.id == spaceID));

    const markDirty = () => {
      dirty.value = true;
    };

    const back = async () => {
      if (dirty.value) {
        const alert = await alertController.create({
          cssClass: "alert",
          header: "Are you sure?",
          message: "You have unsaved changes.",
          buttons: [
            {
              text: "Save & Close",
              handler: () => {
                propertyStore.saveSpace(space.value.id);
                router.back();
              },
            },
            { text: "Cancel" },
            {
              text: "Discard Changes",
              role: "destructive",
              handler: () => {
                router.back();
              },
            },
          ],
        });
        await alert.present();
      } else {
        router.back();
      }
    };

    const confirmDelete = async () => {
      const alert = await alertController.create({
        cssClass: "alert",
        header: "Are you sure?",
        message: "Deleting this space is irreversible.",
        buttons: [
          {
            text: "Delete",
            role: "destructive",
            handler: () => {
              propertyStore.deleteSpace(space.value);
              router.back();
            },
          },
          { text: "Cancel" },
        ],
      });
      await alert.present();
    };

    const showDocuments = () => {
      router.push({
        name: "EditPropertySpaceDocuments",
        params: { propertyID: propertyID, floorID: floorID, spaceID: spaceID },
      });
    };

    const showPhotos = () => {
      router.push({
        name: "EditPropertySpacePhotos",
        params: { propertyID: propertyID, floorID: floorID, spaceID: spaceID },
      });
    };

    const showFeatures = () => {
      router.push({
        name: "EditPropertySpaceFeatures",
        params: { propertyID: propertyID, floorID: floorID, spaceID: spaceID },
      });
    };

    const showTerms = () => {
      router.push({
        name: "EditPropertySpaceTerms",
        params: { propertyID: propertyID, floorID: floorID, spaceID: spaceID },
      });
    };

    return {
      // variables
      space,
      floor,
      property,
      // methods
      showDocuments,
      showPhotos,
      showFeatures,
      showTerms,
      confirmDelete,
      markDirty,
      back,
      // icons
      chevronForward,
    };
  },
};
</script>
