import { createRouter, createWebHistory } from "@ionic/vue-router";
import { useUserStore } from "@/stores/user";

import DocumentViewer from "@/views/view/property/common/Document.vue";
import Property from "@/views/view/Property.vue";
import PropertyDetails from "@/views/view/property/details/Details.vue";
import PropertyPricing from "@/views/view/property/pricing/Pricing.vue";
import PropertyBullseyeDetails from "@/views/view/property/bullseye/BullseyeDetail.vue";
import PropertyBullseyeDetailsWhy from "@/views/view/property/bullseye/BullseyeWhy.vue";
import PropertyGallery from "@/views/view/property/details/Gallery.vue";
import PropertyAccreditations from "@/views/view/property/details/Accreditations.vue";
import PropertyDocuments from "@/views/view/property/details/Documents.vue";
import PropertyLinks from "@/views/view/property/details/Links.vue";
import PropertyAccreditation from "@/views/view/property/details/Accreditation.vue";
import PropertyContacts from "@/views/view/property/details/Contacts.vue";
import PropertyContact from "@/views/view/property/details/Contact.vue";
import PropertyFeedback from "@/views/view/property/details/GiveFeedback.vue";
import PropertySpaces from "@/views/view/property/spaces/Spaces.vue";
import PropertySpace from "@/views/view/property/spaces/Space.vue";
import PropertySpaceTerms from "@/views/view/property/spaces/Terms.vue";
import PropertySpaceGallery from "@/views/view/property/spaces/Gallery.vue";
import PropertySpaceDocuments from "@/views/view/property/spaces/Documents.vue";
import PropertyFloorDocuments from "@/views/view/property/spaces/FloorDocuments.vue";

import EditProperty from "@/views/edit/Property.vue";
import EditPropertyDescription from "@/views/edit/PropertyComponents/Description.vue";
import EditPropertyLocation from "@/views/edit/PropertyComponents/Location.vue";
import EditPropertyWifi from "@/views/edit/PropertyComponents/WiFi.vue";
import EditPropertyAccreditations from "@/views/edit/PropertyComponents/Accreditations.vue";
import EditPropertyPhotos from "@/views/edit/PropertyComponents/Photos.vue";
import EditPropertyDocuments from "@/views/edit/PropertyComponents/Documents.vue";
import EditPropertyLinks from "@/views/edit/PropertyComponents/Links.vue";
import EditPropertyBullseye from "@/views/edit/PropertyComponents/Highlights.vue";
import EditPropertyContacts from "@/views/edit/PropertyComponents/Contacts.vue";
// import EditPropertyOrganisations from "@/views/edit/PropertyComponents/Contact/Organisations.vue";
// import EditPropertyPeople from "@/views/edit/PropertyComponents/Contact/People.vue";
// import EditPropertyPerson from "@/views/edit/PropertyComponents/Contact/Person.vue";
import EditPropertyFloor from "@/views/edit/PropertyComponents/Floor.vue";
import EditPropertyFloorDocuments from "@/views/edit/PropertyComponents/Floor/Documents.vue";
import EditPropertySpace from "@/views/edit/PropertyComponents/Space.vue";
import EditPropertySpaceDocuments from "@/views/edit/PropertyComponents/Space/Documents.vue";
import EditPropertySpacePhotos from "@/views/edit/PropertyComponents/Space/Photos.vue";
import EditPropertySpaceFeatures from "@/views/edit/PropertyComponents/Space/Features.vue";
import EditPropertySpaceTerms from "@/views/edit/PropertyComponents/Space/Terms.vue";

import EditPropertyFloors from "@/views/edit/PropertyComponents/Floors.vue";
import EditPropertyQRCode from "@/views/edit/PropertyComponents/QR.vue";

import EditCarrier from "@/views/edit/Carrier.vue";
import EditContactTypeDetails from "@/views/edit/ContactTypeDetails.vue";
import EditContactType from "@/views/edit/ContactType.vue";
import EditContact from "@/views/edit/Contact.vue";
import EditOrganisation from "@/views/edit/Organisation.vue";
import EditOrganisationDetails from "@/views/edit/OrganisationDetails.vue";

import EditUsers from "@/views/edit/Users.vue";

import ListProperties from "@/views/list/ListProperties.vue";
import ListCarriers from "@/views/list/ListCarriers.vue";
import ListContactTypes from "@/views/list/ListContactTypes.vue";

import Home from "@/views/home/Home.vue";
import AppHome from "@/views/home/AppHome.vue";

import StaticHome from "@/views/home/StaticHome.vue";
import MobileStaticHome from "@/views/home/MobileStaticHome.vue";

import Download from "@/views/utility/Download.vue";
import Shortlist from "@/views/utility/Shortlist.vue";
import Recent from "@/views/utility/Recent.vue";
import Account from "@/views/utility/Account.vue";
import About from "@/views/utility/About.vue";
import Terms from "@/views/utility/Terms.vue";
import Contact from "@/views/utility/Contact.vue";
import NoAccess from "@/views/utility/NoAccess.vue";
import LegalNotices from "@/views/utility/LegalNotices.vue";
import Onboarding from "@/views/utility/Onboarding.vue";
import NotFound from "@/views/utility/404.vue";
import CloseMe from "@/views/utility/CloseMe.vue";
import Upgrade from "@/views/utility/Upgrade.vue";

import LocateMe from "@/views/view/Locate.vue";
import GetPrices from "@/views/view/locate/GetPrices.vue";

import Login from "@/views/auth/Login.vue";
import LoginWithPassword from "@/views/auth/LoginWithPassword.vue";
import Register from "@/views/auth/Register.vue";
import Reminder from "@/views/auth/Reminder.vue";
import VerifyEmail from "@/views/auth/VerifyEmail.vue";
import Auth from "@/views/auth/Auth.vue";
import ResetPassword from "@/views/auth/responses/ResetPassword.vue";
import ValidateEmail from "@/views/auth/responses/ValidateEmail.vue";
import MagicLink from "@/views/auth/responses/MagicLink.vue";

import { isPlatform } from "@ionic/vue";

import { setScreen } from "@/composables/analytics";
import { computed } from "vue";
import { storeToRefs } from "pinia";
import { getFirebaseDocument, checkVersion } from "@/composables/firebase";
import mixpanel from "mixpanel-browser";

const checkStaticHome = async (to, from, next) => {
  if (isMobile.value) {
    if (to.name == "StaticHome") {
      next({ name: "MobileStaticHome" });
    } else {
      next();
    }
  } else {
    if (to.name == "MobileStaticHome") {
      next({ name: "StaticHome" });
    } else {
      next();
    }
  }
};

const canInstall = computed(() => {
  return (isPlatform("ios") || isPlatform("android")) && isPlatform("mobileweb");
});

const isMobile = computed(() => {
  return isPlatform("iphone") || isPlatform("android");
});

const checkRole = (role) => {
  const userStore = useUserStore();
  return new Promise(checkForRoles);
  function checkForRoles(resolve) {
    if (userStore.data?.user?.roles) {
      resolve(userStore.data?.user?.roles?.includes(role));
    } else {
      setTimeout(checkForRoles.bind(this, resolve), 50);
    }
  }
};

const checkHome = async (to, from, next) => {
  // If in native app, go to App Home
  if (isMobile.value) {
    if (to.name == "Home") {
      next({ name: "AppHome" });
    } else {
      next();
    }
  } else {
    if (to.name == "AppHome") {
      next({ name: "Home" });
    } else {
      next();
    }
  }
};

const checkContactAdmin = (to, _, next) => {
  if (checkRole("contactAdmin")) {
    next();
  } else {
    next({ name: "NoAccess" });
  }
};

const checkPropertyAdmin = (to, _, next) => {
  if (checkRole("propertyAdmin")) {
    next();
  } else {
    next({ name: "NoAccess" });
  }
};

const checkCarrierAdmin = (to, _, next) => {
  if (checkRole("carrierAdmin")) {
    next();
  } else {
    next({ name: "NoAccess" });
  }
};

const checkUserAdmin = (to, _, next) => {
  if (checkRole("userAdmin")) {
    next();
  } else {
    next({ name: "NoAccess" });
  }
};

const processLink = (to, _, next) => {
  const components = to.path.split("/");
  const linkID = components[2];
  getFirebaseDocument("links", linkID).then((link) => {
    if (canInstall.value) {
      next({
        name: "DownloadProperty",
        params: { propertyID: link.propertyID },
      });
    } else {
      next({
        name: "Property",
        params: { propertyID: link.propertyID },
      });
    }
  });
};

const processQR = (to, _, next) => {
  if (window.location.hostname == "dev.onlineinside.com") window.location = `https://www.onlineinside.com${to.fullPath}`;
  const components = to.path.split("/");
  const shortcode = components[2].toUpperCase();

  getFirebaseDocument("shortcodes", shortcode).then((doc) => {
    console.log("DOC", doc);
    if (!doc) next({ name: "Home" });
    mixpanel.track("QR Code Scanned", { shortcode, landlordID: doc.landlordID, propertyID: doc.propertyID });
    if (canInstall.value) {
      switch (doc.type) {
        case "landlord":
          next({
            name: "DownloadLandlord",
            params: { landlordID: doc.landlordID },
          });
          break;
        case "property":
          next({
            name: "DownloadProperty",
            params: { propertyID: doc.propertyID },
          });
          break;
        case "editProperty":
          next({
            name: "EditProperty",
            params: { propertyID: doc.propertyID },
          });
          break;
        case "space":
          next({
            name: "DownloadSpace",
            params: {
              propertyID: doc.propertyID,
              floorID: doc.floorID,
              spaceID: doc.spaceID,
            },
          });
          break;
        default:
          next();
      }
    } else {
      switch (doc.type) {
        case "landlord":
          next({
            name: "Landlord",
            params: { landlordID: doc.landlordID },
          });
          break;
        case "property":
          next({
            name: "Property",
            params: { propertyID: doc.propertyID },
          });
          break;
        case "editProperty":
          next({
            name: "EditProperty",
            params: { propertyID: doc.propertyID },
          });
          break;
        case "space":
          next({
            name: "Space",
            params: {
              propertyID: doc.propertyID,
              floorID: doc.floorID,
              spaceID: doc.spaceID,
            },
          });
          break;
        default:
          next();
      }
    }
  });
};

const routes = [
  {
    path: "/linkedin",
    name: "StaticHome",
    beforeEnter: checkStaticHome,
    component: StaticHome,
  },
  {
    path: "/mobilelinkedin",
    name: "MobileStaticHome",
    beforeEnter: checkStaticHome,
    component: MobileStaticHome,
  },
  {
    path: "",
    redirect: "/home",
  },
  {
    path: "/link/:id",
    beforeEnter: processLink,
    component: Home,
  },
  {
    path: "/qr/:id",
    beforeEnter: processQR,
    component: Home,
  },
  {
    path: "/home",
    name: "Home",
    beforeEnter: checkHome,
    component: Home,
  },
  {
    path: "/appHome",
    name: "AppHome",
    beforeEnter: checkHome,
    component: AppHome,
  },
  // View links
  {
    path: "/property/view/:propertyID",
    name: "Property",
    component: Property,
  },
  {
    path: "/property/view/pricing/:propertyID",
    name: "PropertyPricing",
    component: PropertyPricing,
  },
  {
    path: "/property/view/details/:propertyID",
    name: "PropertyDetails",
    component: PropertyDetails,
  },
  {
    path: "/property/view/bullseye/:propertyID/:section",
    name: "PropertyBullseyeDetails",
    component: PropertyBullseyeDetails,
  },
  {
    path: "/property/view/why/:propertyID/:score/:section",
    name: "BullseyeWhy",
    component: PropertyBullseyeDetailsWhy,
  },
  {
    path: "/property/view/spaces/:propertyID",
    name: "PropertySpaces",
    component: PropertySpaces,
  },
  {
    path: "/property/view/:propertyID/:floorID/:spaceID",
    name: "PropertySpace",
    component: PropertySpace,
  },
  {
    path: "/property/view/accreditations/:propertyID",
    name: "PropertyAccreditations",
    component: PropertyAccreditations,
  },
  {
    path: "/property/view/documents/:propertyID",
    name: "PropertyDocuments",
    component: PropertyDocuments,
  },
  {
    path: "/property/view/links/:propertyID",
    name: "PropertyLinks",
    component: PropertyLinks,
  },
  {
    path: "/property/view/feedback/:propertyID",
    name: "PropertyFeedback",
    component: PropertyFeedback,
  },
  {
    path: "/property/view/accreditation/:propertyID/:accreditationID/:levelID",
    name: "PropertyAccreditation",
    component: PropertyAccreditation,
  },
  {
    path: "/property/view/gallery/:propertyID",
    name: "PropertyGallery",
    component: PropertyGallery,
  },
  {
    path: "/property/view/contacts/:propertyID",
    name: "PropertyContacts",
    component: PropertyContacts,
  },
  {
    path: "/property/view/contact/:propertyID/:typeID/:organisationID/:contactID",
    name: "PropertyContact",
    component: PropertyContact,
  },
  {
    path: "/property/view/space/terms/:propertyID/:floorID/:spaceID",
    name: "SpaceTerms",
    component: PropertySpaceTerms,
  },
  {
    path: "/property/view/space/gallery/:propertyID/:floorID/:spaceID",
    name: "SpaceGallery",
    component: PropertySpaceGallery,
  },
  {
    path: "/property/view/floor/documents/:propertyID/:floorID/:spaceID",
    name: "SpaceDocuments",
    component: PropertySpaceDocuments,
  },
  {
    path: "/property/view/space/documents/:propertyID/:floorID",
    name: "FloorDocuments",
    component: PropertyFloorDocuments,
  },
  {
    path: "/document/:propertyID/:path",
    name: "Document",
    component: DocumentViewer,
  },
  // Edit links
  {
    path: "/contactTypeDetails/edit/:contactTypeID",
    name: "EditContactTypeDetails",
    beforeEnter: checkContactAdmin,
    component: EditContactTypeDetails,
  },
  {
    path: "/contactTypes/edit/:contactTypeID",
    name: "EditContactType",
    beforeEnter: checkContactAdmin,
    component: EditContactType,
  },
  {
    path: "/organisation/edit/:contactTypeID/:organisationID",
    name: "EditOrganisation",
    beforeEnter: checkContactAdmin,
    component: EditOrganisation,
  },
  {
    path: "/organisationDetails/edit/:contactTypeID/:organisationID",
    name: "EditOrganisationDetails",
    beforeEnter: checkContactAdmin,
    component: EditOrganisationDetails,
  },
  {
    path: "/contact/edit/:contactTypeID/:organisationID/:contactID",
    name: "EditContact",
    beforeEnter: checkContactAdmin,
    component: EditContact,
  },
  {
    path: "/users/edit",
    name: "EditUsers",
    beforeEnter: checkUserAdmin,
    component: EditUsers,
  },
  {
    path: "/property/edit/:propertyID",
    name: "EditProperty",
    beforeEnter: checkPropertyAdmin,
    component: EditProperty,
  },
  {
    path: "/property/edit/location/:propertyID",
    name: "EditPropertyLocation",
    beforeEnter: checkPropertyAdmin,
    component: EditPropertyLocation,
  },
  {
    path: "/property/edit/description/:propertyID",
    name: "EditPropertyDescription",
    beforeEnter: checkPropertyAdmin,
    component: EditPropertyDescription,
  },
  {
    path: "/property/edit/wifi/:propertyID",
    name: "EditPropertyWifi",
    beforeEnter: checkPropertyAdmin,
    component: EditPropertyWifi,
  },
  {
    path: "/property/edit/accreditations/:propertyID",
    name: "EditPropertyAccreditations",
    beforeEnter: checkPropertyAdmin,
    component: EditPropertyAccreditations,
  },
  {
    path: "/property/edit/photos/:propertyID",
    name: "EditPropertyPhotos",
    beforeEnter: checkPropertyAdmin,
    component: EditPropertyPhotos,
  },
  {
    path: "/property/edit/documents/:propertyID",
    name: "EditPropertyDocuments",
    beforeEnter: checkPropertyAdmin,
    component: EditPropertyDocuments,
  },
  {
    path: "/property/edit/links/:propertyID",
    name: "EditPropertyLinks",
    beforeEnter: checkPropertyAdmin,
    component: EditPropertyLinks,
  },
  {
    path: "/property/edit/bullseye/:propertyID",
    name: "EditPropertyBullseye",
    beforeEnter: checkPropertyAdmin,
    component: EditPropertyBullseye,
  },
  {
    path: "/property/edit/contacts/:propertyID",
    name: "EditPropertyContacts",
    beforeEnter: checkPropertyAdmin,
    component: EditPropertyContacts,
  },
  // {
  //   path: "/property/edit/organisations/:propertyID/:typeID",
  //   name: "EditPropertyOrganisations",
  //   beforeEnter: checkPropertyAdmin,
  //   component: EditPropertyOrganisations,
  // },
  // {
  //   path: "/property/edit/people/:propertyID/:typeID/:organisationID",
  //   name: "EditPropertyPeople",
  //   beforeEnter: checkPropertyAdmin,
  //   component: EditPropertyPeople,
  // },
  // {
  //   path: "/property/edit/person/:propertyID/:typeID/:organisationID/:personID",
  //   name: "EditPropertyPerson",
  //   beforeEnter: checkPropertyAdmin,
  //   component: EditPropertyPerson,
  // },
  {
    path: "/property/edit/floor/:propertyID/:floorID",
    name: "EditPropertyFloor",
    beforeEnter: checkPropertyAdmin,
    component: EditPropertyFloor,
  },
  {
    path: "/property/edit/floor/documents/:propertyID/:floorID",
    name: "EditPropertyFloorDocuments",
    beforeEnter: checkPropertyAdmin,
    component: EditPropertyFloorDocuments,
  },
  {
    path: "/property/edit/space/:propertyID/:floorID/:spaceID",
    name: "EditPropertySpace",
    beforeEnter: checkPropertyAdmin,
    component: EditPropertySpace,
  },
  {
    path: "/property/edit/space/documents/:propertyID/:floorID/:spaceID",
    name: "EditPropertySpaceDocuments",
    beforeEnter: checkPropertyAdmin,
    component: EditPropertySpaceDocuments,
  },
  {
    path: "/property/edit/space/photos/:propertyID/:floorID/:spaceID",
    name: "EditPropertySpacePhotos",
    beforeEnter: checkPropertyAdmin,
    component: EditPropertySpacePhotos,
  },
  {
    path: "/property/edit/space/features/:propertyID/:floorID/:spaceID",
    name: "EditPropertySpaceFeatures",
    beforeEnter: checkPropertyAdmin,
    component: EditPropertySpaceFeatures,
  },
  {
    path: "/property/edit/space/terms/:propertyID/:floorID/:spaceID",
    name: "EditPropertySpaceTerms",
    beforeEnter: checkPropertyAdmin,
    component: EditPropertySpaceTerms,
  },
  {
    path: "/property/edit/floors/:propertyID",
    name: "EditPropertyFloors",
    beforeEnter: checkPropertyAdmin,
    component: EditPropertyFloors,
  },
  {
    path: "/property/edit/qrcode/:propertyID",
    name: "EditPropertyQRCode",
    beforeEnter: checkPropertyAdmin,
    component: EditPropertyQRCode,
  },

  {
    path: "/carrier/edit/:carrierID",
    name: "EditCarrier",
    beforeEnter: checkCarrierAdmin,
    component: EditCarrier,
  },
  //List views
  {
    path: "/contactTypes/list",
    name: "ListContactTypes",
    component: ListContactTypes,
  },
  {
    path: "/property/list",
    name: "ListProperties",
    component: ListProperties,
  },
  {
    path: "/carrier/list",
    name: "ListCarriers",
    component: ListCarriers,
  },
  // Download links
  {
    path: "/download/shortlist/:shortlistID",
    name: "DownloadShortlist",
    component: Download,
  },
  {
    path: "/download/landlord/:landlordID",
    name: "DownloadLandlord",
    component: Download,
  },
  {
    path: "/download/property/:propertyID",
    name: "DownloadProperty",
    component: Download,
  },
  {
    path: "/download/space/:landlordID/:propertyID/:floorID/:spaceID",
    name: "DownloadSpace",
    component: Download,
  },

  // Authentication
  { path: "/login", name: "Login", component: Login },
  {
    path: "/login-with-password",
    name: "LoginWithPassword",
    component: LoginWithPassword,
  },
  { path: "/register", name: "Register", component: Register },
  { path: "/password-reminder", name: "Reminder", component: Reminder },
  { path: "/verify-email", name: "VerifyEmail", component: VerifyEmail },
  { path: "/auth", name: "AuthNoURL", component: Auth },
  { path: "/auth/:url", name: "Auth", component: Auth },
  { path: "/reset-password/:link", name: "ResetPassword", component: ResetPassword },
  { path: "/validate-email/:link", name: "ValidateEmail", component: ValidateEmail },
  { path: "/magic-link/:link", name: "MagicLink", component: MagicLink },

  // Other links
  { path: "/locateme", name: "LocateMe", component: LocateMe },
  { path: "/prices", name: "GetPrices", component: GetPrices },

  { path: "/account", name: "Account", component: Account },
  { path: "/shortlist", name: "Shortlist", component: Shortlist },
  { path: "/recent", name: "Recent", component: Recent },
  { path: "/about", name: "About", component: About },
  { path: "/terms", name: "Terms", component: Terms },
  { path: "/contact", name: "Contact", component: Contact },
  { path: "/noaccess", name: "NoAccess", component: NoAccess },
  { path: "/legal", name: "LegalNotices", component: LegalNotices },
  { path: "/welcome", name: "Onboarding", component: Onboarding },
  { path: "/close-me", name: "CloseMe", component: CloseMe },
  { path: "/upgrade", name: "Upgrade", component: Upgrade },
  { path: "/404", name: "404", component: NotFound },
  { path: "/:pathMatch(.*)*", redirect: "/404" },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// const ensureUserLoaded = () => {
//   return new Promise(waitForUser);
// };

// const waitForUser = (resolve, reject) => {
//   console.log("Checking...");
//   const userStore = useUserStore();
//   const user = userStore.getUser;
//   console.log("1", user);
//   if (!user) {
//     console.log("Waiting for 5...");
//     setTimeout(waitForUser.bind(this, resolve, reject), 5);
//   } else {
//     resolve();
//   }
// };

router.beforeEach(async (to, from) => {
  const userStore = useUserStore();
  const { user } = storeToRefs(userStore);

  // If user hasn't been subscribed, create subscription
  if (!user.value) {
    await userStore.subscribe();
  }

  setScreen(to.name); // Log screen view event to Google Analytics

  //These pages are always allowed
  if (["Home", "AppHome", "Auth", "ValidateEmail", "VerifyEmail", "MagicLink", "ResetPassword", "Terms", "About"].includes(to.name))
    return true;
  if (!user.value) return { name: "Home" };
  // If user has registered but not verified email, redirect them to the verify email page

  if (!user.value?.isAnonymous && !user.value?.emailVerified) return { name: "VerifyEmail" };

  return true;
});

router.beforeResolve((to, from) => {
  if (!checkVersion() && to.name != "Upgrade") return { name: "Upgrade" };
});

export default router;
