<template>
  <ion-page>
    <uncoded-header title="About Online Inside" />

    <ion-content scroll-y="false">
      <div class="noScroll fullheight fullwidth flex vertical">
        <div class="p2 mb2 tc fs scroll">
          <img
            src="/img/logos/logo_text_white.png"
            alt="logo"
            style="max-width: 200px"
          />

          <div class="uppercase">&copy; {{ year }} Uncoded Limited</div>
          <div class="titleFont f2 uppercase mt2">
            A better connectivity experience for the commercial property sector.
          </div>
          <div class="fr tl">
            <p>
              Connectivity &amp; technology in general is, in our opinion,
              unnecessarily confusing &amp; over-complicated. Online Inside is
              on a mission to cut through the noise.
            </p>
            <p>
              As much or as little information as you want, simply presented
              &amp; as it is.
            </p>
            <p>
              An overview of expected Lead times, Wayleave requirements,
              certifications, processes, documents &amp; if you just want to
              talk to someone who can help, then we can give you that option
              too.
            </p>
            <p>
              The Online Inside team have gained their experience from working
              in the commercial property sector for a long time so we understand
              the pain points typically experienced by occupiers, tenants,
              landlords, owners, agents, managing agents &amp; project managers
              (to name a few).
            </p>
          </div>
        </div>

        <div class="actionList fullwidth pb safe-bottom">
          <div class="action" @click="contact()">
            <ion-label> Contact Us</ion-label>
            <ion-icon :icon="chevronForward" />
          </div>
          <div class="action" @click="showNotices()">
            <ion-label> Legal Notices</ion-label>
            <ion-icon :icon="chevronForward" />
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import UncodedHeader from "@/components/UncodedHeader";
import { chevronForward } from "ionicons/icons";
import { useRouter } from "vue-router";

export default {
  components: {
    UncodedHeader,
  },
  setup() {
    const router = useRouter();
    const year = new Date().getFullYear();

    const contact = () => {
      router.push({ name: "Contact" });
    };

    const showNotices = () => {
      router.push({ name: "LegalNotices" });
    };
    return { year, showNotices, contact, chevronForward };
  },
};
</script>

<style></style>
