<template>
  <div class="fullwidth pt2 ph2">
    <div class="flex left cl uppercase titleFont fm">
      <div>{{ property?.title }}</div>
      <ion-icon :icon="shieldCheckmarkOutline" class="ft superscript cp" v-if="property?.verified" />
    </div>
    <div class="flex left cl boldFont uppercase fs">{{ property?.subtitle }}</div>
  </div>
</template>

<script>
import { usePropertyStore } from "@/stores/property";
import { storeToRefs } from "pinia";
import { shieldCheckmarkOutline } from "ionicons/icons";
export default {
  setup() {
    const propertyStore = usePropertyStore();
    const { property } = storeToRefs(propertyStore);
    return { property, shieldCheckmarkOutline };
  },
};
</script>

<style></style>
