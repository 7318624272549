<template>
  <div class="flex bgd pt pb pl2 pr2 fg cl">
    <div class="flex headerButton fg" @click="back">
      <ion-icon :icon="chevronBackCircleOutline" />
    </div>
    <div class="uppercase tc boldFont fs"><slot></slot></div>
    <div class="headerButton"></div>
  </div>
</template>

<script>
import { chevronBackCircleOutline } from "ionicons/icons";
import { star, starOutline } from "ionicons/icons";

export default {
  props: ["isFavourite", "showFavourite"],
  emits: ["back", "favourite"],
  setup(props, { emit }) {
    const back = () => {
      emit("back");
    };

    const toggleFavourite = () => {
      emit("favourite");
    };
    return {
      // variables
      // methods
      back,
      toggleFavourite,
      // icons
      chevronBackCircleOutline,
      star,
      starOutline,
    };
  },
};
</script>

<style scoped>
.headerButton {
  width: 50px;
}
</style>
