<template>
  <ion-page>
    <uncoded-header />

    <ion-content scroll-y="false">
      <div class="fullwidth" v-if="documents && !wait">
        <property-header />

        <nav-header @back="back">Documents</nav-header>
        <ion-accordion-group class="fullwidth">
          <ion-accordion v-for="(docs, type) in groupedDocuments" :key="type">
            <ion-item color="dark" slot="header">
              <ion-label>{{ type }} ({{ docs.length }} document{{ docs.length == 1 ? "" : "s" }})</ion-label>
            </ion-item>
            <div slot="content" class="flex fullwidth p2 wrap centered">
              <div class="flex vertical centered file" v-for="document in docs" :key="document.id" @click="showFile(document)">
                <ion-icon :icon="documentOutline" class="f3" />
                <ion-label class="fr tc uppercase cp boldFont">{{ document.description }}</ion-label>
                <ion-label class="ft tc">{{ document.filename }}</ion-label>
              </div>
            </div>
          </ion-accordion>
          <ion-accordion>
            <ion-item color="dark" slot="header">
              <ion-label>All Documents</ion-label>
              <div class="flex autowidth nogap" slot="end" @click="downloadFiles($event)" v-if="!isPlatform('mobile')">
                <ion-icon :icon="downloadOutline" />
              </div>
            </ion-item>

            <div slot="content" class="flex fullwidth p2 wrap centered">
              <div class="flex vertical centered file" v-for="document in documents" :key="document.id" @click="showFile(document)">
                <ion-icon :icon="documentOutline" class="f3" />
                <ion-label class="fr tc uppercase cp boldFont">{{ document.filename }}</ion-label>
                <ion-label class="ft tc">{{ document.filename }}</ion-label>
              </div>
            </div>
          </ion-accordion>
        </ion-accordion-group>
      </div>
      <wait v-if="wait">Fetching files...</wait>
    </ion-content>
  </ion-page>
</template>

<script>
import { documentOutline, attachOutline, downloadOutline } from "ionicons/icons";
import { computed, ref } from "@vue/runtime-core";
import { useRoute, useRouter } from "vue-router";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { isPlatform, onIonViewWillEnter } from "@ionic/vue";
import UncodedHeader from "@/components/UncodedHeader";
import PropertyHeader from "../common/PropertyHeader";
import { usePropertyStore } from "@/stores/property";
import { storeToRefs } from "pinia";

export default {
  name: "FloorDocuments",
  components: { UncodedHeader, PropertyHeader },
  setup() {
    const wait = ref(false);
    const route = useRoute();
    const router = useRouter();

    const propertyID = route.params.propertyID;
    const floorID = route.params.floorID;
    const spaceID = route.params.spaceID;

    const propertyStore = usePropertyStore();
    const { property, floors, allDocumentTypes } = storeToRefs(propertyStore);

    onIonViewWillEnter(() => {
      propertyStore.setPropertyID(propertyID);
    });

    const documents = computed(() => {
      const space = floors?.value?.find((f) => f.id == floorID).spaces.find((s) => s.id == spaceID);
      return space?.documents;
    });

    const groupedDocuments = computed(() => {
      return groupBy(documents.value, "documentTypeID");
    });

    const back = () => {
      router.back();
    };

    const showFile = (file) => {
      router.push({
        name: "Document",
        params: { propertyID: propertyID, path: file.path },
      });
    };

    const groupBy = (arr, property) => {
      if (!arr) return;
      const result = {};
      const groupNames = [...new Set(arr.map((item) => item[property]))];
      for (const groupName of groupNames) {
        const type = allDocumentTypes.value.find((dt) => dt.id == groupName)?.name;
        result[type] = arr.filter((item) => item[property] == groupName);
      }
      return result;
    };

    const downloadURL = (url) => {
      return new Promise(function (resolve, reject) {
        var xhr = new XMLHttpRequest();
        xhr.open("GET", url, true);
        xhr.responseType = "arraybuffer";
        xhr.onreadystatechange = function (evt) {
          if (xhr.readyState === 4) {
            if (xhr.status === 200) {
              resolve(xhr.response);
            } else {
              reject(new Error("Error for " + url + ": " + xhr.status));
            }
          }
        };
        xhr.send();
      });
    };

    const downloadFiles = async (e) => {
      e.cancelBubble = true;
      wait.value = true;
      const zip = new JSZip();
      for (const type in groupedDocuments.value) {
        for (const file of groupedDocuments.value[type]) {
          console.log(file);
          const fileBlob = await downloadURL(file.url);
          zip
            .folder(`${property.value?.title} documents` || "Online Inside")
            .folder(type)
            .file(file.filename, fileBlob);
        }
      }

      const content = await zip.generateAsync({ type: "blob" });

      const zipName = `${property.value?.title} documents` || "Online Inside";
      saveAs(content, zipName);
      wait.value = false;
    };

    return {
      // variables
      property,
      documents,
      groupedDocuments,
      wait,
      // methods
      isPlatform,
      downloadFiles,
      back,
      showFile,
      //icons
      documentOutline,
      attachOutline,
      downloadOutline,
    };
  },
};
</script>

<style scoped>
.file {
  width: 150px;
}
</style>
