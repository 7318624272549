import { defineStore } from "pinia";
import { sortUnique, sortAlphabetically } from "../composables/utilities";
import { getFirebaseDocument } from "@/composables/firebase";
import { logPropertySelection } from "@/composables/analytics";
import { useUserStore } from "@/stores/user";
import { reIndex } from "@/composables/utilities";

import { getURL, subscribeDocument, getFirebaseCollection } from "@/composables/firebase";
import { getFirstPhoto } from "@/composables/utilities";
import {
  createFirebaseDocument,
  deleteFirebaseDocument,
  deleteFirebaseFile,
  getShortcode,
  runFunction,
  subscribeCollection,
  updateFirebaseDocument,
  uploadFirebaseFile,
} from "../composables/firebase";
let propertySnapshot;

export const usePropertyStore = defineStore("propertyStore", {
  state: () => ({
    _allDocumentTypes: [],
    _allAccreditations: [],
    _allCarriers: [],
    _allShortcodes: [],
    _allContactTypes: [],
    __allAccreditationsUpdated: null,
    __allDocumentTypesUpdated: null,

    _properties: [],
    _floors: [],

    _propertyID: null,
    _floorID: null,
    _carrierID: null,
    _propertySnapshot: null,

    _propertyPricing: {},
    _propertyContactTypes: [],
  }),
  getters: {
    //Global getters
    allProperties: (state) => sortAlphabetically(state._properties, "title"),
    allCarriers: (state) => state._allCarriers,
    allContactTypes: (state) => state._allContactTypes,
    allAccreditations: (state) => state._allAccreditations,
    allDocumentTypes: (state) => state._allDocumentTypes,

    //Property specific getters
    propertyID: (state) => state._propertyID,
    floorID: (state) => state._floorID,
    property: (state) => state._properties.find((property) => property.id == state._propertyID),
    floors() {
      return this.property?.floors;
    },
    propertyCarriers: (state) => state._allCarriers.filter((c) => state.property?.carrierIDs?.includes(c.id)),
    propertyFloors: (state) => state.property?.floors,
    floorSpaces: (state) => state.floor?.spaces,

    propertyContacts(state) {
      const property = state._properties?.find((p) => p.id == this._propertyID);
      if (!property?.contacts) return;

      const data = property.contacts;
      let result = JSON.parse(JSON.stringify(state._propertyContactTypes));

      const flatmap = [];

      for (const typeID in data) {
        for (const organisationID in data[typeID]) {
          for (const contactID of data[typeID][organisationID]) {
            flatmap.push({
              typeID,
              organisationID,
              contactID,
            });
          }
        }
      }

      for (const type of result) {
        for (const organisation of type.organisations) {
          if (organisation.contacts)
            organisation.contacts = organisation.contacts.filter((c) => {
              return flatmap.find((f) => {
                return f.contactID == c.id && f.organisationID == organisation.id && f.typeID == type.id;
              });
            });
        }
        type.organisations = type.organisations.filter((o) => o.contacts?.length > 0);
      }
      result = result.filter((t) => t.organisations.length > 0);
      return result;
    },
    propertyAccreditations() {
      const result = {};
      if (!this.property?.accreditations || this.property.accreditations.length == 0) return result;
      for (const accreditation of this.property?.accreditations) {
        const referenceAccreditation = this.allAccreditations.find((a) => a.id == accreditation.id);
        const referenceLevel = referenceAccreditation?.levels.find((l) => l.id == accreditation.levelID);
        if (referenceLevel) {
          result[accreditation.id] = { id: referenceLevel.id, name: referenceLevel.name, url: referenceLevel.url };
        }
      }
      return result;
    },
    propertyDocumentTypes: (state) => state._allDocumentTypes.filter((dt) => dt.scope?.includes("property")),
    floorDocumentTypes: (state) => state._allDocumentTypes.filter((dt) => dt.scope?.includes("floor")),
    spaceDocumentTypes: (state) => state._allDocumentTypes.filter((dt) => dt.scope?.includes("space")),

    floor: (state) => state._floors.find((floor) => floor.id == state._floorID),
    carrier: (state) => state._allCarriers.find((c) => c.id == state._carrierID),

    //Pricing getters
    prices: (state) => state._propertyPricing?.prices,
    bearerSizes: (state) => state._propertyPricing?.bearerSizes,
    bandwidths: (state) => state._propertyPricing?.bandwidths,
    terms: (state) => state._propertyPricing?.terms,
    shortcodes: (state) => state._allShortcodes,
    priceDate: (state) =>
      state._propertyPricing?.validDate?.toDate().toLocaleString("en-GB", {
        day: "numeric",
        month: "long",
        hour: "numeric",
        minute: "2-digit",
      }),
  },
  actions: {
    async setPropertyID(id) {
      if (id == this._propertyID) return;
      const userStore = useUserStore();
      this._propertyID = id;
      // Add property to loaded properties if it hasn't already been loaded
      if (!this.property) {
        this._properties.push(await getFirebaseDocument("properties", id));
        this.property.featurePhoto = await getFirstPhoto(this.property);
      }

      if (this.property?.managingEntity) {
        const path = `contactTypes/${this.property.managingEntity.typeID}/organisations`;
        subscribeDocument(path, this.property.managingEntity.organisationID, async (data) => {
          this.property.logo = data.path ? await getURL(data.path) : null;
        });
      }

      if (!this.property?.isLoaded) {
        if (!this.property.location) this.property.location = {};

        // Get photo url for display in backgrounds and thumbnails
        this.property.featurePhoto = await getFirstPhoto(this.property);

        this.property.floors = await getFirebaseCollection(`properties/${this.propertyID}/floors`);
        reIndex(this.property.floors);

        // Subscribe to price data for changes
        subscribeDocument(`properties/${id}/pricing`, "current", async (data) => {
          const pricingCarriers = await getFirebaseCollection(`properties/${id}/pricing/current/carriers`);

          let allPrices = [];
          pricingCarriers.map((carrier) => {
            allPrices = allPrices.concat(carrier.prices);
            allPrices.sort((a, b) => {
              return a.monthly_cost - b.monthly_cost;
            });
          });

          this._propertyPricing = {
            ...data,
            prices: allPrices,
            bandwidths: sortUnique(allPrices, "bandwidth", true),
            types: sortUnique(allPrices, "type"),
            bearerSizes: sortUnique(allPrices, "bearer", true),
            terms: sortUnique(allPrices, "term", true),
          };
        });

        // Set bullseye scores for property
        this.property.bullseyeScores = [];
        this.property.bullseyeScores.push(
          this.property.leadTime < 5 ? 4 : this.property.leadTime < 30 ? 3 : this.property.leadTime < 60 ? 2 : 1
        );
        this.property.bullseyeScores.push(this.property.risers > 2 ? 4 : this.property.risers == 2 ? 3 : this.property.risers > 0 ? 2 : 1);
        this.property.bullseyeScores.push(
          this.property?.carrierIDs?.length > 3
            ? 4
            : this.property?.carrierIDs?.length == 3
            ? 3
            : this.property?.carrierIDs?.length == 2
            ? 2
            : 1
        );
        this.property.bullseyeScores.push(
          this.property.entryPoints > 2 ? 4 : this.property.entryPoints == 2 ? 3 : this.property.entryPoints > 0 ? 2 : 1
        );

        //Set highlight details for property
        this.property.highlights = [];
        this.property.highlights.push({
          title: "Lead Time",
          longTitle: "Lead Time",
          value: this.property.leadTime || "N/A",
          units: this.property.leadTime ? "Days" : "",
        });
        this.property.highlights.push({
          title: "Risers",
          longTitle: "Risers",
          value: this.property.risers || "N/A",
          units: "",
        });
        this.property.highlights.push({
          title: "Providers",
          longTitle: "Service Providers",
          value: this.property?.carrierIDs?.length || 0,
          units: "",
        });
        this.property.highlights.push({
          title: "Entry",
          longTitle: "Entry Points",
          value: this.property.entryPoints || "N/A",
          units: "",
        });

        // Set up metrics for bullseye
        this.property.metrics = [
          {
            value: this.property.leadTime,
            description: this.property.leadTimeDescription,
          },
          {
            value: this.property.risers,
            description: this.property.risersDescription,
          },
          {
            value: this.property?.carrierIDs?.length || 0,
            description: this.property.carriersDescription,
          },
          {
            value: this.property.entryPoints,
            description: this.property.entryPointsDescription,
          },
        ];

        this.property.isLoaded = true;
      }

      this.fetchPropertySpaces();
      this.fetchPropertyContacts();

      // Get current pricing data and if >60 minutes have elasped since last price update, get new prices
      const currentDoc = await getFirebaseDocument(`properties/{}/pricing`, "current");
      let priceAgeMins = -1;
      if (currentDoc) {
        const validDate = this._propertyPricing?.validDate?.toDate();
        priceAgeMins = Math.floor(Math.abs(now - validDate) / (1000 * 60));
      }

      if ((priceAgeMins > 60 || priceAgeMins < 0) && this.property) {
        runFunction("getPrices", this.property);
      }

      // Add property id to recent properties if it isn't already in list
      userStore.addRecentProperty(id);

      // Log property view with google analytics
      logPropertySelection(this._property?.title);
    },

    async setFloorID(id) {
      if (id == this._floorID) return;
      this._floorID = id;
      const floor = await getFirebaseDocument(`properties/${this.propertyID}/floors`, this.floorID);
      floor.spaces = await getFirebaseCollection(`properties/${this.propertyID}/floors/${this.floorID}/spaces`);
      this._floors.push(floor);
    },

    async fetchAllDocumentTypes(forceUpdate = false) {
      if (!!this.__allDocumentTypesUpdated && !forceUpdate) return;
      this.__allDocumentTypesUpdated = new Date();
      this._allDocumentTypes = await getFirebaseCollection("documentTypes");
    },

    async fetchAllAccreditations(forceUpdate = false) {
      if (!!this.__allAccreditationsUpdated && !forceUpdate) return;
      this.__allAccreditationsUpdated = new Date();

      const accreditations = await getFirebaseCollection("accreditations");
      for (const accreditation of accreditations) {
        accreditation.levels = await getFirebaseCollection(`accreditations/${accreditation.id}/levels`);

        for (const level of accreditation.levels) {
          level.url = await getURL(level.path);
        }
      }

      this._allAccreditations = accreditations;
    },

    async fetchAllCarriers() {
      subscribeCollection("carriers", (carriers) => {
        this._allCarriers = [];
        carriers.forEach(async (carrier) => {
          carrier.logo = carrier.path ? await getURL(carrier.path) : "/img/placeholders/transparent.png";
          this._allCarriers.push(carrier);
        });
      });
    },

    async fetchAllShortcodes() {
      subscribeCollection("shortcodes", (shortcodes) => {
        this._allShortcodes = shortcodes;
      });
    },

    async fetchPropertySkeletons() {
      const properties = await getFirebaseCollection("properties");
      const existingPropertyIDs = this._properties.map((p) => p.id);
      properties.forEach(async (property) => {
        if (!existingPropertyIDs.includes(property.id)) {
          property.isLoaded = false;
          property.featurePhoto = await getFirstPhoto(property);
          this._properties.push(property);
        }
      });
    },

    async fetchPropertySpaces() {
      this.property.floors = await getFirebaseCollection(`properties/${this._propertyID}/floors`);
      this.property.floors.sort((a, b) => a.index - b.index);
      this.property.floors.forEach(async (floor) => {
        floor.spaces = await getFirebaseCollection(`properties/${this._propertyID}/floors/${floor.id}/spaces`);
        floor.availableSpaces = floor.spaces.filter((s) => s.availability == "Available");
        floor.spaces.forEach(async (space) => {
          space.featurePhoto = await getFirstPhoto(space);
          space.photos?.forEach(async (photo) => {
            photo.url = await getURL(photo.path);
          });
        });
      });
    },

    async fetchPropertyContacts() {
      const data = this.property?.contacts;
      const result = JSON.parse(JSON.stringify(this._allContactTypes));
      if (!data) return;

      for (const contactTypeID in data) {
        let contactType = result.find((ct) => ct.id == contactTypeID);

        if (!contactType) {
          // Contact type hasn't been loaded yet
          const newContactType = await getFirebaseDocument("contactTypes", contactTypeID);
          if (!newContactType) return;
          const newLength = result.push({
            ...newContactType,
            organisations: [],
          });

          contactType = result[newLength - 1];
        }

        for (const organisationID in data[contactTypeID]) {
          let organisation = contactType.organisations?.find((o) => o.id == organisationID);
          if (!organisation) {
            const newOrganisation = await getFirebaseDocument(`contactTypes/${contactTypeID}/organisations`, organisationID);
            if (!newOrganisation) return;
            const newLength = contactType.organisations.push({
              ...newOrganisation,
              contacts: [],
            });
            organisation = contactType.organisations[newLength - 1];
          }

          for (const contactID of data[contactTypeID][organisationID]) {
            let contact = organisation.contacts.find((c) => c.id == contactID);
            if (!contact) {
              const newContact = await getFirebaseDocument(
                `contactTypes/${contactTypeID}/organisations/${organisationID}/contacts`,
                contactID
              );
              if (!newContact) return;
              newContact.url = "/img/placeholders/portrait.png";
              if (newContact.path) newContact.url = await getURL(newContact.path);
              const newLength = organisation.contacts.push(newContact);
              contact = organisation.contacts[newLength - 1];
            }
          }
        }
      }
      this._propertyContactTypes = JSON.parse(JSON.stringify(result));
    },

    async setCarrierID(id) {
      if (id == this._carrierID) return;
      this._carrierID = id;
    },

    async revertProperty() {
      const dbProperty = await getFirebaseDocument("properties", this.propertyID);
      for (const value in this.property) {
        if (!dbProperty[value]) delete this.property[value];
      }
      for (const value in dbProperty) {
        this.property[value] = dbProperty[value];
      }
    },

    async reloadProperty() {
      await this.setPropertyID(this._propertyID, true);
    },

    async createProperty() {
      return new Promise(async (resolve) => {
        const newDoc = await createFirebaseDocument("properties", {
          title: "New Property",
          shortcode: getShortcode(),
        });
        resolve(newDoc.id);
      });
    },

    async saveProperty(originalShortcode) {
      console.log(this.property);
      const data = { ...this.property };
      delete data.featurePhoto;
      delete data.id;
      delete data.bullseyeScores;
      delete data.floors;
      delete data.highlights;
      delete data.metrics;
      delete data.isLoaded;
      await updateFirebaseDocument("properties", this.propertyID, data, false);
      if (originalShortcode && originalShortcode != data.shortcode) {
        await deleteFirebaseDocument("shortcodes", originalShortcode);
        await updateFirebaseDocument("shortcodes", data.shortcode, {
          type: "property",
          propertyID: this.propertyID,
        });
      }
    },

    async createCarrier() {
      return new Promise(async (resolve) => {
        const newDoc = await createFirebaseDocument("carriers", {
          name: "New Carrier",
        });
        resolve(newDoc.id);
      });
    },

    async deleteCarrierLogo() {
      return new Promise(async (resolve) => {
        await deleteFirebaseFile(`Carriers/${this._carrierID}`);
        this.carrier.logo = "/img/placeholders/transparent.png";
        delete this.carrier.path;
        await this.saveCarrier();
        resolve();
      });
    },

    async saveCarrier() {
      const data = { ...this.carrier };
      delete data.logo;
      delete data.id;
      delete data.imageLoaded;
      await updateFirebaseDocument("carriers", this._carrierID, data, false);
    },

    async deleteCarrier() {
      console.log("TODO = DELETE CARRIER");
    },

    async addCarrierLogo(file) {
      const result = await uploadFirebaseFile("Carriers", this._carrierID, file);
      this.carrier.path = result.metadata.fullPath;
      this.carrier.logo = result.url;
      this.saveCarrier();
    },

    async fetchAllContactTypes(forceUpdate = false) {
      subscribeCollection("contactTypes", async (contactTypes) => {
        contactTypes.sort((a, b) => {
          if (a.name == b.name) return 0;
          return a.name < b.name ? -1 : 1;
        });

        for (const contactType of contactTypes) {
          contactType.organisations = await getFirebaseCollection(`contactTypes/${contactType.id}/organisations`);
          contactType.organisations.sort((a, b) => {
            if (a.name == b.name) return 0;
            return a.name < b.name ? -1 : 1;
          });
          for (const organisation of contactType.organisations) {
            if (organisation.path) organisation.logo = await getURL(organisation.path);
            organisation.contacts = await getFirebaseCollection(`contactTypes/${contactType.id}/organisations/${organisation.id}/contacts`);
            for (const contact of organisation.contacts) {
              if (contact.path) contact.url = await getURL(contact.path);
            }
            organisation.contacts.sort((a, b) => {
              if (a.lastname == b.lastname) return 0;
              return a.lastname < b.lastname ? -1 : 1;
            });
          }
        }
        this._allContactTypes = contactTypes;
      });
    },

    async createContactType() {
      return new Promise(async (resolve) => {
        const newDoc = await createFirebaseDocument("contactTypes", {
          name: "",
        });
        resolve(newDoc.id);
      });
    },

    async saveContactType(id) {
      const contactType = this.allContactTypes.find((ct) => ct.id == id);
      if (contactType) {
        const data = { ...contactType };
        delete data.organisations;
        updateFirebaseDocument(`contactTypes`, id, data);
      }
    },

    setPropertyAccreditationLevel(accreditation, level) {
      const record = this.property?.accreditations?.find((a) => a.id == accreditation?.id);
      if (record) {
        record.levelID = level.id;
      } else {
        if (!this.property.accreditations) this.property.accreditations = [];
        this.property.accreditations.push({ id: accreditation.id, levelID: level.id });
      }
    },

    async setPropertyAccreditationCertificate(accreditation, result) {
      const record = this.property?.accreditations?.find((a) => a.id == accreditation?.id);
      record.certificate = {
        filename: result.ref.name,
        path: result.ref.fullPath,
        description: result.ref.name,
      };
      await this.saveProperty();
    },

    deletePropertyAccreditationLevel(accreditation) {
      const index = this.property.accreditations.findIndex((a) => a.id == accreditation.id);
      this.property.accreditations.splice(index, 1);
    },

    async createOrganisation(contactTypeID) {
      return new Promise(async (resolve) => {
        const contactType = this.allContactTypes.find((ct) => ct.id == contactTypeID);
        const newDoc = await createFirebaseDocument(`contactTypes/${contactTypeID}/organisations`, {
          name: "New Organisation",
        });
        contactType.organisations.push({
          id: newDoc.id,
          name: "New Organisation",
        });
        contactType.organisations.sort((a, b) => {
          if (a.name == b.name) return 0;
          return a.name < b.name ? -1 : 1;
        });
        resolve(newDoc.id);
      });
    },

    async saveOrganisation(contactTypeID, organisationID) {
      const contactType = this.allContactTypes.find((ct) => ct.id == contactTypeID);
      const organisation = contactType.organisations.find((o) => o.id == organisationID);
      if (organisation) {
        const data = { ...organisation };
        delete data.contacts;
        delete data.logo;
        updateFirebaseDocument(`contactTypes/${contactTypeID}/organisations`, organisationID, data, false);
      }
    },

    async addOrganisationLogo(contactTypeID, organisationID, file) {
      const result = await uploadFirebaseFile(`ContactTypes/${contactTypeID}/Organisations/${organisationID}/`, "logo.png", file);
      const contactType = this._allContactTypes.find((ct) => ct.id == contactTypeID);
      const organisation = contactType.organisations.find((o) => o.id == organisationID);
      organisation.path = result.metadata.fullPath;
      organisation.logo = result.url;
      this.saveOrganisation(contactTypeID, organisationID);
    },

    async deleteOrganisationLogo(contactTypeID, organisationID) {
      return new Promise(async (resolve) => {
        const contactType = this._allContactTypes.find((ct) => ct.id == contactTypeID);
        const organisation = contactType.organisations.find((o) => o.id == organisationID);
        await deleteFirebaseFile(organisation.path);
        organisation.logo = "/img/placeholders/transparent.png";
        delete organisation.path;
        await this.saveOrganisation(contactTypeID, organisationID);
        resolve();
      });
    },

    async createContact(contactTypeID, organisationID) {
      return new Promise(async (resolve) => {
        const contactType = this.allContactTypes.find((ct) => ct.id == contactTypeID);
        const organisation = contactType.organisations.find((o) => o.id == organisationID);

        const newDoc = await createFirebaseDocument(`contactTypes/${contactTypeID}/organisations/${organisationID}/contacts`, {
          firstname: "New Contact",
          lastname: "",
          phone: {},
        });
        organisation.contacts.push({
          id: newDoc.id,
          firstname: "",
          lastname: "",
        });
        organisation.contacts.sort((a, b) => {
          if (a.name == b.name) return 0;
          return a.name < b.name ? -1 : 1;
        });
        resolve(newDoc.id);
      });
    },

    async saveContact(contactTypeID, organisationID, contactID) {
      const contactType = this.allContactTypes.find((ct) => ct.id == contactTypeID);
      const organisation = contactType.organisations.find((o) => o.id == organisationID);
      const contact = organisation.contacts.find((c) => c.id == contactID);

      if (contact) {
        const data = { ...contact };
        delete data.photo;
        updateFirebaseDocument(`contactTypes/${contactTypeID}/organisations/${organisationID}/contacts`, contactID, data, false);
      }
    },

    async addContactPhoto(contactTypeID, organisationID, contactID, file) {
      const result = await uploadFirebaseFile(
        `ContactTypes/${contactTypeID}/Organisations/${organisationID}/Contacts/${contactID}`,
        "photo.png",
        file
      );
      const contactType = this._allContactTypes.find((ct) => ct.id == contactTypeID);
      const organisation = contactType.organisations.find((o) => o.id == organisationID);
      const contact = organisation.contacts.find((c) => c.id == contactID);
      contact.path = result.metadata.fullPath;
      contact.photo = result.url;
      this.saveContact(contactTypeID, organisationID, contactID);
    },

    async deleteContactPhoto(contactTypeID, organisationID, contactID) {
      return new Promise(async (resolve) => {
        const contactType = this._allContactTypes.find((ct) => ct.id == contactTypeID);
        const organisation = contactType.organisations.find((o) => o.id == organisationID);
        const contact = organisation.contacts.find((c) => c.id == contactID);
        await deleteFirebaseFile(contact.path);
        contact.photo = "/img/placeholders/portrait.png";
        delete contact.path;
        await this.saveContact(contactTypeID, organisationID, contactID);
        resolve();
      });
    },

    async addPropertyPhoto(file) {
      const result = await uploadFirebaseFile(`Properties/${this.property.title}/photos`, file.name, file);

      const record = {
        name: result.ref.name,
        path: result.ref.fullPath,
        url: result.url,
        index: this.property.photos.length,
      };
      this.property.photos.push(record);
      return record;
    },

    async addFloorDocument(file) {
      const result = await uploadFirebaseFile(`Properties/${this.property.title}/Floors/${this.floor.name}/Documents`, file.name, file);
      const record = {
        filename: result.ref.name,
        path: result.ref.fullPath,
        description: result.ref.name,
      };
      if (!this.floor.documents) this.floor.documents = [];
      this.floor.documents.push(record);
      this.saveFloor(this.floor);
    },

    async deletePropertyPhoto(photo) {
      this.property.photos = this.property.photos.filter((item) => item != photo);
      this.property.photos.forEach((item) => {
        if (item.index > photo.index) item.index = item.index - 1;
      });
      deleteFirebaseFile(photo.path);
    },

    async deleteContact(contactTypeID, organisationID, contactID) {
      console.log("TODO = DELETE CONTACT");
    },

    async deleteContactType(id) {
      console.log("TODO = DELETE CONTACT TYPE");
    },

    async deleteProperty() {
      const userStore = useUserStore();
      return new Promise(async (resolve) => {
        try {
          //Delete photos stored in Firebase storage
          this.property.documents?.forEach(async (document) => {
            await deleteFirebaseFile(document.path);
          });
          //Delete photos stored in Firebase storage
          this.property.photos?.forEach(async (photo) => {
            await deleteFirebaseFile(photo.path);
          });
        } catch (error) {
          console.log("Error deleting property", error.localizedDescription);
        }

        this.property.floors.forEach(async (floor) => {
          floor.spaces.forEach(async (space) => {
            try {
              //Delete space document
              await deleteFirebaseDocument(`properties/${this._propertyID}/floors/${floor.id}/spaces`, space.id);
              //Delete photos stored in Firebase storage
              space.documents?.forEach(async (document) => {
                await deleteFirebaseFile(document.path);
              });
              //Delete photos stored in Firebase storage
              space.photos?.forEach(async (photo) => {
                await deleteFirebaseFile(photo.path);
              });
            } catch (error) {
              console.log("Error deleting space", error.localizedDescription);
            }
          });

          try {
            //Delete space document
            await deleteFirebaseDocument(`properties/${this._propertyID}/floors`, floor.id);
            //Delete photos stored in Firebase storage
            floor.documents?.forEach(async (document) => {
              await deleteFirebaseFile(document.path);
            });
            //Delete photos stored in Firebase storage
            floor.photos?.forEach(async (photo) => {
              await deleteFirebaseFile(photo.path);
            });
          } catch (error) {
            console.log("Error deleting floor", error.localizedDescription);
          }
        });
        await deleteFirebaseDocument("shortcodes", this.property.shortcode);

        await deleteFirebaseDocument("properties", this.propertyID);

        const propertyIndex = this._properties.findIndex((p) => p.id == this._propertyID);
        if (propertyIndex != -1) {
          this._properties.splice(propertyIndex, 1);
        }

        userStore.removeRecentProperty(this._propertyID);
        this._propertyID = null;
        resolve();
      });
    },

    async removeCarrier(id) {
      const property = this._properties.find((p) => p.id == this._propertyID);
      const index = property?.carrierIDs.findIndex((cid) => cid == id);
      property?.carrierIDs.splice(index, 1);
    },

    async addCarrier(id) {
      if (!this.property?.carrierIDs) this.property.carrierIDs = [];
      this.property?.carrierIDs.push(id);
    },

    async saveFloors() {
      this.propertyFloors.forEach(async (floor) => {
        await this.saveFloor(floor);
      });
    },

    async saveFloor(floor) {
      const data = { ...floor };
      delete data.spaces;
      delete data.availableSpaces;
      await updateFirebaseDocument(`properties/${this.propertyID}/floors/`, floor.id, data);
    },

    async restoreFloors() {
      this.property.floors = await getFirebaseCollection(`properties/${this.propertyID}/floors`);
      reIndex(this.property.floors);
    },

    async createFloor() {
      return new Promise(async (resolve) => {
        const index = Math.max(...this.propertyFloors.map((f) => f.index)) || 0;
        const newDoc = await createFirebaseDocument(`properties/${this.propertyID}/floors`, {
          name: "New Floor",
          index: index + 1,
        });
        this.propertyFloors.push({ id: newDoc.id, name: "New Floor", index: index });
        resolve(newDoc);
      });
    },

    async deleteFloor() {
      return new Promise(async (resolve) => {
        const spaces = await getFirebaseCollection(`properties/${this.propertyID}/floors/${this.floorID}/spaces`);

        spaces.forEach(async (space) => {
          try {
            //Delete space document
            await deleteFirebaseDocument(`properties/${this.propertyID}/floors/${this.floorID}/spaces`, space.id);
            //Delete photos stored in Firebase storage
            space.photos?.forEach(async (photo) => {
              await deleteFirebaseFile(photo.path);
            });
          } catch (error) {
            console.log("Error deleting space", error.localizedDescription);
          }
        });

        const index = this.propertyFloors.findIndex((f) => f.id == this.floorID);
        this.propertyFloors.splice(index, 1);
        await deleteFirebaseDocument(`properties/${this.propertyID}/floors`, this.floorID);
        resolve();
      });
    },

    async restoreFloor() {
      console.log("TODO");
    },

    async createSpace() {
      return new Promise(async (resolve) => {
        const newDoc = await createFirebaseDocument(`properties/${this.propertyID}/floors/${this.floorID}/spaces`, {
          title: "New Space",
          availability: "Leased",
        });
        this.floorSpaces.push({ id: newDoc.id, title: "New Space", availability: "Leased" });
        resolve(newDoc);
      });
    },

    async saveSpace(id) {
      const data = this.floor.spaces.find((s) => s.id == id);
      if (!data) return;
      await updateFirebaseDocument(`properties/${this.propertyID}/floors/${this.floorID}/spaces`, id, data);
    },

    async deleteSpace(space) {
      await deleteFirebaseDocument(`properties/${this.propertyID}/floors/${this.floorID}/spaces`, space.id);
      //Delete photos stored in Firebase storage
      space.photos?.forEach(async (photo) => {
        await deleteFirebaseFile(photo.path);
      });
      const index = this.floor.spaces.findIndex((s) => s.id == space.id);
      this.floor.spaces.splice(index, 1);
    },

    async addSpaceDocument(space, file) {
      const result = await uploadFirebaseFile(
        `Properties/${this.property.title}/Floors/${this.floor.name}/Spaces/${space.title}/Documents`,
        file.name,
        file
      );
      const record = {
        filename: result.ref.name,
        path: result.ref.fullPath,
        description: result.ref.name,
      };
      if (!space.documents) space.documents = [];
      space.documents.push(record);
      this.saveFloor(this.floor);
    },

    async deleteSpaceDocument(space, document) {
      space.documents = space.documents.filter((item) => item != document);
      deleteFirebaseFile(document.path);
      this.saveSpace(space.id);
    },

    async deleteFloorDocument(document) {
      this.floor.documents = this.floor.documents.filter((item) => item != document);
      deleteFirebaseFile(document.path);
      this.saveFloor(this.floor);
    },

    async addSpacePhoto(space, file) {
      if (!space.photos) space.photos = [];
      const result = await uploadFirebaseFile(
        `Properties/${this.property.title}/Floors/${this.floor.name}/Spaces/${space.title}/Photos`,
        file.name,
        file
      );

      const record = {
        name: result.ref.name,
        path: result.ref.fullPath,
        url: result.url,
        index: space.photos.length,
      };
      space.photos.push(record);
      this.saveSpace(space.id);
    },

    async deleteSpacePhoto(space, photo) {
      space.photos = space.photos.filter((item) => item != photo);
      space.photos.forEach((item) => {
        if (item.index > photo.index) item.index = item.index - 1;
      });
      deleteFirebaseFile(photo.path);
      this.saveSpace(space.id);
    },

    addContact(typeID, organisationID, contactID) {
      if (!this.property.contacts) this.property.contacts = {};
      if (!this.property.contacts[typeID]) this.property.contacts[typeID] = {};
      if (!this.property.contacts[typeID][organisationID]) this.property.contacts[typeID][organisationID] = [];
      this.property.contacts[typeID][organisationID].push(contactID);
    },

    removeContact(typeID, organisationID, contactID) {
      const types = this.property?.contacts;
      const type = types[typeID];
      const organisation = type[organisationID];
      const contactIndex = organisation.findIndex((c) => c == contactID);
      organisation.splice(contactIndex, 1);
      if (organisation.length == 0) delete type[organisationID];
      if (Object.keys(types).length == 0) delete types[typeID];
    },

    // updateProperty(data) {
    //   //Remove exiting property record
    //   const index = this._properties.findIndex((p) => p.id == this._propertyID);
    //   this._properties.splice(index, 1);
    //   //Recreate it with new data
    //   this._properties.push(data);
    // },

    initialise() {
      this.fetchAllAccreditations();
      this.fetchAllCarriers();
      this.fetchAllDocumentTypes();
      this.fetchAllContactTypes();
      this.fetchAllShortcodes();
    },
  },
});
