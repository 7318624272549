<template>
  <ion-page>
    <uncoded-header />

    <ion-content scroll-y="false" v-if="property">
      <div class="flex vertical fullwidth left mt2">
        <property-header />

        <nav-header @back="back">Terms &amp; Conditions</nav-header>

        <ion-item v-for="(term, index) in terms" :key="index" class="fullwidth mb2">
          <div class="flex vertical top">
            <div class="boldFont">{{ term.label }}</div>
            <div>{{ term.value }}</div>
          </div>
        </ion-item>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { computed } from "@vue/runtime-core";
import { useRoute, useRouter } from "vue-router";
import UncodedHeader from "@/components/UncodedHeader";
import PropertyHeader from "../common/PropertyHeader";
import { usePropertyStore } from "@/stores/property";
import { storeToRefs } from "pinia";
import { onIonViewWillEnter } from "@ionic/vue";

export default {
  components: { UncodedHeader, PropertyHeader },
  setup() {
    const router = useRouter();
    const route = useRoute();

    const propertyID = route.params.propertyID;
    const floorID = route.params.floorID;
    const spaceID = route.params.spaceID;

    const propertyStore = usePropertyStore();
    const { property, floors } = storeToRefs(propertyStore);

    onIonViewWillEnter(() => {
      propertyStore.setPropertyID(propertyID);
    });

    const terms = computed(() => floors.value?.find((f) => f.id == floorID).spaces.find((s) => s.id == spaceID).terms);

    const back = () => {
      router.back();
    };

    return {
      terms,
      property,
      back,
    };
  },
};
</script>
