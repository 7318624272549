import { toastController } from "@ionic/vue";
import { getURL } from "./firebase";

export const routeTo = (router, type, ids) => {
  const path = `/${type}/view/${ids.join("/")}`;
  router.push(path);
};

export const routeToEdit = (router, type, ids) => {
  const path = `/${type}/edit/${ids.join("/")}`;
  router.push(path);
};

export const routeToDelete = (router, type, ids) => {
  const path = `/${type}/delete/${ids.join("/")}`;
  router.push(path);
};

export const precacheImage = (url) => {
  if (!url) return;
  try {
    const img = new Image();
    img.src = url;
  } catch (error) {
    console.log("Could not precache image");
  }
};

export const getFirstPhoto = async (record) => {
  try {
    const photoArray = record?.photos;
    if (!photoArray || photoArray.length == 0) return "/img/placeholders/transparent.png";
    photoArray.sort((a, b) => (a.index > b.index ? 1 : -1));
    const path = photoArray[0]?.path;
    return await getURL(path);
  } catch (error) {
    console.log(error, record);
    return "/img/placeholders/transparent.png";
  }
};

export const boundValue = (value, min, max) => {
  return Math.max(min, Math.min(max, value));
};

export const reIndex = (array) => {
  //First sort in existing index order
  if (array && array.length > 0) {
    array.sort((a, b) => (a.index > b.index ? 1 : -1));
    //Then re-base from 0
    array.forEach((item, index) => {
      item.index = index;
    });
  }
};

export const formatBandwidth = (bandwidth) => {
  return bandwidth < 1000 ? `${bandwidth}MB` : `${bandwidth / 1000}GB`;
};

export const scrollTo = (page) => {
  page.scrollIntoView({
    block: "start",
    behavior: "smooth",
  });
};

export const toast = async (message, duration = 2000) => {
  const toast = await toastController.create({
    message: message,
    duration: duration,
  });
  toast.present();
};

export const getAddress = (from, multiline = true) => {
  const addressComponents = [];
  const components = ["address1", "address2", "address3", "town", "county", "postcode"];

  components.forEach((component) => {
    if (component in from && from[component] != "") {
      addressComponents.push(from[component]);
    }
  });
  return addressComponents.join(multiline ? "<br />" : ", ");
};

export const formatDate = (date, style = "long") => {
  const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const shortMonths = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  switch (style) {
    case "short":
      return `${date.getDate()} ${shortMonths[date.getMonth()]} ${date.getFullYear()}`;
    default:
      return `${days[date.getDay()]} ${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}`;
  }
};

export const haversine = (lat1, lng1, lat2, lng2) => {
  const R = 6371e3; // metres
  const φ1 = (lat1 * Math.PI) / 180; // φ, λ in radians
  const φ2 = (lat2 * Math.PI) / 180;
  const Δφ = ((lat2 - lat1) * Math.PI) / 180;
  const Δλ = ((lng2 - lng1) * Math.PI) / 180;

  const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) + Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const d = R * c; // in metres
  return d;
};

export const delay = (t) => {
  return new Promise((resolve) => setTimeout(resolve, t));
};

export const isEmailValid = (email) => {
  return /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(
    email?.toLowerCase()
  );
};

export const sortAlphabetically = (arr, key) => {
  return arr.sort((a, b) => {
    const compA = a[key].toUpperCase();
    const compB = b[key].toUpperCase();
    if (compA == compB) return 0;
    return compA < compB ? -1 : 1;
  });
};

export const sortUnique = (arr, key, isInteger) => {
  return [...new Set(arr.map((item) => (isInteger ? parseInt(item[key]) : item[key])))].sort((a, b) => {
    return a - b;
  });
};
