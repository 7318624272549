<template>
  <ion-page>
    <uncoded-header title="Carriers" />
    <ion-content>
      <div class="flex vertical">
        <div class="grid2 p" v-show="allCarriers">
          <div
            class="card"
            v-for="carrier in allCarriers"
            :key="carrier.id"
            @click="editCarrier(carrier.id)"
          >
            <img
              :src="carrier.logo"
              class="light contain"
              @load="carrier.imageLoaded = true"
            />
            <div class="title">{{ carrier.name }}</div>
          </div>
        </div>

        <div class="fullwidth ph2">
          <ion-button @click="addCarrier" class="fullwidth medium primary mb2"
            >Add carrier</ion-button
          >
        </div>
      </div>
      <wait v-show="!allCarriers">Getting carrier list</wait>
    </ion-content>
  </ion-page>
</template>

<script>
import UncodedHeader from "@/components/UncodedHeader.vue";
import { useRouter } from "vue-router";
import { usePropertyStore } from "@/stores/property";
import { storeToRefs } from "pinia";

export default {
  components: { UncodedHeader },
  setup() {
    const router = useRouter();
    const propertyStore = usePropertyStore();
    const { allCarriers } = storeToRefs(propertyStore);

    const editCarrier = (carrierID) => {
      router.push({
        name: "EditCarrier",
        params: { carrierID: carrierID },
      });
    };

    const addCarrier = () => {
      propertyStore.createCarrier().then((newCarrierID) => {
        editCarrier(newCarrierID);
      });
    };

    return {
      // methods
      editCarrier,
      addCarrier,
      // variables
      allCarriers,
      // icons
    };
  },
};
</script>
