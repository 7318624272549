<template>
  <ion-page>
    <uncoded-header title="Edit Property" />
    <ion-content>
      <div class="flex vertical p" v-if="space">
        <property-header :property="property" />
        <nav-header @back="back">Terms</nav-header>
        <div class="fullwidth">
          <ion-item lines="none"
            ><ion-label slot="end">Reorder terms</ion-label><ion-toggle v-model="allowReorder" slot="end"></ion-toggle>
          </ion-item>
          <ion-list class="fullwidth">
            <ion-reorder-group :disabled="!allowReorder" @ionItemReorder="reorder($event)">
              <ion-item v-for="(term, index) in space.terms" :key="index" class="flex left" lines="none">
                <div class="flex top">
                  <ion-item lines="none">
                    <ion-label position="stacked" color="secondary">Title</ion-label>
                    <ion-input v-model="term.label" placeholder="Label" @ionInput="markDirty()"></ion-input>
                  </ion-item>

                  <ion-textarea v-show="!allowReorder" v-model="term.value" rows="5" class="bdm p"></ion-textarea>
                </div>
                <ion-reorder slot="end"></ion-reorder>
                <ion-icon v-if="!allowReorder" :icon="trashOutline" slot="end" color="danger" @click="deleteTerm(term)" />
              </ion-item>
            </ion-reorder-group>
          </ion-list>
        </div>
        <div class="grow"></div>
        <ion-button @click="addTerm()" class="medium fullwidth primary">Add Term</ion-button>
      </div>
      <wait v-else>Getting details</wait>
    </ion-content>
  </ion-page>
</template>

<script>
import { computed, onMounted, ref } from "@vue/runtime-core";
import { useRoute, useRouter } from "vue-router";
import { chevronForward, trashOutline } from "ionicons/icons";
import { alertController, onIonViewWillEnter } from "@ionic/vue";
import { reIndex } from "@/composables/utilities";
import UncodedHeader from "@/components/UncodedHeader";
import PropertyHeader from "@/components/PropertyHeader.vue";
import NavHeader from "@/components/NavHeader.vue";
import { usePropertyStore } from "@/stores/property";
import { storeToRefs } from "pinia";

export default {
  components: { NavHeader, PropertyHeader, UncodedHeader },
  setup() {
    const dirty = ref(false);
    const allowReorder = ref(false);
    const route = useRoute();
    const router = useRouter();

    const propertyID = route.params.propertyID;
    const floorID = route.params.floorID;
    const spaceID = route.params.spaceID;

    const propertyStore = usePropertyStore();
    const { property, floor } = storeToRefs(propertyStore);
    const space = computed(() => floor.value?.spaces?.find((s) => s.id == spaceID));

    onIonViewWillEnter(() => {
      propertyStore.setPropertyID(propertyID);
      propertyStore.setFloorID(floorID);
    });

    const markDirty = () => {
      dirty.value = true;
    };

    const back = async () => {
      if (dirty.value) {
        const alert = await alertController.create({
          cssClass: "alert",
          header: "Are you sure?",
          message: "You have unsaved changes.",
          buttons: [
            {
              text: "Save & Close",
              handler: () => {
                propertyStore.saveSpace(space.value.id);
                router.back();
              },
            },
            { text: "Cancel" },
            {
              text: "Discard Changes",
              role: "destructive",
              handler: () => {
                router.back();
              },
            },
          ],
        });
        await alert.present();
      } else {
        router.back();
      }
    };
    const reorder = (e) => {
      markDirty();
      space.value.terms = e.detail.complete(space.value.terms);
      for (const [i, item] of space.value.terms.entries()) {
        item.index = i;
      }
    };

    const addTerm = () => {
      if (!space.value.terms) space.value.terms = [];
      space.value.terms.push({
        index: space.value.terms.length,
      });
    };

    const deleteTerm = (feature) => {
      space.value.terms = space.value.terms.filter((item) => item != feature);
      reIndex(space.value.terms);
      markDirty();
    };

    return {
      // variables
      space,
      property,
      allowReorder,
      // methods
      reorder,
      addTerm,
      deleteTerm,
      markDirty,
      back,
      // icons
      chevronForward,
      trashOutline,
    };
  },
};
</script>
