<template>
  <ion-page>
    <uncoded-header />

    <ion-content scroll-y="false">
      <div class="flex vertical fullwidth">
        <property-header />
        <nav-header @back="back">{{ space?.title }}</nav-header>

        <div class="expand noScroll fullwidth">
          <img :src="space.featurePhoto" class="fullwidth cover mt ph2" v-if="space" />
        </div>

        <div class="fullwidth ph">
          <div class="fullwidth p">
            <div class="action" @click="showGallery">
              <ion-label>Gallery</ion-label>
              <ion-icon :icon="chevronForward" />
            </div>
            <div class="action" @click="showDocuments" v-show="space?.documents?.length > 0">
              <ion-label>Documents</ion-label>
              <ion-icon :icon="chevronForward" />
            </div>
            <div class="action" @click="showTerms" v-show="space?.terms?.length > 0">
              <ion-label>Terms</ion-label>
              <ion-icon :icon="chevronForward" />
            </div>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { chevronForward, star, starOutline } from "ionicons/icons";
import { computed } from "@vue/runtime-core";
import { useRoute, useRouter } from "vue-router";
import { usePropertyStore } from "@/stores/property";
import UncodedHeader from "@/components/UncodedHeader";
import PropertyHeader from "../common/PropertyHeader";
import { storeToRefs } from "pinia";
import { onIonViewWillEnter } from "@ionic/vue";

export default {
  components: { UncodedHeader, PropertyHeader },
  setup() {
    const route = useRoute();
    const router = useRouter();

    const propertyID = route.params.propertyID;
    const floorID = route.params.floorID;
    const spaceID = route.params.spaceID;

    const propertyStore = usePropertyStore();
    const { property, floors } = storeToRefs(propertyStore);

    onIonViewWillEnter(() => {
      propertyStore.setPropertyID(propertyID);
    });

    const space = computed(() => {
      console.log(floors?.value);
      return floors?.value?.find((f) => f.id == floorID).spaces.find((s) => s.id == spaceID);
    });

    const back = () => {
      router.back();
    };

    const showGallery = () => {
      router.push({
        name: "SpaceGallery",
        params: { propertyID: propertyID, floorID: floorID, spaceID: spaceID },
      });
    };

    const showDocuments = () => {
      router.push({
        name: "SpaceDocuments",
        params: { propertyID: propertyID, floorID: floorID, spaceID: spaceID },
      });
    };

    const showTerms = () => {
      router.push({
        name: "SpaceTerms",
        params: { propertyID: propertyID, floorID: floorID, spaceID: spaceID },
      });
    };

    return {
      // variables
      property,
      space,
      // methods
      back,
      showDocuments,
      showTerms,
      showGallery,
      // toggleFavourite,
      // icons
      chevronForward,
      star,
      starOutline,
    };
  },
};
</script>
