<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
        </ion-buttons>
        <ion-title>My Account</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-accordion-group value="details" class="m2">
        <ion-accordion value="details" v-if="user">
          <ion-item slot="header" color="dark">My Details</ion-item>
          <div slot="content">
            <ion-item>
              <ion-label position="stacked">First Name</ion-label>
              <ion-input v-model="user.firstname" @ionChange="markDirty()" />
            </ion-item>
            <ion-item>
              <ion-label position="stacked">Last Name</ion-label>
              <ion-input v-model="user.lastname" @ionChange="markDirty()" />
            </ion-item>
            <ion-item>
              <ion-label position="stacked">Email Address</ion-label>
              <ion-input :value="user.id" :disabled="true" />
            </ion-item>
            <ion-item>
              <ion-label position="stacked">Phone Number</ion-label>
              <ion-input v-model="user.phone" @ionChange="markDirty()" />
            </ion-item>
            <ion-item lines="none">
              <ion-button
                expand="full"
                class="bdl fullwidth"
                :disabled="!dirty"
                @click="save"
                >Save</ion-button
              ></ion-item
            >
          </div>
        </ion-accordion>
      </ion-accordion-group>
    </ion-content>
  </ion-page>
</template>

<script>
import { ref } from "vue";
import { onBeforeRouteLeave, useRouter } from "vue-router";
import { updateFirebaseDocument } from "@/composables/firebase";
import { alertController } from "@ionic/vue";
import { useUserStore } from "@/stores/user";
import { storeToRefs } from "pinia";

export default {
  setup() {
    const dirty = ref(false);
    const router = useRouter();
    const userStore = useUserStore();
    const { user } = storeToRefs(userStore);

    const markDirty = () => {
      dirty.value = true;
    };

    const save = async () => {
      userStore.updateProfile(
        user.value.firstname,
        user.value.lastname,
        user.value.phone
      );
      dirty.value = false;
    };

    onBeforeRouteLeave(async (to, _, next) => {
      if (dirty.value) {
        const alert = await alertController.create({
          cssClass: "alert",
          header: "Are you sure?",
          message: "You have unsaved changes.",
          buttons: [
            {
              text: "Save & Close",
              handler: async () => {
                await save();
                router.push(to);
              },
            },
            {
              text: "Cancel",
            },
            {
              text: "Discard Changes",
              role: "destructive",
              handler: () => {
                next();
              },
            },
          ],
        });
        await alert.present();
      } else {
        next();
      }
    });

    return { user, markDirty, dirty, save };
  },
};
</script>

<style scoped></style>
