import { getApp, initializeApp } from "firebase/app";

import {
  getFirestore,
  collection,
  collectionGroup,
  query,
  where,
  getDocs,
  doc,
  getDoc,
  setDoc,
  addDoc,
  deleteDoc,
  onSnapshot,
  connectFirestoreEmulator,
} from "@firebase/firestore";
import { getStorage, ref, uploadBytes, uploadString, getDownloadURL, deleteObject, connectStorageEmulator } from "firebase/storage";
import {
  initializeAuth,
  signInAnonymously,
  browserLocalPersistence,
  onAuthStateChanged,
  getAuth,
  connectAuthEmulator,
  AuthCredential,
} from "firebase/auth";
import { fetchAndActivate, getRemoteConfig, getValue } from "firebase/remote-config";
import { connectFunctionsEmulator, getFunctions, httpsCallable } from "firebase/functions";
import { isPlatform } from "@ionic/vue";
import { usePropertyStore } from "@/stores/property";
import { useUserStore } from "@/stores/user";
import mixpanel from "mixpanel-browser";
let db;
let storage;
let functions;
let remoteConfig;
const unsubscriptions = [];
const isDevelopment = process.env.VUE_APP_MODE == "development";

export const initialiseFirebase = async (config) => {
  const app = initializeApp(config);
  const auth = initializeAuth(app, {
    persistence: browserLocalPersistence,
  });

  db = getFirestore();
  functions = getFunctions(getApp(), "europe-west2");
  storage = getStorage();
  remoteConfig = getRemoteConfig(app);
  remoteConfig.settings.minimumFetchIntervalMillis = process.env.VUE_APP_REMOTE_CONFIG_INTERVAL;
  fetchAndActivate(remoteConfig);

  if (isDevelopment && !isPlatform("cordova")) {
    //console.log("Connecting to local emulators");
    // connectFirestoreEmulator(db, "localhost", 8088);
    // connectAuthEmulator(auth, "http://localhost:9099");
    // connectStorageEmulator(storage, "localhost", 9199);
    //connectFunctionsEmulator(functions, "localhost", 5001);
  }

  onAuthStateChanged(auth, () => {
    if (!auth.currentUser) {
      signInAnonymously(auth);
    } else {
      const propertyStore = usePropertyStore();
      propertyStore.initialise();
      mixpanel.identify(auth.currentUser?.email || auth.currentUser?.uid);
      if (auth.currentUser?.email) mixpanel.track("User Authenticated", { email: auth.currentUser.email });
    }
  });
};

export const checkVersion = () => {
  const minimumRequiredVersion = getValue(remoteConfig, "minimumRequiredVersion").asNumber();
  const actualVersion = parseInt(process.env.VUE_APP_VERSION);
  try {
    return !isPlatform("cordova") || actualVersion >= minimumRequiredVersion;
    // return actualVersion >= minimumRequiredVersion;
  } catch (error) {
    console.log(error);
    return true;
  }
};

export const subscribeCollection = (collectionPath, callback) => {
  const collectionRef = collection(db, collectionPath);
  const result = onSnapshot(collectionRef, (collectionSnapshot) => {
    callback(
      collectionSnapshot.docs.map((doc) => {
        const result = { id: doc.id, ...doc.data() };
        return result;
      })
    );
  });
  unsubscriptions.push(result);
  return result;
};

export const subscribeDocument = (collectionPath, id, callback) => {
  const documentRef = doc(db, collectionPath, id);

  const result = onSnapshot(documentRef, (documentSnapshot) => {
    callback(documentSnapshot.data());
  });
  unsubscriptions.push(result);
  return result;
};

export const runFunction = async (functionName, data) => {
  const fn = httpsCallable(functions, functionName, { timeout: 540000 });
  const cleanData = JSON.parse(JSON.stringify(data));
  try {
    const result = await fn(cleanData);
    return result.data;
  } catch (error) {
    if (isDevelopment) console.log("Error calling function", functionName, cleanData, error);
  }
};

export const getFirebaseDocument = async (collection, id) => {
  try {
    const docRef = doc(db, collection, id);
    const document = await getDoc(docRef);
    let result;
    if (document.exists()) {
      result = { id: document.id, ...document.data() };
    }
    return Promise.resolve(result);
  } catch (error) {
    if (isDevelopment) console.log("Error getting document", collection, id, error);
  }
};

export const getFirebaseCollection = async (collectionPath) => {
  try {
    const q = query(collection(db, collectionPath));
    const snapshot = await getDocs(q);
    const result = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    return Promise.resolve(result);
  } catch (error) {
    if (isDevelopment) console.log("Error getting collection", collectionPath, error);
  }
};

export const getFirebaseCollectionGroup = async (collectionPath) => {
  try {
    const q = query(collectionGroup(db, collectionPath));
    const snapshot = await getDocs(q);
    const result = snapshot.docs.map((doc) => ({
      id: doc.id,
      path: doc.ref.parent.path,
      ...doc.data(),
    }));
    return Promise.resolve(result);
  } catch (error) {
    if (isDevelopment) console.log("Error getting collection group", collectionPath, error);
  }
};

export const getFirebaseQuery = async (collectionPath, filterField, operator, value) => {
  try {
    const q = query(collection(db, collectionPath), where(filterField, operator, value));
    const snapshot = await getDocs(q);
    const result = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    return Promise.resolve(result);
  } catch (error) {
    if (isDevelopment) console.log("Error getting query", collectionPath, filterField, operator, value, error);
  }
};

export const getFirebaseDualQuery = async (collectionPath, filterField, operator, value, filterField2, operator2, value2) => {
  try {
    const q = query(collection(db, collectionPath), where(filterField, operator, value), where(filterField2, operator2, value2));
    const snapshot = await getDocs(q);
    const result = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    return Promise.resolve(result);
  } catch (error) {
    if (isDevelopment)
      console.log("Error getting query", collectionPath, filterField, operator, value, filterField2, operator2, value2, error);
  }
};

export const updateFirebaseDocument = async (collectionName, id, value, merge = true) => {
  try {
    const docRef = doc(db, collectionName, id);
    const document = await setDoc(docRef, value, { merge: merge });
    return Promise.resolve(document);
  } catch (error) {
    if (isDevelopment) console.log("Error updating document", collectionName, id, error, value);
  }
};

export const createFirebaseDocument = async (collectionName, value) => {
  const db = getFirestore();
  try {
    const document = await addDoc(collection(db, collectionName), value);
    return Promise.resolve(document);
  } catch (error) {
    if (isDevelopment) console.log("Error creating document", collectionName, value);
  }
};

export const deleteFirebaseDocument = async (collectionName, value) => {
  try {
    const document = doc(db, collectionName, value);
    await deleteDoc(document);
  } catch (error) {
    if (isDevelopment) console.log("Error deleting document", collectionName, value, error);
  }
};

export const uploadFirebaseFile = async (path, filename, data) => {
  try {
    const storageRef = ref(storage, `${path}/${filename}`);
    const snapshot = await uploadBytes(storageRef, data);
    const url = await getDownloadURL(storageRef);
    return { ...snapshot, url: url };
  } catch (error) {
    console.log("Error uploading File", path, filename);
  }
};

export const deleteFirebaseFile = async (path) => {
  try {
    const storageRef = ref(storage, path);
    await deleteObject(storageRef);
  } catch (error) {
    console.log("Error deleting file", path);
  }
};

export const uploadFirebaseBase64 = async (path, filename, data) => {
  try {
    const storageRef = ref(storage, `${path}/${filename}`);
    const snapshot = await uploadString(storageRef, data, "base64");
    const url = await getDownloadURL(storageRef);
    return { ...snapshot, url: url };
  } catch (error) {
    if (isDevelopment) console.log("Error uploading document (64)", path, filename);
  }
};

export const getCurrentUser = () => {
  return new Promise((resolve) => {
    const auth = getAuth();
    if (auth?.currentUser) {
      resolve(auth?.currentUser);
    }
    auth.onAuthStateChanged((user) => {
      resolve(user);
    });
  });
};

export const getURL = async (path) => {
  try {
    const url = await getDownloadURL(ref(storage, path));
    return url;
  } catch (error) {
    console.log(`Could not resolve ${path}, ${error}`);
  }
};

export const getShortcode = (length = 4) => {
  let text;
  let isUnique = false;
  const propertyStore = usePropertyStore();

  const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";

  do {
    text = "";
    for (let i = 0; i < length; i++) text += possible.charAt(Math.floor(Math.random() * possible.length));
  } while (!propertyStore.allProperties.map((p) => p.shortcode).includes(text));

  return text;
};

export const firebaseLogout = async () => {
  unsubscriptions.forEach((unsubscribe) => {
    unsubscribe();
  });
  const auth = getAuth();
  await auth.signOut();
};
