<template>
  <ion-page>
    <uncoded-header title="Contact Us" />
    <ion-content>
      <div class="flex vertical cd p2 safe-bottom">
        <div class="flex vertical left autoheight grow">
          <ion-input
            class="nogrow"
            placeholder="Your name"
            v-model="data.name"
          ></ion-input>
          <ion-input
            class="nogrow"
            placeholder="Your email"
            v-model="data.email"
          ></ion-input>
          <ion-input
            class="nogrow"
            placeholder="Your phone number"
            v-model="data.phone"
          ></ion-input>
          <ion-input
            class="nogrow"
            placeholder="Subject"
            v-model="data.subject"
          ></ion-input>
          <ion-textarea
            class="grow"
            auto-grow="true"
            placeholder="Message"
            v-model="data.message"
          ></ion-textarea>
        </div>
        <div class="fullwidth">
          <ion-button expand="full" @click="performAction" class="medium"
            >Send message</ion-button
          >
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent, onMounted, reactive } from "vue";
import { runFunction } from "@/composables/firebase";
import { toast } from "@/composables/utilities";
import UncodedHeader from "@/components/UncodedHeader";
import { useUserStore } from "@/stores/user";
import { storeToRefs } from "pinia";

export default defineComponent({
  components: { UncodedHeader },
  setup() {
    const userStore = useUserStore();
    const { user } = storeToRefs(userStore);

    const data = reactive({});
    onMounted(async () => {
      data.name = `${user.value?.firstname || ""} ${
        user.value?.lastname || ""
      }`;
      if (data.name == " ") data.name = "";
      data.email = user.value?.id;
      data.phone = user.value?.phone;
      data.subject = `Online Inside enquiry`;
    });

    const performAction = async () => {
      await runFunction("responseRequest", { type: data.action, ...data });
      toast("Message sent.");
    };

    return {
      // methods
      close,
      performAction,
      //variables
      data,
      //icons
    };
  },
});
</script>
<style scoped>
ion-input,
ion-textarea {
  --padding-start: 5px;
  background: var(--ion-color-light);
  color: var(--ion-color-light-contrast);
}
</style>
