<template>
  <ion-page>
    <ion-header class="bgm">
      <ion-toolbar>
        <ion-title class="titleFont fg">Price Enquiry</ion-title>
        <ion-buttons slot="end" class="mr2">
          <ion-icon :icon="closeCircleOutline" class="fg" @click="close" />
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <div class="flex vertical bgl cd p2 safe-bottom">
        <div class="flex vertical left autoheight grow">
          <ion-input
            class="darkBorder nogrow"
            placeholder="Your name"
            v-model="data.name"
          ></ion-input>
          <ion-input
            class="darkBorder nogrow"
            placeholder="Your email"
            v-model="data.email"
          ></ion-input>
          <ion-input
            class="darkBorder nogrow"
            placeholder="Your phone number"
            v-model="data.phone"
          ></ion-input>
          <ion-input
            class="darkBorder nogrow"
            placeholder="Subject"
            v-model="data.subject"
          ></ion-input>
          <ion-textarea
            class="darkBorder grow"
            auto-grow="true"
            placeholder="Message"
            v-model="data.message"
          ></ion-textarea>
        </div>
        <div class="fullwidth">
          <ion-button expand="full" @click="performAction"
            >Make Enquiry</ion-button
          >
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent, reactive } from "vue";
import { closeCircleOutline } from "ionicons/icons";
import { runFunction } from "@/composables/firebase";
import { modalController } from "@ionic/vue";
import { toast } from "@/composables/utilities";
import { useUserStore } from "@/stores/user";
import { storeToRefs } from "pinia";

export default defineComponent({
  props: ["property", "price"],
  setup(props) {
    const userStore = useUserStore();
    const { user } = storeToRefs(userStore);
    const data = reactive({
      name: `${user.value?.firstname || ""} ${user.value?.lastname || ""}`,
      email: user.value?.email,
      phone: user.value?.phone,
      subject: `${props.property.title} pricing enquiry`,
      message: `Please contact me to discuss connectivity pricing for ${props.property.title}. I am interested in ${props.price.bandwidth} on a ${props.price.bearer} bearer over ${props.price.term} months.`,
      property: {
        id: props.property?.id,
        title: props.property?.title,
      },
    });

    const close = () => {
      modalController.dismiss();
    };

    const performAction = async () => {
      await runFunction("responseRequest", { type: data.action, ...data });
      close();
      toast("Message sent.");
    };

    return {
      // methods
      close,
      performAction,
      //variables
      data,
      //icons
      closeCircleOutline,
    };
  },
});
</script>
