<template>
  <ion-page>
    <ion-content>
      <swiper
        slot="fixed"
        pagination
        :allowTouchMove="false"
        :modules="modules"
        class="fullwidth fullheight grow safe-top safe-bottom"
        @swiper="setSwiper"
      >
        <swiper-slide v-for="(page, index) in pages" :key="index">
          <div class="flex vertical pb" :class="{ oilogo: page.bullets }">
            <div class="flex vertical safe-bottom">
              <div class="flex vertical slideContainer">
                <ion-label
                  class="titleFont f2 cl"
                  :class="{ cd: page.bullets }"
                  >{{ page.title }}</ion-label
                >

                <div v-if="page.icon">
                  <ion-icon :icon="page.icon" class="f8 cl" />
                </div>

                <div
                  v-for="(bullet, index) in page.bullets"
                  :key="index"
                  class="bullet cd"
                  :class="{ left: index % 2 == 0, right: index % 2 == 1 }"
                >
                  {{ bullet }}
                </div>

                <div class="cl fl mh2" :class="{ cd: page.bullets }">
                  <div class="fullwidth">{{ page.description }}</div>
                </div>

                <div class="fullwidth ph2" v-if="index < pages.length - 1">
                  <ion-button expand="full" class="bdl" @click="next()"
                    >Next</ion-button
                  >
                </div>

                <div class="fullwidth ph2" v-if="index == pages.length - 1">
                  <ion-button expand="full" class="bdl" @click="done()"
                    >Let's Go!</ion-button
                  >
                </div>
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </ion-content>
  </ion-page>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "@ionic/vue/css/ionic-swiper.css";
import { shareOutline, qrCodeOutline } from "ionicons/icons";
import { useRouter } from "vue-router";

export default {
  components: { Swiper, SwiperSlide },
  setup() {
    const router = useRouter();
    const modules = [Pagination];
    let swiper;
    const pages = [
      {
        title: "Search & Scan",
        icon: qrCodeOutline,
        description: "Scan a QR code, or enter a shortcode for a property.",
      },
      {
        title: "View Details",
        bullets: [
          "Information Snapshot",
          "Connectivity Options",
          "Technical Infrastructure",
          "Get Help",
        ],
      },
      {
        title: "Favourite & Share",
        icon: shareOutline,
        description:
          "Add a property to a shortlist and share it with the right people, so that they have the information they need.",
      },
    ];

    const setSwiper = (_swiper) => {
      swiper = _swiper;
    };

    const next = () => {
      swiper.slideNext(300);
    };

    const done = () => {
      router.push({ name: "Home" });
    };

    return {
      // methods
      done,
      next,
      setSwiper,
      // variables
      pages,
      modules,
    };
  },
};
</script>

<style>
.swiper-pagination-horizontal {
  margin-bottom: 80px;
}
</style>

<style scoped>
.slideContainer {
  margin: 100px 0px;
  overflow: hidden;
}

.bullet {
  font-family: Bold;
  padding: 10px;
}

.bullet.left {
  background: #aaa;
  /* color: var(--ion-color-primary); */
  /* border: 3px solid var(--ion-color-primary); */
  border-radius: calc(var(--default-radius) * 2);
  width: 100%;
  margin-right: 50px;
  padding-left: 50px;
}
.bullet.right {
  background: var(--ion-color-primary);
  border: 3px solid var(--ion-color-primary);
  border-radius: calc(var(--default-radius) * 2);
  width: 100%;
  margin-left: 50px;
  padding-right: 50px;
}
</style>
