<template>
  <ion-page>
    <uncoded-header title="Organisation" />
    <nav-header class="cl" @back="back">{{ organisation?.name }}</nav-header>
    <ion-content :scroll-y="false">
      <div class="flex vertical" v-if="organisation">
        <div class="fullwidth p mt2 scroll">
          <div class="action fullwidth" v-for="contact in organisation.contacts" :key="contact.id" @click="showContact(contact.id)">
            {{ contact.firstname }} {{ contact.lastname }}
            <ion-icon :icon="chevronForward" />
          </div>
        </div>
        <div class="p fullwidth">
          <ion-button color="primary" expand="full" class="equal" @click="addContact()">New Contact</ion-button>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import UncodedHeader from "@/components/UncodedHeader.vue";
import { computed, onMounted, ref } from "@vue/runtime-core";
import { useRoute, useRouter } from "vue-router";
import { usePropertyStore } from "@/stores/property";

import { chevronBackCircleOutline, chevronForward } from "ionicons/icons";
import { storeToRefs } from "pinia";

export default {
  components: { UncodedHeader },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const contactTypeID = route.params.contactTypeID;
    const organisationID = route.params.organisationID;
    const propertyStore = usePropertyStore();
    const { allContactTypes } = storeToRefs(propertyStore);

    const contactType = computed(() => allContactTypes.value?.find((ct) => ct.id == contactTypeID));
    const organisation = computed(() => contactType.value?.organisations?.find((o) => o.id == organisationID));

    const showContact = (contactID) => {
      router.push({
        name: "EditContact",
        params: {
          contactTypeID: contactTypeID,
          organisationID: organisationID,
          contactID: contactID,
        },
      });
    };

    const addContact = () => {
      propertyStore.createContact(contactTypeID, organisationID).then((newDocID) => {
        showContact(newDocID);
      });
    };

    const back = () => {
      router.back();
    };

    return {
      // variables
      organisation,
      // methods
      back,
      addContact,
      showContact,
      // icons
      chevronBackCircleOutline,
      chevronForward,
    };
  },
};
</script>
