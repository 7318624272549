<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
        </ion-buttons>
        <ion-title>Recently viewed</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <div v-if="recent?.properties">
        <ion-item class="uppercase titleFont f2 cl mv" lines="full"> {{ recent.properties.length }} Properties </ion-item>

        <div class="grid2 p">
          <div
            class="card"
            v-for="(property, index) in recent.properties"
            :key="index"
            @click="openProperty(property)"
            v-show="property.imageLoaded"
          >
            <img :src="property.featurePhoto" class="photo" @load="property.imageLoaded = true" />
            <div class="title">{{ property.title }}</div>
            <div class="subtitle">{{ property.town }}{{ property.town && property.postcode ? ", " : "" }}{{ property.postcode }}</div>
          </div>
        </div>
      </div>

      <!-- <div v-if="recent?.spaces">
        <ion-item class="uppercase titleFont f2 cl mv" lines="full">
          Spaces
        </ion-item>

        <div class="grid2 p">
          <div
            class="card"
            v-for="(space, index) in recents.spaces"
            :key="index"
            @click="openSpace(space)"
            v-show="space.imageLoaded"
          >
            <img
              :src="getFirstPhoto(property)"
              class="photo"
              @load="space.imageLoaded = true"
            />
            <div class="title">{{ space.name }}</div>
            <div class="subtitle"></div>
          </div>
        </div>
      </div> -->

      <div v-if="recent?.locations">
        <ion-item class="uppercase titleFont f2 cl mv" lines="full"> {{ recent.locations.length }} Locations </ion-item>

        <div class="grid2 p">
          <div class="card" v-for="(location, index) in recent.locations" :key="index" @click="openLocation(location)">
            <static-map :location="location" :useLightMode="true" class="photo cover" />
            <div class="title">
              {{ location?.title }}
            </div>
            <div class="subtitle">
              {{ location?.postcode }}
            </div>
          </div>
        </div>
      </div>

      <div v-if="(recent?.properties && recent.properties.length > 0) || (recent?.spaces && recent.spaces.length > 0)" class="fullwidth p2">
        <ion-button expand="full" class="medium" @click="back()">Find another property</ion-button>
      </div>
      <div v-else class="fullwidth p2">
        <ion-button expand="full" class="medium" @click="back()">Find your first property</ion-button>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { useRouter } from "vue-router";
import { useUserStore } from "@/stores/user";
import { storeToRefs } from "pinia";
import StaticMap from "@/components/StaticMap";

export default {
  components: { StaticMap },

  setup() {
    const userStore = useUserStore();
    const { recent } = storeToRefs(userStore);
    const router = useRouter();

    console.log(recent.value);

    const openProperty = (property) => {
      router.push({
        name: "Property",
        params: { propertyID: property.id },
      });
    };

    const openLocation = async (location) => {
      router.push({
        name: "GetPrices",
        params: {
          street_number: location.house_number,
          street: location.road,
          town: location.city,
          postcode: location.postcode,
          latitude: location.lat,
          longitude: location.lng,
          full: location.full,
          title: location.title,
        },
      });
    };

    const openSpace = (space) => {
      router.push({
        name: "Space",
        params: {
          propertyID: space.propertyID,
          floorID: space.floorID,
          spaceID: space.spaceID,
        },
      });
    };

    const back = () => {
      router.push({ name: "Home" });
    };

    return {
      recent,
      openProperty,
      openLocation,
      openSpace,
      back,
    };
  },
};
</script>
