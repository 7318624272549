<template>
  <ion-page>
    <div id="loginContainer">
      <uncoded-header title="Verify Email" />

      <ion-content>
        <div class="flex vertical">
          <div></div>
          <div style="max-width: min(90vw, 500px)" class="fullwidth">
            <div class="tc">
              Your email has not yet been verified. Please check your email and click on the link we sent you to confirm your email address,
              then click on the button below to retry.
            </div>
            <ion-button expand="full" class="dark bdl mt" @click="retry()">Retry</ion-button>
          </div>

          <div class="flex pb2 safe-bottom" style="max-width: min(90vw, 500px)">
            <ion-button expand="full" class="dark bdl mv" @click="resend()"
              ><ion-label class="fs">Resend Verification Email</ion-label></ion-button
            >
          </div>
        </div>
      </ion-content>
    </div>
  </ion-page>
</template>

<script>
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { toast } from "@/composables/utilities";
import { updateFirebaseDocument, runFunction } from "@/composables/firebase";
import UncodedHeader from "@/components/UncodedHeader";
import { useUserStore } from "@/stores/user";
import { storeToRefs } from "pinia";

export default {
  components: { UncodedHeader },
  setup() {
    const router = useRouter();
    const email = ref();
    const emailFeedback = ref();
    const userStore = useUserStore();
    const { user, profile } = storeToRefs(userStore);

    const retry = async () => {
      await userStore.updateUser();
      //if (user.value.emailVerified) {
      // updateUser();
      router.push({ name: "Onboarding" });
      // } else {
      //   toast(
      //     "Email is still not verified. Please try resending the verification email. If you still experience issues, you can contact us from the main menu.",
      //     5000
      //   );
      // }
    };

    // onMounted(async () => {
    //   await user.value?.reload();
    // });

    const resend = () => {
      let remainingMinutes = 0;

      if (profile.value?.emailVerificationSent) {
        const sentDate = profile.value.emailVerificationSent.toDate();
        const elapsedMinutes = Math.ceil((new Date() - sentDate) / (1000 * 60));
        remainingMinutes = 2 - elapsedMinutes;
      }

      if (remainingMinutes > 0) {
        toast(
          `
          Please wait ${remainingMinutes} minute${remainingMinutes > 1 ? "s" : ""} before sending another request.
          If you cannot find the email in your inbox, please check your spam folder.
          `,
          5000
        );
        return;
      }

      if (profile.value) {
        profile.value.emailVerificationSent = new Date();
      } else {
        profile.value = {
          emailVerificationSent: new Date(),
        };
      }
      updateFirebaseDocument("profiles", user.value?.id, profile.value);
      runFunction("validateEmail", { validate_email: user.value.id });
      toast(`Verification email resent to ${user.value?.id}. Please check your inbox.`);
    };

    return {
      email,
      emailFeedback,
      resend,
      retry,
    };
  },
};
</script>

<style scoped>
#loginContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.formItems {
  height: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

#loginContainer ion-item,
#loginContainer ion-button {
  width: 100%;
  max-width: 500px;
}

.footerContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: env(safe-area-inset-bottom);
}
.footerContainer ion-button {
  flex: 1 0 1px;
  font-size: 0.7em;
}
</style>
