<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
        </ion-buttons>
        <ion-title>Shortlist</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <div v-if="shortlist.properties">
        <ion-item class="uppercase titleFont f2 cl mv" lines="full"> {{ shortlist.properties.length }} Properties </ion-item>

        <div class="grid2 p">
          <div
            class="card"
            v-for="(property, index) in shortlist.properties"
            :key="index"
            @click="openProperty(property)"
            v-show="property.imageLoaded"
          >
            <img :src="property.featurePhoto" class="photo" @load="property.imageLoaded = true" />
            <div class="title">{{ property.title }}</div>
            <div class="subtitle">{{ property.town }}{{ property.town && property.postcode ? ", " : "" }}{{ property.postcode }}</div>
          </div>
        </div>
      </div>

      <div v-if="shortlist.properties && shortlist.properties.length > 0" class="fullwidth p2">
        <ion-button expand="full" class="medium" @click="back()">Find another property</ion-button>
      </div>
      <div v-else class="fullwidth p2">
        <ion-button expand="full" class="medium" @click="back()">Find your first property</ion-button>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { useRouter } from "vue-router";
import { useUserStore } from "@/stores/user";
import { storeToRefs } from "pinia";

export default {
  setup() {
    const userStore = useUserStore();
    const { shortlist } = storeToRefs(userStore);
    const router = useRouter();

    const openProperty = (property) => {
      router.push({
        name: "Property",
        params: { propertyID: property.id },
      });
    };

    const back = () => {
      router.push({ name: "Home" });
    };

    return {
      shortlist,
      openProperty,
      back,
    };
  },
};
</script>
