<template>
  <div class="fullwidth bgd ph2 pt2 keepOnTop" style="text-align: left">
    <div class="flex cl uppercase titleFont fm">
      <div class="flex left">
        <div>{{ property?.title }}</div>
      </div>
      <div>
        <ion-icon :icon="playCircleOutline" @click="showPreview()" />
      </div>
    </div>

    <div class="flex left cl uppercase boldFont fs mb">
      <ion-label>{{ property?.subtitle }}</ion-label>
    </div>
  </div>
</template>

<script>
import { playCircleOutline } from "ionicons/icons";
import { useRouter } from "vue-router";
export default {
  name: "PropertyHeader",
  props: ["property"],
  setup(props) {
    const router = useRouter();
    const showPreview = () => {
      router.push({
        name: "Property",
        props: { propertyID: props.property?.id },
      });
    };

    return { showPreview, playCircleOutline };
  },
};
</script>
