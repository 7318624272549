<template>
  <ion-page>
    <uncoded-header />
    <ion-content scroll-y="false" v-if="property">
      <div class="flex vertical fullwidth bgd">
        <div class="flex left cl uppercase titleFont fm pt2 pl2">
          <div>{{ property?.title }}</div>
          <ion-icon :icon="shieldCheckmarkOutline" class="ft superscript cp" v-if="property?.verified" />
        </div>

        <nav-header @back="back">Give Feedback</nav-header>

        <div ref="typeform" id="typeform" class="grow fullwidth"></div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { chevronBackCircleOutline, chevronForward, shieldCheckmarkOutline } from "ionicons/icons";
import { useRoute, useRouter } from "vue-router";
import UncodedHeader from "@/components/UncodedHeader";
import { usePropertyStore } from "@/stores/property";
import { storeToRefs } from "pinia";
import { onIonViewWillEnter } from "@ionic/vue";
import { ref, watch } from "vue-demi";
import { createWidget } from "@typeform/embed";
import "@typeform/embed/build/css/widget.css";

export default {
  components: { UncodedHeader },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const propertyID = route.params.propertyID;
    const propertyStore = usePropertyStore();
    const { property } = storeToRefs(propertyStore);
    propertyStore.setPropertyID(propertyID);
    const typeform = ref();

    onIonViewWillEnter(async () => {
      await propertyStore.setPropertyID(propertyID);
    });

    const back = () => {
      router.back();
    };

    const setupForm = () => {
      if (!(property.value?.typeformID && typeform.value)) return;
      createWidget(property.value.typeformID, {
        container: typeform.value,
        inlineOnMobile: true,
        hidden: {
          property: property.value?.title,
        },
      });
    };

    watch([property, typeform], setupForm);
    setupForm();

    return {
      back,
      property,
      typeform,
      chevronBackCircleOutline,
      shieldCheckmarkOutline,
      chevronForward,
    };
  },
};
</script>
