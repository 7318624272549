<template>
  <ion-page>
    <uncoded-header title="Edit Property" />

    <ion-content>
      <div class="flex vertical nogap left" v-if="property">
        <property-header :property="property" />
        <nav-header @back="back">Photos</nav-header>
        <div class="fullwidth p">
          <input type="file" @change="addPhoto" ref="photoSelector" multiple hidden />

          <ion-item lines="none" class="fullwidth"
            ><ion-label slot="end">Reorder photos</ion-label><ion-toggle v-model="allowReorder" slot="end"></ion-toggle>
          </ion-item>

          <ion-list class="fullwidth">
            <ion-reorder-group :disabled="!allowReorder" @ionItemReorder="reorderPhotos($event)">
              <ion-item v-for="photo in property.photos" :key="photo.id" lines="none">
                <ion-thumbnail slot="start" class="thumbnail">
                  <img :src="photo.url" :alt="photo.description" v-if="photo.url" />
                </ion-thumbnail>
                <ion-input v-model="photo.description" placeholder="Description" @ionInput="markDirty()"></ion-input>
                <ion-reorder slot="end"></ion-reorder>
                <ion-icon v-if="!allowReorder" :icon="trashOutline" slot="end" color="danger" @click="deletePhoto(photo)" />
              </ion-item>
            </ion-reorder-group>
          </ion-list>

          <ion-button expand="full" class="fullwidth medium" @click="triggerAddPhoto">Add photo</ion-button>
        </div>
      </div>
      <wait v-else>Loading photos</wait>
    </ion-content>
  </ion-page>
</template>

<script>
import { ref } from "@vue/runtime-core";
import { useRoute, useRouter } from "vue-router";
import { chevronForward, trashOutline } from "ionicons/icons";
import { alertController, onIonViewWillEnter } from "@ionic/vue";

import UncodedHeader from "@/components/UncodedHeader";
import PropertyHeader from "@/components/PropertyHeader.vue";
import NavHeader from "@/components/NavHeader.vue";
import { usePropertyStore } from "@/stores/property";

import { storeToRefs } from "pinia";
export default {
  name: "Photos",
  components: { NavHeader, PropertyHeader, UncodedHeader },
  setup() {
    const dirty = ref(false);
    const allowReorder = ref(false);
    const propertyID = useRoute().params.propertyID;
    const photoSelector = ref();
    const router = useRouter();
    const propertyStore = usePropertyStore();
    const { property } = storeToRefs(propertyStore);
    const addedPhotos = [];

    onIonViewWillEnter(() => {
      propertyStore.setPropertyID(propertyID);
    });

    const save = async (pop = true) => {
      propertyStore.saveProperty();
      if (pop) router.back();
    };

    const markDirty = () => {
      dirty.value = true;
    };

    const back = async () => {
      if (dirty.value) {
        const alert = await alertController.create({
          cssClass: "alert",
          header: "Are you sure?",
          message: "You have unsaved changes.",
          buttons: [
            {
              text: "Save & Close",
              handler: () => {
                save();
              },
            },
            { text: "Cancel" },
            {
              text: "Discard Changes",
              role: "destructive",
              handler: () => {
                console.log(addedPhotos);
                addedPhotos.forEach((photo) => {
                  propertyStore.deletePropertyPhoto(photo);
                });
                propertyStore.revertProperty();
                router.back();
              },
            },
          ],
        });
        await alert.present();
      } else {
        router.back();
      }
    };

    const deletePhoto = async (photo) => {
      const alert = await alertController.create({
        cssClass: "alert",
        header: "Are you sure?",
        message: "This photo and associated information will be deleted immediately.",
        buttons: [
          {
            text: "Delete",
            role: "destructive",
            handler: () => {
              propertyStore.deletePropertyPhoto(photo);
              save(false);
            },
          },
          {
            text: "Cancel",
            role: "cancel",
          },
        ],
      });
      await alert.present();
    };

    const addPhoto = async () => {
      if (!property.value.photos) property.value.photos = [];
      const files = Array.from(event.target.files);
      files.forEach(async (file) => {
        const newPhoto = await propertyStore.addPropertyPhoto(file);
        addedPhotos.push(newPhoto);
        photoSelector.value.value = "";
      });

      markDirty();
    };

    const reorderPhotos = (e) => {
      const from = e.detail.from;
      const to = e.detail.to;
      markDirty();
      property.value.photos = e.detail.complete(property.value.photos);
      for (const [i, item] of property.value.photos.entries()) {
        item.index = i;
      }
    };

    const triggerAddPhoto = () => {
      photoSelector.value.click();
    };

    return {
      // variables
      property,
      allowReorder,
      photoSelector,
      // methods
      addPhoto,
      deletePhoto,
      reorderPhotos,
      triggerAddPhoto,
      markDirty,
      back,
      // icons
      chevronForward,
      trashOutline,
    };
  },
};
</script>
