import { getAnalytics, logEvent } from "firebase/analytics";

export const setScreen = (screenName) => {
  const analytics = getAnalytics();
  logEvent(analytics, "screen_view", {
    firebase_screen: screenName,
  });
};

export const logPropertySelection = (name) => {
  const analytics = getAnalytics();
  logEvent(analytics, "select_content", {
    content_type: "property",
    content_id: name,
  });
};
