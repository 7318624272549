<template>
  <ion-page>
    <uncoded-header />
    <ion-content scroll-y="false">
      <div class="flex vertical fullwidth">
        <property-header />
        <nav-header @back="back">Spaces</nav-header>
        <ion-accordion-group class="fullwidth">
          <ion-accordion v-for="floor in propertyFloors" :key="floor.id">
            <ion-item color="dark" slot="header">
              <div class="flex vertical top nogap centered">
                <div class="uppercase boldFont" style="margin-bottom: -3px">
                  {{ floor.name }}
                </div>
                <div
                  class="fs cl"
                  style="padding: -20px"
                  :style="{
                    opacity: floor.availableSpaces?.length > 0 ? 1 : 0.6,
                  }"
                >
                  {{ floor.availableSpaces?.length }} available space{{ floor.availableSpaces?.length == 1 ? "" : "s" }}
                </div>
              </div>
              <!-- <div
                class="flex centered nogap"
                style="width: 30px"
                slot="end"
                v-if="floor.documents?.length > 0"
                @click="showDocuments(floor, $event)"
              >
                {{ floor.documents.length }}<ion-icon class="fg" color="light" :icon="attachOutline" />
              </div> -->
            </ion-item>
            <ion-item slot="content">
              <ion-list class="fullwidth">
                <ion-item v-if="floor.spaces?.length == 0" lines="none">No spaces</ion-item>
                <ion-item
                  v-for="space in floor.spaces"
                  :key="space.id"
                  lines="none"
                  :style="{
                    opacity: space.availability == 'Available' ? 1 : 0.6,
                  }"
                  @click="showSpace(floor, space)"
                >
                  <div>
                    {{ space.title }}
                  </div>
                  <ion-icon :icon="chevronForward" slot="end" class="fs" />
                </ion-item>
              </ion-list>
            </ion-item>
          </ion-accordion>
        </ion-accordion-group>
        <div class="grow"></div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { attachOutline, chevronForward } from "ionicons/icons";
import { useRoute, useRouter } from "vue-router";
import UncodedHeader from "@/components/UncodedHeader.vue";
import PropertyHeader from "../common/PropertyHeader";
import { usePropertyStore } from "@/stores/property";
import { storeToRefs } from "pinia";
import { onIonViewWillEnter } from "@ionic/vue";

export default {
  components: { UncodedHeader, PropertyHeader },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const propertyID = route.params.propertyID;

    const propertyStore = usePropertyStore();
    const { property, propertyFloors } = storeToRefs(propertyStore);

    onIonViewWillEnter(() => {
      propertyStore.setPropertyID(propertyID);
    });

    const back = () => {
      router.back();
    };

    const showDocuments = (floor, event) => {
      event.cancelBubble = true;
      router.push({
        name: "FloorDocuments",
        params: { propertyID: propertyID, floorID: floor.id },
      });
    };

    const showSpace = (floor, space) => {
      router.push({
        name: "PropertySpace",
        params: {
          propertyID: propertyID,
          floorID: floor.id,
          spaceID: space.id,
        },
      });
    };

    return {
      back,
      property,
      showDocuments,
      showSpace,
      propertyFloors,
      attachOutline,
      chevronForward,
    };
  },
};
</script>
