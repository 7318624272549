<template>
  <ion-page>
    <uncoded-header />
    <ion-content scroll-y="false" v-if="property?.highlights">
      <div class="flex vertical fullwidth">
        <property-header />

        <nav-header @back="back">{{
          property.highlights[section].title
        }}</nav-header>

        <div>
          <div class="titleFont cl f8 tc">
            {{ property.highlights[section].value
            }}<span class="fr">{{ property.highlights[section].units }}</span>
          </div>
          <div class="titleFont cp f2 tc">
            {{ property.highlights[section].longTitle }}
          </div>
        </div>

        <div v-html="property.metrics[section].description" class="p2 tc"></div>
        <carrier-logos
          v-if="section == '2'"
          :carriers="carriers"
          class="fullwidth"
        />

        <div class="grow"></div>
        <div class="mb2 fullwidth">
          <div class="action" @click="showWhy">
            <ion-label
              >Why a score of
              {{ property.bullseyeScores[section] }}/4?</ion-label
            ><ion-label>&gt;</ion-label>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import CarrierLogos from "./CarrierLogos";
import { chevronBackCircleOutline } from "ionicons/icons";
import PropertyHeader from "../common/PropertyHeader";
import { useRoute, useRouter } from "vue-router";
import UncodedHeader from "@/components/UncodedHeader";
import { usePropertyStore } from "@/stores/property";
import { storeToRefs } from "pinia";
export default {
  components: {
    CarrierLogos,
    UncodedHeader,
    PropertyHeader,
  },
  emits: ["highlightClicked"],
  setup(props, { emit }) {
    const router = useRouter();
    const route = useRoute();
    const propertyID = route.params.propertyID;
    const section = route.params.section;

    const propertyStore = usePropertyStore();

    propertyStore.setPropertyID(propertyID);

    const { property, carriers } = storeToRefs(propertyStore);

    const back = () => {
      router.back();
    };

    const showWhy = () => {
      router.push({
        name: "BullseyeWhy",
        params: {
          section: section,
          score: property.value.bullseyeScores[section],
        },
      });
    };

    return {
      property,
      carriers,
      section,
      back,
      showWhy,
      chevronBackCircleOutline,
    };
  },
};
</script>
