<template>
  <ion-page>
    <uncoded-header title="Edit Property" />

    <ion-content :scroll-y="false" v-if="property">
      <div class="flex vertical nogap left">
        <property-header :property="property" />
        <nav-header @back="back">Description</nav-header>
        <div class="fullwidth p">
          <ion-item class="fullwidth">
            <ion-label position="stacked" color="secondary">Shortcode</ion-label>
            <ion-input placeholder="Shortcode" v-model="property.shortcode" @ionInput="markDirty()" required="true"></ion-input>
          </ion-item>
          <ion-item class="fullwidth">
            <ion-label position="stacked" color="secondary">Title</ion-label>
            <ion-input placeholder="Title" v-model="property.title" @ionInput="markDirty()" required="true"></ion-input>
          </ion-item>
          <ion-item class="fullwidth">
            <ion-label position="stacked" color="secondary">Subtitle</ion-label>
            <ion-input placeholder="Subtitle" v-model="property.subtitle" @ionInput="markDirty()"></ion-input>
          </ion-item>

          <ion-item class="fullwidth">
            <ion-label position="stacked" color="secondary">Website</ion-label>
            <ion-input placeholder="Website" v-model="property.website" @ionInput="markDirty()" inputmode="url"></ion-input>
          </ion-item>

          <ion-item class="fullwidth">
            <ion-label position="stacked" color="secondary">Typeform Feedback Survey ID</ion-label>
            <ion-input placeholder="Typeform ID" v-model="property.typeformID" @ionInput="markDirty()"></ion-input>
          </ion-item>

          <ion-item>
            <ion-toggle v-model="property.verified" @ionChange="markDirty()" color="secondary" slot="start" /><ion-label color="secondary"
              >Verified</ion-label
            >
          </ion-item>
          <ion-item>
            <ion-toggle v-model="property.hideDetails" @ionChange="markDirty()" color="secondary" slot="start" /><ion-label
              color="secondary"
              >Hide Details</ion-label
            >
          </ion-item>
          <ion-item>
            <ion-toggle v-model="property.hideConnectivity" @ionChange="markDirty()" color="secondary" slot="start" /><ion-label
              color="secondary"
              >Hide Connectivity</ion-label
            >
          </ion-item>

          <ion-item class="fullwidth">
            <ion-label position="stacked" color="secondary">Description</ion-label>
            <ion-textarea placeholder="Description" v-model="property.description" @ionInput="markDirty()" :rows="10"></ion-textarea>
          </ion-item>

          <ion-item>
            <ion-label position="stacked">Managing Entity</ion-label>
            <ion-select
              v-model="property.managingEntityID"
              interface="action-sheet"
              :interface-options="actionSheetSettings"
              placeholder="Select One"
              @ionChange="managingEntitySelected"
            >
              <ion-select-option v-for="contact in dropdownContacts" :key="contact.id" :value="contact.id"
                >{{ contact.typeName }}: {{ contact.organisationName }}</ion-select-option
              >
            </ion-select>
          </ion-item>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { computed, ref } from "@vue/runtime-core";
import { useRoute, useRouter } from "vue-router";
import { chevronForward } from "ionicons/icons";
import { alertController, onIonViewWillEnter } from "@ionic/vue";
import { IonSelect, IonSelectOption } from "@ionic/vue";
import { toast } from "@/composables/utilities";
import UncodedHeader from "@/components/UncodedHeader";
import PropertyHeader from "@/components/PropertyHeader.vue";
import NavHeader from "@/components/NavHeader.vue";
import { usePropertyStore } from "@/stores/property";
import { storeToRefs } from "pinia";

export default {
  name: "Description",
  components: { UncodedHeader, PropertyHeader, NavHeader, IonSelect, IonSelectOption },

  setup() {
    const dirty = ref(false);
    const propertyID = useRoute().params.propertyID;
    const propertyStore = usePropertyStore();
    const { property, shortcodes, allContactTypes } = storeToRefs(propertyStore);

    const router = useRouter();
    let originalShortcode;

    onIonViewWillEnter(() => {
      propertyStore.setPropertyID(propertyID);
      originalShortcode = property.value?.shortcode;
    });

    const save = async () => {
      if (shortcodes.value.map((s) => s.id).includes(property.value.shortcode) && property.value.shortcode != originalShortcode) {
        toast("Shortcode is already in use. Please choose a different value.");
        return;
      }

      propertyStore.saveProperty(originalShortcode);
      router.back();
    };

    const markDirty = () => {
      dirty.value = true;
    };

    const dropdownContacts = computed(() => {
      const result = [];
      for (const type of allContactTypes.value) {
        for (const organisation of type.organisations) {
          console.log(organisation.name, organisation.path);
          result.push({
            id: `${type.id}:${organisation.id}`,
            path: organisation.path,
            typeName: type.name,
            organisationName: organisation.name,
            typeID: type.id,
            organisationID: organisation.id,
          });
        }
      }
      return result;
    });

    const managingEntitySelected = () => {
      const typeID = property.value.managingEntityID.substring(0, property.value.managingEntityID.indexOf(":"));
      const organisationID = property.value.managingEntityID.substring(property.value.managingEntityID.indexOf(":") + 1);
      const organisation = dropdownContacts.value.find((o) => o.typeID == typeID && o.organisationID == organisationID);
      property.value.managingEntity = organisation;
      console.log(property.value);
      markDirty();
    };

    const actionSheetSettings = {
      header: "Portfolio Manager",
    };

    const back = async () => {
      if (dirty.value) {
        const alert = await alertController.create({
          cssClass: "alert",
          header: "Are you sure?",
          message: "You have unsaved changes.",
          buttons: [
            {
              text: "Save & Close",
              handler: () => {
                save();
              },
            },
            { text: "Cancel" },
            {
              text: "Discard Changes",
              role: "destructive",
              handler: () => {
                router.back();
              },
            },
          ],
        });
        await alert.present();
      } else {
        router.back();
      }
    };

    return {
      // variables
      property,
      dropdownContacts,
      actionSheetSettings,
      // methods
      markDirty,
      back,
      managingEntitySelected,
      // icons
      chevronForward,
    };
  },
};
</script>
