<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
        </ion-buttons>
        <ion-title>No Access</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <div class="container">
        <div>
          Sorry, you don't have access to perform this action. Please contact
          your administrator.
        </div>
        <ion-button expand="block" router-link="/">Home</ion-button>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonTitle,
  IonButtons,
  IonButton,
  IonToolbar,
  IonContent,
  IonMenuButton,
} from "@ionic/vue";

export default {
  components: {
    IonPage,
    IonHeader,
    IonTitle,
    IonButtons,
    IonButton,
    IonToolbar,
    IonContent,
    IonMenuButton,
  },
};
</script>

<style scoped>
.container:before {
  content: "";
  width: 100%;
  height: 100%;
  background-image: url("@/assets/img/backgrounds/data.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  filter: grayscale(100%) brightness(30%);
  z-index: -1;
}

.container {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: center;
}

.container div {
  width: 70%;
}

ion-button {
  width: 70%;
}
</style>
